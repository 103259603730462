import React, { Component } from 'react'
import '../css/main.css';
import '../css/careers.css';
import '../css/application.css';
import '../css/rtl.css';
import { Loading } from '../components/Loading';
import { Error } from '../components/Error';
import axios from 'axios';
import $, { error } from 'jquery';
import {
    GetLang, calcNavHeight, Toast, validmobileRegex, validNumbersRegex, validEmailRegex,
    validSpacesRegex, validSpecialCharRegex, IntegersRegex, IntegersGreaterThanZeroRegex
} from '../_helpers/HelperFunctions';
import { FormattedMessage } from 'react-intl';

export class ApplicationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            id: this.props.match.params.id,
            careerInfo: '',
            qaInfo: '',
            name: '',
            position: '',
            mobile: '',
            desc: '',
            selectedFile: '',
            addionalFile: [],
            qList: [],
            loadingFetch: true,
            isSubmitting: false,
            error: '',
            errors: {
                name: '',
                mobile: '',
                position: '',
                desc: '',
                selectedFile: '',
                addionalFile: '',
                email: '',
                dynmicQuestions:''
            }
        };
    }

    async fetch(method, endpoint, body) {
        try {
            const response = await fetch(`api/${endpoint}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    LangCode: GetLang(),
                },
            });
            if (!response.ok) {
                const error = (response && response.message) || response.statusText;
                if (method === "POST") {
                    Toast.fire({
                        icon: 'error',
                        title: "There is an error, Try later."
                    })
                } else
                    throw error;
            } else {
                if (this.state.selectedFile == '' && this.state.addionalFile.length == 0 && method === "POST") {
                    Toast.fire({
                        icon: 'success',
                        title: "Your request was sent successfully."
                    })
                    await this.setState({
                        name: '',
                        position: '',
                        mobile: '',
                        desc: '',
                        selectedFile: '',
                        addionalFile: [],
                        email: ''
                    });
                }
                return await response.json();
            }
        } catch (error) {
            console.error(error);
            this.setState({ error });
        }
    }

    CheckExtension(event) {
        for (let i = 0; i < event.target.files.length; i++) {

            var fileName = event.target.files[i].name
            if (fileName == "") {
                Toast.fire({
                    icon: 'info',
                    title: "Browse to upload a valid File with (docx,pdf,png,jpg,jpeg) extensions"
                })
                return false;
            }
            else if (
                (fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2)).toLowerCase() == "jpg" ||
                (fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2)).toLowerCase() == "png" ||
                (fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2)).toLowerCase() == "jpeg" ||
                (fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2)).toLowerCase() == "pdf" ||
                (fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2)).toLowerCase() == "docx"
            ) {
                return true;
            }
            else {
                Toast.fire({
                    icon: 'info',
                    title: "Select files with (docx,pdf,png,jpg,jpeg) extensions"
                })
                return false;
            }
        }
    }

    async componentDidMount() {
        calcNavHeight();
        let that = this;
        $(window).scroll(function () {
            if ($(window).scrollTop() > 0) {
                $("#navigationBar").addClass("active");
            } else {
                $("#navigationBar").removeClass("active");
            }
        });
        // Header Scroller
        $(window).on("scroll", function () {
            if ($(window).scrollTop() > 0 && $("#headerScroller")[0]) {
                $(".transition-title").addClass("active");
                that.headerBottomScrollerChecker();
            } else {
                $(".transition-title").removeClass("active");
                $("#nav").removeClass("active");
            }
        });
        let IsInt = IntegersRegex.test(this.state.id) ? true : false;
        let IsGreateThanZero = IntegersGreaterThanZeroRegex.test(this.state.id) ? true : false;
        if (IsInt !== true && IsGreateThanZero !== true) {
            window.location.assign('/404')
        } else {
            let qList = [];
            let qaInfo = await this.fetch('GET', "Service/GetApplicationJob/" + this.state.id);
            qaInfo && qaInfo.map(que =>
                qList.push({ questID:que.questionID, quest:que.question, answervalue:"" })
            )

            this.setState({ loadingFetch: true, careerInfo: await this.fetch('GET', "Service/" + this.state.id), qaInfo, qList, loadingFetch: false });
            if (this.state.error == '') {
                $(".js-range-slider-skills").ionRangeSlider({
                    onStart: function (data) {
                        $(".application-range-count").text(data.from);
                    },
                    onChange: function (data) {
                        $(".application-range-count").text(data.from);
                        let { qList } = that.state;

                        var QTypeSwiper = qaInfo.filter(function (el) {
                            return el.questionType == 1;
                        }
                        );


                        //Find index of specific object using findIndex method.    
                        let objIndex = qList.findIndex((obj => obj.questID == QTypeSwiper[0].questionID));

                        //Log object to Console.
                       // console.log("Before update: ", qList[objIndex])

                        //Update object's name property.
                        qList[objIndex].answervalue = data.from;

                        //Log object to console again.
                        //console.log("After update: ", qList[objIndex])
                    },
                    onUpdate: function (data) {
                       // $(".application-range-count").text(data.from);
                    },
                });
            }

        }
        document.body.style.removeProperty("padding-top")
        var element = document.getElementById("nav");
        element.classList.remove("active-page");
        // Range 
    }

    hanldeChangeForm = (event, questionID) => {
        // console.log(event.target.value, question);
        let { qList } = this.state;
        
        //Find index of specific object using findIndex method.    
        let objIndex = qList.findIndex((obj => obj.questID == questionID));

        //Log object to Console.
        // console.log("Before update: ", qList[objIndex])

        //Update object's name property.
        qList[objIndex].answervalue = event.target.value;
        
    }

    handleChange = (event) => {
        event.preventDefault();
        let { name, value } = event.target;
        let { errors } = this.state;
        let emptyField = '';

        switch (name) {
            case 'name':
                errors.name = value.trim() == emptyField ? 'This_field_is_required' : '';
                if (errors.name == '')
                    errors.name = validSpacesRegex.test(value) ? '' : 'Invalid_name';
                if (errors.name == '')
                    errors.name = validNumbersRegex.test(value) ? '' : 'Invalid_name';
                if (errors.name == '')
                    errors.name = validSpecialCharRegex.test(value) ? '' : 'Invalid_name';
                break;
            case 'email':
                errors.email = value.trim() == emptyField ? 'This_field_is_required' : '';
                if (errors.email == '')
                    errors.email = validEmailRegex.test(value) ? '' : 'Invalid_email';
                break;
            case 'mobile':
                errors.mobile = value.trim() == emptyField ? 'This_field_is_required' : '';
                if (errors.mobile == '')
                    errors.mobile = validmobileRegex.test(value) ? '' : 'Invalid_phone';
                break;
            default:
                break;
        }
        this.setState({ errors, [name]: value }, () => {
            console.log(errors)
        })
        
    }

    // Callback IF Condition Header Bottom
    headerBottomScrollerChecker() {
        var mainHeaderBottom =
            $("#headerScroller").position().top +
            $("#headerScroller").outerHeight(true);
        if ($(window).scrollTop() >= mainHeaderBottom) {
            $("#nav").addClass("active");
            $(".transition-title").removeClass("active");
        } else {
            $("#nav").removeClass("active");
        }
    }

    validateForm = (errors, state) => {
        let valid = true;
        if (state.name == '')
            errors.name = "This_field_is_required";
        if (state.mobile == '')
            errors.mobile = "This_field_is_required";
        if (state.email == '')
            errors.email = "This_field_is_required";        

        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );

        this.setState({ errors }, () => {
            console.log(errors)
        })
        return valid;
    }

    // On file select (from the pop up) 
    onFileChange = event => {
        // Update the state 
        if (event.target.files.length !== 0) {
            let isValid = this.CheckExtension(event);
            if (isValid == true)
                this.setState({ selectedFile: event.target.files[0] });
        } else
            this.setState({ selectedFile: '' });
    };

    // On file select (from the pop up) 
    onFileChangeAddionalFile = event => {
        let { addionalFile } = this.state;
        // Update the state 
        if (event.target.files.length !== 0) {
            let isValid = this.CheckExtension(event);
            if (isValid == true) {
                addionalFile.push(event.target.files[0]);
                this.setState({ addionalFile: addionalFile });
            }
        } else
            this.setState({ addionalFile: addionalFile });
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        let { errors, name, mobile, desc, position, selectedFile, email, addionalFile, qList } = this.state;
        let feedBackCategoryName = "Careers";

        let text = "";
        for (let i = 0; i < qList.length; i++) {
            text += "Q" + i + "):" + qList[i].quest + "\n" + "A" + i + "):" + qList[i].answervalue + "\n";
        }    
       
        function nullExists() {
            return qList.some(function (el) {
                return el.answervalue == "";
            });
        }

        if (nullExists() == true) 
            errors.dynmicQuestions = 'Answerallquestions';
        else
            errors.dynmicQuestions = '';

        let body = { "name": name, "subject": position, "mobile": mobile, "comment": desc, "email": email, "feedBackCategoryName": feedBackCategoryName, AnswersQuestionsViewModels: qList, description: text };

        if (this.validateForm(errors, this.state)) {
            console.info('Valid Form');
            this.setState({ isSubmitting: true });

            let FeedbackInsert;
            let responseCV;
            let responseAddionalFiles;


            let responseFeedbackInfo = await this.fetch('POST', "Contact/PostApplication", body);
            if (selectedFile !== '') {
                // Create an object of formData 
                const formData = new FormData();
                // Update the formData object   
                formData.append(
                    "file",
                    selectedFile,
                    selectedFile.name
                );

                // Details of the uploaded file 
                // console.log(selectedFile);

                // Request made to the backend api 
                // Send formData object 
                try {
                    let response = await axios.post("api/Feedback/UploadFile/" + responseFeedbackInfo.message, formData);
                    const error = (response && response.data) || response.statusText;
                    if (response.status !== 200) {
                        throw error;
                    }
                    else {
                        if (addionalFile.length == 0) {
                            Toast.fire({
                                icon: 'success',
                                title: "Your request was sent successfully."
                            })
                            await this.setState({
                                name: '',
                                position: '',
                                mobile: '',
                                desc: '',
                                selectedFile: '',
                                email: ''
                            });
                        }
                    }
                } catch (error) {
                    this.setState({ error });
                }
            }
            if (addionalFile.length !== 0) {
                // Create an object of formData 
                const formData2 = new FormData();
                // Update the formData object   
                for (let i = 0; i < addionalFile.length; i++) {
                    formData2.append("files", addionalFile[i]);
                }
                // Details of the uploaded file 
                // console.log(addionalFile);
                // Request made to the backend api 
                // Send formData object 
                try {
                    let response = await axios.post("api/Feedback/UploadAddtionalFiles/" + responseFeedbackInfo.message, formData2);
                    const error = (response && response.data) || response.statusText;
                    if (response.status !== 200)
                        throw error;
                    else {
                        Toast.fire({
                            icon: 'success',
                            title: "Your request was sent successfully."
                        })
                        await this.setState({
                            name: '',
                            position: '',
                            mobile: '',
                            desc: '',
                            selectedFile: '',
                            addionalFile: [],
                            email: ''
                        });
                    }
                } catch (error) {
                    this.setState({ error });
                }
            }

            this.setState({ isSubmitting: false });
        } else {
            console.error('Invalid Form')
        }
    }

    render() {
        document.title = "Application";

        let { errors, name, desc, email, position, mobile, selectedFile, isSubmitting, error, careerInfo, loadingFetch, addionalFile, qaInfo } = this.state;
        if (error)
            return <Error />
        let img = careerInfo && careerInfo.image;
        var rows = [];

        for (let i = 0; i < addionalFile.length; i++) {
            rows.push(addionalFile[i].name + " ");
        }
        // console.log(addionalFile)
        return (
            <div className="wrapper-container">
                {/* Slider main container */}
                {loadingFetch === true ? <Loading /> :
                    <>
                        <div id="headerScroller" className="home-header">
                            <div className="careers-header" style={{ backgroundImage: img === null ? 'assets/img/application.png' : `url(${img}),url("assets/img/services/service-1.png")` }}>
                                <div className="overlay" />
                                <div className="careers-data text-center">
                                    <div className="sub-title mb-3"><FormattedMessage
                                        id='ApplyFor'
                                        defaultMessage='Apply for'
                                    /></div>
                                    <div className="bg-title">
                                        {careerInfo && careerInfo.name}
                                    </div>
                                    <div className="sub-title">
                                        {careerInfo && careerInfo.string3}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="careers">
                            <div className="container-fluid">
                                <div className="careers-content">
                                    <div className="careers-st-container">
                                        <div className="application-container">
                                            <fieldset disabled={isSubmitting}>
                                                <form onSubmit={this.handleSubmit} autoComplete="off" method="post">
                                                    <div className="form-fields">
                                                        <div className="form-row">
                                                            {qaInfo && qaInfo.map((question, indx) =>
                                                                question && question.questionType == 1 ?
                                                                    <div key={question.questionID} className="application-row">
                                                                        <div className="application-title mb-2">
                                                                            {question && question.question}
                                                                        </div>
                                                                        <div className="application-range text-center">
                                                                            <div className="application-range-count">-</div>
                                                                            <div className="application-range-dash">-</div>
                                                                            <input type="text" dir="rtl" onChange={(e) => { this.hanldeChangeForm(e, question.questionID) }} data-drag-interval="false" className="js-range-slider-skills" name="my_range" data-type="single" data-min={1} data-max={10} data-from={1} data-grid="false" data-step={1} />
                                                                        </div>
                                                                    </div> : <div key={question.questionID} className="application-row">
                                                                        <div className="application-title mb-2">
                                                                            {question && question.question}
                                                                        </div>
                                                                        <div className="application-checkbox">
                                                                            {
                                                                                question && question.answerInfos && question.answerInfos.map((answer,indx) => {
                                                                                    return <div className="app-checkbox-card" key={indx} onChange={(e) => { this.hanldeChangeForm(e, question.questionID) }}>
                                                                                        < label htmlFor={"ANS_" + answer.answerID}  >
                                                                                            <input id={"ANS_" + answer.answerID} type="radio" name={"Inp_" + question.questionID} value={answer.answer} />
                                                                                            <span className="app-fake-checkbox" />
                                                                                            {answer && answer.answer}
                                                                                        </label>
                                                                                    </div>
                                                                                }
                                                                                )}
                                                                        </div>
                                                                    </div>
                                                            )}
                                                            {errors.dynmicQuestions.length > 0 &&
                                                                <font style={{ float: "left", color: "#ffc107", width: "100%", fontSize: "100%", marginTop: '9px' }}>
                                                                    <FormattedMessage
                                                                    id={errors.dynmicQuestions}
                                                                        defaultMessage="Answer all questions"
                                                                    />
                                                                </font>}
                                                        </div>
                                                        <div className="form-row">
                                                            {/*Info Form*/}
                                                            <div className="col-lg-6 col-md-6 mb-4">
                                                                <div className="application-title mb-2"><FormattedMessage
                                                                    id='Name'
                                                                    defaultMessage='Name*'
                                                                />
                                                                </div>
                                                                <div className="form-group-row">
                                                                    <FormattedMessage id="Enter_full_name"
                                                                        defaultMessage="Enter full name">
                                                                        {placeholder =>
                                                                            <input type="text" id="Name" className="form-group-input" placeholder={placeholder} name="name" value={name} onChange={this.handleChange} />
                                                                        }
                                                                    </FormattedMessage>
                                                                </div>
                                                                {errors.name.length > 0 &&
                                                                    <font style={{ float: "left", color: "#ffc107", width: "100%", fontSize: "100%", marginTop: '9px' }}>
                                                                        <FormattedMessage
                                                                            id={errors.name}
                                                                            defaultMessage="Invalid name"
                                                                        />
                                                                    </font>}
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 mb-4">
                                                                <div className="application-title mb-2"><FormattedMessage
                                                                    id='Phone'
                                                                    defaultMessage='Phone*'
                                                                />
                                                                </div>
                                                                <div className="form-group-row">
                                                                    <FormattedMessage id="Enter_Phone"
                                                                        defaultMessage="Enter phone">
                                                                        {placeholder =>
                                                                            <input type="text" className="form-group-input" placeholder={placeholder} name="mobile" value={mobile} onChange={this.handleChange} />
                                                                        }
                                                                    </FormattedMessage>
                                                                </div>
                                                                {errors.mobile.length > 0 &&
                                                                    <font style={{ float: "left", color: "#ffc107", width: "100%", fontSize: "100%", marginTop: '9px' }}>
                                                                        <FormattedMessage
                                                                            id={errors.mobile}
                                                                            defaultMessage="Invalid mobile"
                                                                        />
                                                                    </font>}
                                                            </div>
                                                            <div className="col-lg-12 col-md-6 mb-4">
                                                                <div className="application-title mb-2"><FormattedMessage
                                                                    id='emailApp'
                                                                    defaultMessage='email*'
                                                                />
                                                                </div>
                                                                <div className="form-group-row">
                                                                    <FormattedMessage id="Enter_Email"
                                                                        defaultMessage="Enter Email">
                                                                        {placeholder =>
                                                                            <input type="text" className="form-group-input" placeholder={placeholder} name="email" value={email} onChange={this.handleChange} />
                                                                        }
                                                                    </FormattedMessage>
                                                                </div>
                                                                {errors.email.length > 0 &&
                                                                    <font style={{ float: "left", color: "#ffc107", width: "100%", fontSize: "100%", marginTop: '9px' }}>
                                                                        <FormattedMessage
                                                                            id={errors.email}
                                                                            defaultMessage="Invalid email"
                                                                        />
                                                                    </font>}
                                                            </div>
                                                            <div className="col-lg-12 col-md-6 mb-4">
                                                                <div className="application-title mb-2"><FormattedMessage
                                                                    id='PositionTitle'
                                                                    defaultMessage='Position title'
                                                                />
                                                                </div>
                                                                <div className="form-group-row">
                                                                    <FormattedMessage id="EnterPositionTitle"
                                                                        defaultMessage="Enter position title">
                                                                        {placeholder =>
                                                                            <input type="text" className="form-group-input" placeholder={placeholder} name="position" value={position} onChange={this.handleChange} />
                                                                        }
                                                                    </FormattedMessage>
                                                                </div>
                                                                {errors.position.length > 0 &&
                                                                    <font style={{ float: "left", color: "#ffc107", width: "100%", fontSize: "100%", marginTop: '9px' }}>
                                                                        <FormattedMessage
                                                                            id={errors.position}
                                                                            defaultMessage="Invalid position"
                                                                        />
                                                                    </font>}
                                                            </div>
                                                            <div className="col-lg-12 mb-4">
                                                                <div className="application-title mb-2"><FormattedMessage
                                                                    id='PositionDescription'
                                                                    defaultMessage='Position description'
                                                                />
                                                                </div>
                                                                <div className="form-group-row">
                                                                    <FormattedMessage id="EnterPositionDescription"
                                                                        defaultMessage="Enter position description">
                                                                        {placeholder =>
                                                                            <textarea type="text" rows={3} className="form-group-input" placeholder={placeholder} value={""} name="desc" value={desc} onChange={this.handleChange} />
                                                                        }
                                                                    </FormattedMessage>
                                                                </div>
                                                                {errors.desc.length > 0 &&
                                                                    <font style={{ float: "left", color: "#ffc107", width: "100%", fontSize: "100%", marginTop: '9px' }}>
                                                                        <FormattedMessage
                                                                            id={errors.desc}
                                                                            defaultMessage="Invalid description"
                                                                        />
                                                                    </font>}
                                                            </div>
                                                            <div className="col-lg-12 mb-4">
                                                                <div className="application-title mb-2"><FormattedMessage
                                                                    id='UploadCV'
                                                                    defaultMessage='Upload CV'
                                                                />
                                                                </div>
                                                                <div className="form-group-row form-group-row-file">
                                                                    <input type="file" className="input-file" onChange={this.onFileChange} />
                                                                    <div className="upload-file-card">
                                                                        <span>
                                                                            {selectedFile === '' ? <>
                                                                                <FormattedMessage
                                                                                    id='DropFile'
                                                                                    defaultMessage='Drop your file or '
                                                                                /><u><FormattedMessage
                                                                                    id='Upload'
                                                                                    defaultMessage='Upload'
                                                                                /></u>
                                                                            </> : selectedFile.name}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                {errors.selectedFile.length > 0 &&
                                                                    <font style={{ float: "left", color: "#ffc107", width: "100%", fontSize: "100%", marginTop: '9px' }}>
                                                                        <FormattedMessage
                                                                            id={errors.selectedFile}
                                                                            defaultMessage="Invalid File"
                                                                        />
                                                                    </font>}
                                                            </div>
                                                            <div className="col-lg-12 mb-4">
                                                                <div className="application-title mb-2"><FormattedMessage
                                                                    id='Additionalfiles'
                                                                    defaultMessage='Additional files'
                                                                />
                                                                </div>
                                                                <div className="form-group-row form-group-row-file">
                                                                    <input type="file" className="input-file" onChange={this.onFileChangeAddionalFile} multiple />
                                                                    <div className="upload-file-card">
                                                                        <span>
                                                                            {addionalFile.length === 0 ? <>
                                                                                <FormattedMessage
                                                                                    id='DropFile'
                                                                                    defaultMessage='Drop your file or '
                                                                                /><u><FormattedMessage
                                                                                    id='Upload'
                                                                                    defaultMessage='Upload'
                                                                                /></u>
                                                                            </> : rows
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                {errors.addionalFile.length > 0 &&
                                                                    <font style={{ float: "left", color: "#ffc107", width: "100%", fontSize: "100%", marginTop: '9px' }}>
                                                                        <FormattedMessage
                                                                            id={errors.addionalFile}
                                                                            defaultMessage="Invalid File"
                                                                        />
                                                                    </font>}
                                                            </div>
                                                            <div className="col-lg-12 mb-4">
                                                                <div className="form-group-row-submit mt-5 text-center">
                                                                    <button type="submit" className="application-submit" disabled={isSubmitting}>{isSubmitting ? <FormattedMessage
                                                                        id='Waiting'
                                                                        defaultMessage='Waiting...'
                                                                    /> : <FormattedMessage
                                                                            id='SubmitApplication'
                                                                            defaultMessage='Submit application'
                                                                        />
                                                                    }</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        )
    }
}
