import React, { Component } from 'react'
import '../css/project-1.css';
import '../css/main.css';
import '../css/rtl.css';
import moment from 'moment'
import $ from 'jquery';
import { Link } from 'react-router-dom';
import Swiper, { SwiperOptions } from "swiper"
import SlimSelect from 'slim-select'
import { Loading } from '../components/Loading';
import { Error } from '../components/Error';
import { ReadMore } from '../components/ReadMore';
import { FormattedMessage } from 'react-intl';
import Context from '../components/Context';

import { numberWithCommas, validEmailRegex, IntegersGreaterThanZeroRegex, IntegersRegex, validmobileRegex, validNumbersRegex, validSpacesRegex, validSpecialCharRegex, Toast, GetLang, calcNavHeight } from '../_helpers/HelperFunctions'

export class ProjectPage extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            types: '',
            name: '',
            email: '',
            mobile: '',
            massage: '',
            typeID: '',
            whatsAppNumber: '',
            isSubmitting: false,
            errors: {
                name: '',
                email: '',
                mobile: '',
                massage: '',
                typeID: ''
            },
            projectID: this.props.match.params.id,
            projectInfo: '',
            companyInfo: '',
            loadingFetch: true,
            error: '',
        };
    }

    async fetch(method, endpoint, body) {
        try {
            const response = await fetch(`api/${endpoint}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    LangCode: GetLang(),
                },
            });
            if (!response.ok) {
                const error = (response && response.message) || response.statusText;
                if (method === "POST") {
                    Toast.fire({
                        icon: 'error',
                        title: "There is error, try later."
                    })
                } else
                    throw error;
            } else {
                if (method == "POST") {
                    Toast.fire({
                        icon: 'success',
                        title: "Your massage was send successfully."
                    })
                    this.setState({ name: '', email: '', massage: '', mobile: '', typeID: '' });
                }
                return await response.json();
            }
        } catch (error) {
            console.error(error);
            this.setState({ error });
        }
    }

    handleSwiperThumbPhotos() {
        let img = $(".swiper-thumb-photos .swiper-slide.swiper-slide-active")
            .find("img")
            .attr("src");
        let modifiedimg = img && img.replace("PPTS_", "PPT_");
        $("#bgImageModalViewPhoto").attr(
            "src", modifiedimg
        );
    }

    handleSwiperThumbPlans() {
        let img = $(".swiper-thumb-plan .swiper-slide.swiper-slide-active")
            .find("img")
            .attr("src");
        let modifiedimg = img && img.replace("IPTS_", "IPT_");
        $("#bgImageModalViewPlan").attr(
            "src", modifiedimg
        );
    }


    async componentDidMount() {
        let { errors } = this.state;

        calcNavHeight();

        //if (this.props && this.props.location && this.props.location.show && (this.props.location.show != 2 && this.props.location.show != 1)) {
        //    window.location.assign('/404')
        //}

        let IsInt = IntegersRegex.test(this.state.projectID) ? true : false;
        let IsGreateThanZero = IntegersGreaterThanZeroRegex.test(this.state.projectID) ? true : false;

        if (IsInt !== true && IsGreateThanZero !== true) {
            window.location.assign('/404')
        } else {
            let companyInfo = ''
            let projectInfo = await this.fetch('GET', "Projects/" + this.state.projectID);

            if (typeof (projectInfo) !== 'undefined' && projectInfo != null) {
                companyInfo = await this.fetch('GET', "Companies/" + projectInfo.companyId);
                const { socialBtns } = this.context;

                // let whatsAppNumber = await this.fetch('GET', "Generic/GetSocialBtns");
                let types = await this.fetch('GET', "Generic/GetTypes");

                //console.log(projectInfo)

                this.setState({ loadingFetch: true, projectInfo: projectInfo, whatsAppNumber: socialBtns, companyInfo: companyInfo, types, loadingFetch: false });
                let that = this;
                if (this.state.error === '' && this.state.loadingFetch === false) {
                    $(document).ready(function () {
                        new SlimSelect({
                            select: '#typeID',
                            showSearch: true,
                            onChange: (info) => {
                                errors.typeID = '';
                                that.setState({ typeID: info.value, errors });
                            }
                        });

                    });
                }
            } else {
                console.log('Undefined or Null')
            }
            calcNavHeight();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        new Swiper(".properties", {
            // Optional parameters
            direction: "horizontal",
            loop: false,
            slidesPerView: 4,
            spaceBetween: 30,
            autoplay: {
                delay: 4000,
            },
            speed: 1000,
            // Navigation arrows
            navigation: {
                nextEl: ".arrow-nextProperties",
                prevEl: ".arrow-prevProperties",
            },
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                // when window width is >= 480px
                480: {
                    slidesPerView: 1.8,
                    spaceBetween: 30,
                },
                // when window width is >= 640px
                640: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
            },
        });
        new Swiper(".projects", {
            // Optional parameters
            direction: "horizontal",
            loop: false,
            slidesPerView: 4,
            spaceBetween: 30,
            autoplay: {
                delay: 4000,
            },
            speed: 1000,
            // Navigation arrows
            navigation: {
                nextEl: ".arrow-nextProjects",
                prevEl: ".arrow-prevProjects",
            },
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                // when window width is >= 480px
                480: {
                    slidesPerView: 1.8,
                    spaceBetween: 30,
                },
                // when window width is >= 640px
                640: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
            },
        });
        new Swiper(".swiper-thumb-photos", {
            spaceBetween: 15,
            // touchRatio: 0.2,
            slideToClickedSlide: true,
            loop: true,
            direction: "vertical",
            observer: true,
            observeParents: true,
            autoHeight: false,
            slidesPerView: "auto",
            loopedSlides: 3,
            freeMode: false,
            watchSlidesVisibility: true,
            slidesPerView: 3,
            navigation: {
                nextEl: "#mssNextPhotoBtn",
                prevEl: "#mssPrevPhotoBtn",
            },
            slideToClickedSlide: true,
            pagination: {
                el: ".photos-fraction-counter",
                type: "fraction",

                renderFraction: function (currentClass, totalClass) {
                    return (
                        '<span class="' +
                        currentClass +
                        '"></span>' +
                        " " +
                        '<span class="' +
                        totalClass +
                        '"></span>'
                    );
                },
            },
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                    direction: "horizontal",
                },
                // when window width is >= 480px
                480: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                    direction: "horizontal",
                },
                // when window width is >= 640px
                768: {
                    slidesPerView: 4,
                    spaceBetween: 15,
                    direction: "horizontal",
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                    direction: "vertical",
                },
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                    direction: "vertical",
                },
            },
        });
        new Swiper(".swiper-thumb-plan", {
            spaceBetween: 15,
            // touchRatio: 0.2,
            slideToClickedSlide: true,
            loop: true,
            direction: "vertical",
            observer: true,
            observeParents: true,
            autoHeight: false,
            slidesPerView: "auto",
            loopedSlides: 3,
            freeMode: false,
            watchSlidesVisibility: true,
            slidesPerView: 3,
            navigation: {
                nextEl: "#mssNextPlanBtn",
                prevEl: "#mssPrevPlanBtn",
            },
            slideToClickedSlide: true,
            pagination: {
                el: ".plan-fraction-counter",
                type: "fraction",

                renderFraction: function (currentClass, totalClass) {
                    return (
                        '<span class="' +
                        currentClass +
                        '"></span>' +
                        " " +
                        '<span class="' +
                        totalClass +
                        '"></span>'
                    );
                },
            },
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                    direction: "horizontal",
                },
                // when window width is >= 480px
                480: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                    direction: "horizontal",
                },
                // when window width is >= 640px
                768: {
                    slidesPerView: 4,
                    spaceBetween: 15,
                    direction: "horizontal",
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                    direction: "vertical",
                },
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                    direction: "vertical",
                },
            },
        });
    }

    handleClickFormUp() {
        $("#toggleContactContainer").slideDown(500);
    }

    handleClickFormDown() {
        $("#toggleContactContainer").slideUp(500);
    }

    handleClickPrevPhoto() {
        let img = $(".swiper-thumb-photos .swiper-slide.swiper-slide-active")
            .next()
            .find("img")
            .attr("src");
        let modifiedimg = img && img.replace("PPTS_", "PPT_");
        $("#bgImageModalViewPhoto").attr(
            "src", modifiedimg

        );
    }
    handleClickNextPhoto() {
        let img = $(".swiper-thumb-photos .swiper-slide.swiper-slide-active")
            .next()
            .find("img")
            .attr("src");
        let modifiedimg = img && img.replace("PPTS_", "PPT_");
        $("#bgImageModalViewPhoto").attr(
            "src", modifiedimg

        );
    }
    handleClickPrevPlan() {
        let img = $(".swiper-thumb-plan .swiper-slide.swiper-slide-active")
            .next()
            .find("img")
            .attr("src");
        let modifiedimg = img && img.replace("IPTS_", "IPT_");
        $("#bgImageModalViewPlan").attr(
            "src", modifiedimg

        );
    }
    handleClickNextPlan() {
        let img = $(".swiper-thumb-plan .swiper-slide.swiper-slide-active")
            .next()
            .find("img")
            .attr("src");
        let modifiedimg = img && img.replace("IPTS_", "IPT_");
        $("#bgImageModalViewPlan").attr(
            "src", modifiedimg

        );
    }

    handleClickTab(tabName, e) {
        let { projectInfo } = this.state;
        var tab = tabName;
        $("#" + tab + "Btn").addClass("active").siblings().removeClass("active");
        $(".modal-tab").addClass("active");
        $(".wrapper-container").addClass("toggle-wrapper-active");
        $("#" + tab)
            .addClass("active")
            .siblings()
            .removeClass("active");
        //$("#videoPlayer").get(0).pause();        
        $(".vp-overlay").addClass("active");
        $("#toggleVpBtn").find("img").attr("src", "assets/img/icons/play.svg");
        if (tab == "planTab") {
            $(".mtaf-plan").addClass("active");
            $(".mtaf-photos").removeClass("active");
            if (projectInfo.string2 !== null) {
                var frame = document.getElementById("videoPlayer");
                frame.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
            }
        } else if (tab == "photosTab") {
            $(".mtaf-plan").removeClass("active");
            $(".mtaf-photos").addClass("active");
            if (projectInfo.string2 !== null) {
                var frame = document.getElementById("videoPlayer");
                frame.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
            }
        } else {
            $(".mtaf-plan").removeClass("active");
            $(".mtaf-photos").removeClass("active");
        }
        window.scrollTo(0, 0);
    }

    handleClickCloseModalTab() {
        let { projectInfo } = this.state;
        $(".modal-tab").removeClass("active");
        $(".wrapper-container").removeClass("toggle-wrapper-active");
        //$("#videoPlayer").get(0).pause();
        if (projectInfo.string2 !== null) {
            var frame = document.getElementById("videoPlayer");
            frame.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
        }
        $(".vp-overlay").addClass("active");
        $("#toggleVpBtn").find("img").attr("src", "assets/img/icons/play.svg");
    }

    renderFractionImages(currentClass, totalClass) {
        return (
            '<span class="' +
            currentClass +
            '"></span>' +
            " " +
            '<span class="' +
            totalClass +
            '"></span>'
        );
    }

    async onSelect(id) {
        this.props.history.push({
            pathname: `/Project/${id}`
        })
        await this.setState({ loadingFetch: true });
        let projectInfo = await this.fetch('GET', "Projects/" + id);
        let companyInfo = await this.fetch('GET', "Companies/" + projectInfo.companyId);

        await this.setState({ projectID: id, projectInfo: projectInfo, companyInfo: companyInfo, loadingFetch: false });
    }

    handleChange = (event) => {
        event.preventDefault();
        let { name, value } = event.target;
        let { errors } = this.state;
        let emptyField = '';

        switch (name) {
            case 'name':
                errors.name = value.trim() == emptyField ? 'This_field_is_required' : '';
                if (errors.name == '')
                    errors.name = validSpacesRegex.test(value) ? '' : 'Invalid_name';
                if (errors.name == '')
                    errors.name = validNumbersRegex.test(value) ? '' : 'Invalid_name';
                if (errors.name == '')
                    errors.name = validSpecialCharRegex.test(value) ? '' : 'Invalid_name';
                break;
            case 'email':
                errors.email = value.trim() == emptyField ? 'This_field_is_required' : '';
                if (errors.email == '')
                    errors.email = validEmailRegex.test(value) ? '' : 'Invalid_email';
                break;
            case 'mobile':
                errors.mobile = value.trim() == emptyField ? 'This_field_is_required' : '';
                if (errors.mobile == '')
                    errors.mobile = validmobileRegex.test(value) ? '' : 'Invalid_phone';
                break;
            case 'massage':
                errors.massage = value.trim() == emptyField ? 'This_field_is_required' : '';
                if (errors.massage == '')
                    errors.massage = validSpecialCharRegex.test(value) ? '' : 'Invalid_name';
                if (errors.massage == '')
                    errors.massage = validSpacesRegex.test(value) ? '' : 'This_field_is_required';
                break;
            default:
                break;
        }

        this.setState({ errors, [name]: value }, () => {
            console.log(errors)
        })
    }

    validateForm = (errors, state) => {
        let valid = true;
        if (state.name == '')
            errors.name = "This_field_is_required";
        if (state.email == '')
            errors.email = "This_field_is_required";
        if (state.mobile == '')
            errors.mobile = "This_field_is_required";
        if (state.massage == '')
            errors.massage = "This_field_is_required";
        if (state.typeID == '')
            errors.typeID = "This_field_is_required";

        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );

        this.setState({ errors }, () => {
            console.log(errors)
        })
        return valid;
    }

    collapseShow(collpase) {
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        let { errors, name, email, mobile, massage, typeID, projectInfo } = this.state;
        let extendString1 = "Project Id: " + projectInfo.id + ", Project Name: " + projectInfo.name;

        let body = { "name": name, "email1": email, "mobile1": mobile, "description": massage, "typeId": typeID, "extendString1": extendString1 };

        if (this.validateForm(errors, this.state)) {
            console.info('Valid Form');
            this.setState({ isSubmitting: true });
            await this.fetch('POST', "Generic/AddContact", body)
            this.setState({ isSubmitting: false });

        } else {
            console.error('Invalid Form')
        }
    }

    handleReadMore = (id) => {
        var content = $("#" + id)
            .parents(".read-more-parent")
            .find(".read-more-content");
        content.text(content.attr("data-text"));
        $("#" + id).css("display", "none");
    }

    render() {
        document.title = "Project";

        let { error, projectInfo, companyInfo, projectID, loadingFetch, isSubmitting, errors, email, whatsAppNumber, mobile, massage, types, typeID, name } = this.state;
        if (error)
            return <Error />
        let lang = GetLang();
        let number = whatsAppNumber && whatsAppNumber.filter(obj => {
            return obj.name == "WhatsAppNumber"
        })
        // There's no real number bigger than plus Infinity
        var lowest = Number.POSITIVE_INFINITY;
        var tmp;
        for (var i = projectInfo && projectInfo.relatedItems.length - 1; i >= 0; i--) {
            if (projectInfo && projectInfo.relatedItems[i].priceSales > 0) {
                tmp = projectInfo && projectInfo.relatedItems[i].priceSales;
                if (tmp < lowest) lowest = tmp;
            }
        }

        let projectsOfSameCompany = companyInfo && companyInfo.projects && companyInfo.projects.filter(function (el) {
            return el.id != projectInfo.id;
        });

        for (var i = 0; i < projectsOfSameCompany.length; i++) {
            if (projectsOfSameCompany[i].image)
                projectsOfSameCompany[i].image = projectsOfSameCompany[i].image.replace("PI_", "PS_");
        }

        let companyLogo;
        let copycompanyLogo;


        $(document).ready(function () {
            // Add minus icon for collapse element which is open by default
            $(".collapse.show").each(function () {
                $(this)
                    .parent()
                    .find(".icon-minus")
                    .removeClass("icon-minus")
                    .addClass("icon-plus");
            });

            // Toggle plus minus icon on show hide of collapse element
            $(".collapse")
                .on("show.bs.collapse", function () {
                    $(this)
                        .parent()
                        .find(".icon-plus")
                        .removeClass("icon-plus")
                        .addClass("icon-minus");
                })
                .on("hide.bs.collapse", function () {
                    $(this)
                        .parent()
                        .find(".icon-minus")
                        .removeClass("icon-minus")
                        .addClass("icon-plus");
                });
        });
        if (projectInfo && projectInfo.logo)
            projectInfo.logo = projectInfo.logo && projectInfo.logo.replace("Lo_", "LOG_");
        if (companyInfo && companyInfo.image) {
            companyInfo.image = companyInfo.image && companyInfo.image.replace("CL2_", "L_");
            copycompanyLogo = companyInfo.image;
            companyLogo = copycompanyLogo && copycompanyLogo.replace("L_", "CL_")
        }

        let banner = projectInfo && projectInfo.images && projectInfo.images[0] && projectInfo.images[0].image;

        var photosTabSwiper = projectInfo && projectInfo.images;
        for (var i = 0; i < photosTabSwiper.length; i++) {
            if (photosTabSwiper[i].image)
                photosTabSwiper[i].image = photosTabSwiper[i].image.replace("PI_", "PPTS_");
        }

        let intialPhotoSwiper = photosTabSwiper && photosTabSwiper[0] && photosTabSwiper[0].image && photosTabSwiper[0].image.replace("PPTS_", "PPT_");


        var plansTabSwiper = projectInfo && projectInfo.relatedItems;
        for (var i = 0; i < plansTabSwiper.length; i++) {
            if (plansTabSwiper[i].image)
                plansTabSwiper[i].image = plansTabSwiper[i].image.replace("IPT_", "IPTS_");
        }

        return (
            <>
                {/* Modal Slider */}
                <div className="modal-tab animated fadeInDown">
                    <div className="container-fluid">
                        <div className="modal-tab-container">
                            <div className="modal-tab-flexable">
                                <div className="modal-tab-content">
                                    {/* Photos Tab */}
                                    <div id="photosTab" className="modal-project-tab">
                                        <div className="ds-modal-tab-flexable d-flex">
                                            <div className="modal-tab-normal-main-image">
                                                <img id="bgImageModalViewPhoto" src={intialPhotoSwiper} alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />
                                            </div>
                                            <div id="modalPhotosContainer" className="modal-tab-normal-images">
                                                <div className="modal-tab-overview">
                                                    <div className="swiper-container swiper-thumb-photos swiper-wrapper-modal-tab" onClick={() => this.handleSwiperThumbPhotos()}>
                                                        <div className="swiper-wrapper" style={{ direction: lang === 'ar' ? 'rtl' : 'ltr' }}>
                                                            {photosTabSwiper && photosTabSwiper.map(img =>  //projectInfo && projectInfo.images && projectInfo.images.map(img =>
                                                                <div className="swiper-slide">
                                                                    <img src={img && img.image} className="img-fluid" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Plan Tab */}
                                    <div id="planTab" className="modal-project-tab">
                                        <div className="ds-modal-tab-flexable d-flex">
                                            <div className="modal-tab-normal-main-image">
                                                <img id="bgImageModalViewPlan" src={projectInfo && projectInfo.relatedItems && projectInfo.relatedItems[0] && projectInfo.relatedItems[0].image} alt="" />
                                            </div>
                                            <div id="modalPlanContainer" className="modal-tab-normal-images">
                                                <div className="modal-tab-overview">
                                                    <div className="swiper-container swiper-thumb-plan swiper-wrapper-modal-tab" onClick={() => this.handleSwiperThumbPlans()}>
                                                        <div className="swiper-wrapper" style={{ direction: lang === 'ar' ? 'rtl' : 'ltr' }}>
                                                            {plansTabSwiper && plansTabSwiper.map(item =>
                                                                <div className="swiper-slide">
                                                                    <img src={item && item.image} className="img-fluid" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Video Tab */}
                                    <div id="videoTab" className="modal-project-tab">
                                        <div className="ds-modal-tab-flexable md-video-container d-flex">
                                            {projectInfo && projectInfo.string2 !== null ? <iframe id="videoPlayer" className="modal-video-player" width="560" height="315" src={projectInfo && projectInfo.string2 + "?enablejsapi=1&version=3&playerapiid=ytplayer"} frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe> : <FormattedMessage
                                                id='ThereisnVideo'
                                                defaultMessage="There isn\'t any video"
                                            />}
                                            {/*<video id="videoPlayer" src={projectInfo && projectInfo.string2} className="modal-video-player" poster="assets/img/d2/d2-1.png" />*/}
                                            {/*<div className="vp-overlay active">*/}
                                            {/*    <button id="toggleVpBtn"><img src="assets/img/icons/play.svg" className="img-fluid" alt="" /></button>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-tab-actions">
                                    <div className="modal-top-actions">
                                        <button id="exitModalTabBtn" className="mss-btn mb-5" onClick={() => this.handleClickCloseModalTab()}><span className="icon-cancel icon" /></button>
                                        <button id="photosTabBtn" className="mss-btn md-tab-btn" data-tab="photosTab" onClick={() => this.handleClickTab("photosTab")}><span className="icon-photo icon" /></button>
                                        <button id="planTabBtn" className="mss-btn md-tab-btn" data-tab="planTab" onClick={() => this.handleClickTab("planTab")}><span className="icon-plan icon-sm icon" /></button>
                                        <button id="videoTabBtn" className="mss-btn md-tab-btn" data-tab="videoTab" onClick={() => this.handleClickTab("videoTab")}><span className="icon-video icon-sm icon" /></button>
                                    </div>
                                    <div className="modal-tab-tel">
                                        <a href="tel:01234567890" data-toggle="tooltip" data-placement="left" title={"01234567890"} className="mss-btn mss-btn-black md-tab-btn"><span className="icon-telephone icon" /></a>
                                    </div>
                                    <div className="modal-tab-arrows-fraction">
                                        <div className="mtaf mtaf-photos">
                                            <div className="photos-fraction-counter fraction-counter" />
                                            <div className="modal-tab-arrows">
                                                <button id="mssPrevPhotoBtn" onClick={() => this.handleClickPrevPhoto()} className="mss-btn-arrow"><span className="icon-arrow-left icon" /></button>
                                                <button id="mssNextPhotoBtn" onClick={() => this.handleClickNextPhoto()} className="mss-btn-arrow"><span className="icon-arrow-right icon" /></button>
                                            </div>
                                        </div>
                                        <div className="mtaf mtaf-plan">
                                            <div className="plan-fraction-counter fraction-counter" />
                                            <div className="modal-tab-arrows">
                                                <button id="mssPrevPlanBtn" onClick={() => this.handleClickPrevPlan()} className="mss-btn-arrow"><span className="icon-arrow-left icon" /></button>
                                                <button id="mssNextPlanBtn" onClick={() => this.handleClickNextPlan()} className="mss-btn-arrow"><span className="icon-arrow-right icon" /></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal Slider */}
                {/* Content */}
                <div className="wrapper-container">
                    {/* Project Header Cover */}
                    {loadingFetch === true ? <Loading /> :
                        <>
                            <div className="project-cover-container animated fadeInLeft">
                                <div className="project-cover">
                                    <img src={banner} className="img-fluid project-cover-img" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/design-1.png" }} />
                                    <div className="container-fluid position-relative">
                                        <div className="project-profile-outer">
                                            <div className="project-profile-inner">
                                                {projectInfo && projectInfo.logo == null ? <img src="assets/img/notfound.png" className="project-profile-view" alt="" />
                                                    : <img src={projectInfo && projectInfo.logo} className="project-profile-view" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Project Details */}
                            <div className="project-top-container">
                                <div className="container-fluid">
                                    {/* Project Structure Row */}
                                    <div className="project-design-structure">
                                        <div className="project-bar-fake-profile" />
                                        <div className="project-bar-details justify-content-between d-flex">
                                            <div className="pd-data-text flex-center flex-wrap">
                                                <div className="pbd-row">
                                                    <div className="pbd-title"><FormattedMessage
                                                        id='Starting_Price'
                                                        defaultMessage='Starting Price'
                                                    /></div>
                                                    <div className="pbd-data">{lowest && numberWithCommas(lowest)} <FormattedMessage
                                                        id='EGP'
                                                        defaultMessage='EGP'
                                                    /></div>
                                                </div>
                                                <div className="pbd-row">
                                                    <div className="pbd-title"><FormattedMessage
                                                        id='Down_Payment'
                                                        defaultMessage='Down Payment'
                                                    /> </div>
                                                    <div className="pbd-data">{projectInfo && projectInfo.paymentPlans && projectInfo.paymentPlans[0] && projectInfo.paymentPlans[0].downPayment}%</div>
                                                </div>
                                                <div className="pbd-row">
                                                    <div className="pbd-title"><FormattedMessage
                                                        id='Installments'
                                                        defaultMessage='Installments'
                                                    /></div>
                                                    <div className="pbd-data">{projectInfo && projectInfo.paymentPlans && projectInfo.paymentPlans[0] && projectInfo.paymentPlans[0].installmentsCount}</div>
                                                </div>
                                                <div className="pbd-row">
                                                    <div className="pbd-title"><FormattedMessage
                                                        id='Delivery_Date'
                                                        defaultMessage='Delivery Date'
                                                    /> </div>
                                                    <div className="pbd-data">{projectInfo && projectInfo.paymentPlans && projectInfo.paymentPlans[0] && projectInfo.paymentPlans[0].deliveryDate && moment(projectInfo.paymentPlans[0].deliveryDate).format('L')} </div>
                                                </div>
                                            </div>
                                            {/*<div className="pbd-row pdb-date-row pr-0">*/}
                                            {/*    <div className="small-text">Last Updated 28 Oct 2020</div>*/}
                                            {/*</div>*/}
                                        </div>
                                        <div className="project-bar-actions">
                                            <button className="project-action-btn md-tab-btn" data-tab="videoTab" onClick={() => this.handleClickTab("videoTab")}>
                                                <span className="icon-video icon" />
                                                <div className="title"><FormattedMessage
                                                    id='Play_Video'
                                                    defaultMessage='Play Video'
                                                /></div>
                                            </button>
                                            <button className="project-action-btn">
                                                <span className="icon-pin icon" />
                                                <div className="title">{projectInfo && projectInfo.districtName}</div>
                                            </button>
                                            <button className="project-action-btn md-tab-btn" data-tab="planTab" onClick={() => this.handleClickTab("planTab")}>
                                                <span className="icon-plan icon" />
                                                <div className="title"><FormattedMessage
                                                    id='2D_Plans'
                                                    defaultMessage='2D Plans'
                                                /></div>
                                            </button>
                                            <button className="project-action-btn md-tab-btn" data-tab="photosTab" onClick={() => this.handleClickTab("photosTab")}>
                                                <span className="icon-photo icon" />
                                                <div className="title"><FormattedMessage
                                                    id='Photo'
                                                    defaultMessage='Photo'
                                                /></div>
                                            </button>
                                        </div>
                                    </div>
                                    {/* Project Structure Row */}
                                    <div className="project-design-structure">
                                        <div className="sidebar-accordion">
                                            <div className="accordion" id="accordionExample">
                                                {projectInfo && projectInfo.typesDetails && projectInfo.typesDetails.map(type => {
                                                    let collpase = type && type.typeName && type.typeName.replace(/\s/g, '').toLowerCase();
                                                    return <div className="card">
                                                        <div className="card-header accordion-card-header" id="headingOne">
                                                            <button className="toggle-accordion-btn flex-center-between" onClick={(collpase) => { this.collapseShow(collpase) }} type="button" data-toggle="collapse" data-target={"#" + collpase} aria-expanded="false" aria-controls={collpase}>
                                                                <div className="title">{type && type.typeName}</div>
                                                                <span className="icon-plus icon" />
                                                            </button>
                                                        </div>
                                                        <div id={collpase} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                            <div className="card-body">
                                                                <div className="design-list-container">
                                                                    <div className="list-row">
                                                                        <div className="list-shape">
                                                                            <span className="list-circle" />
                                                                        </div>
                                                                        <div className="list-text"><FormattedMessage
                                                                            id='Area_From'
                                                                            defaultMessage='Area From'
                                                                        />: {type && type.minArea} m2 </div>
                                                                    </div>
                                                                    <div className="list-row">
                                                                        <div className="list-shape">
                                                                            <span className="list-circle" />
                                                                        </div>
                                                                        <div className="list-text"><FormattedMessage
                                                                            id='Area_To'
                                                                            defaultMessage='Area To'
                                                                        />: {type && type.maxArea} <FormattedMessage
                                                                                id='m2'
                                                                                defaultMessage='m2'
                                                                            /> </div>
                                                                    </div>
                                                                    <div className="list-row">
                                                                        <div className="list-shape">
                                                                            <span className="list-circle" />
                                                                        </div>
                                                                        <div className="list-text"><FormattedMessage
                                                                            id='Min_Price'
                                                                            defaultMessage='Min Price'
                                                                        />:  {type && type.minPrice && numberWithCommas(type.minPrice)} <FormattedMessage
                                                                                id='EGP'
                                                                                defaultMessage='EGP'
                                                                            /></div>
                                                                    </div>
                                                                    <div className="list-row">
                                                                        <div className="list-shape">
                                                                            <span className="list-circle" />
                                                                        </div>
                                                                        <div className="list-text"><FormattedMessage
                                                                            id='Max_Price'
                                                                            defaultMessage='Max Price'
                                                                        />:  {type && type.maxPrice && numberWithCommas(type.maxPrice)} <FormattedMessage
                                                                                id='EGP'
                                                                                defaultMessage='EGP'
                                                                            /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                )}
                                            </div>
                                        </div>
                                        <div className="text-location">
                                            <div className="tl-card">
                                                <div className="tl-title">
                                                    <FormattedMessage
                                                        id='Project_Overview'
                                                        defaultMessage='Project Overview'
                                                    /></div>
                                                <div className="tl-text read-more-parent">
                                                    <span id="readMoreProjectContent" className="read-more-content" data-length={447}>
                                                        <div className="content" dangerouslySetInnerHTML={{ __html: projectInfo && projectInfo.string5 }}></div>
                                                    </span>
                                                    {/*    <button id="readMoreProject" className="read-more-btn" onClick={() => { this.handleReadMore("readMoreProject") }}> ...Read More</button>*/}
                                                </div>
                                            </div>
                                            <div className="tl-card">
                                                <div className="tl-title">  <FormattedMessage
                                                    id='Location'
                                                    defaultMessage='Location'
                                                /></div>
                                                <iframe src={projectInfo && 'https://maps.google.com/maps?q=' + projectInfo.latitude + ',' + projectInfo.longitude + '&t=&z=12&ie=UTF8&iwloc=&output=embed'} width="100%" height={250} style={{ border: 0 }} allowFullScreen loading="lazy" />
                                            </div>
                                        </div>
                                        <div className="about-developer">
                                            <div className="design-contact">
                                                <a href={number && number[0] && number[0].value && "https://wa.me/" + number[0].value + "?text=This is Capital Lords"} target="_blank" rel="noopener noreferrer" className="dc-btn ask-btn align-center-center">
                                                    <span className="icon-whatsapp icon" /> <FormattedMessage
                                                        id='ASK_NOW'
                                                        defaultMessage='ASK NOW'
                                                    /></a>
                                                <button id="toggleContact" className="dc-btn register-btn" onClick={() => this.handleClickFormUp()}>
                                                    <FormattedMessage
                                                        id='REGISTER_INTEREST'
                                                        defaultMessage='REGISTER INTEREST'
                                                    /></button>
                                                <div id="toggleContactContainer" className="contact-form project-contact-form" style={{ width: "100%" }}>
                                                    <div className="cform-title flex-center-between">
                                                        <div className="title"><FormattedMessage
                                                            id='REGISTER_INTEREST'
                                                            defaultMessage='Register Interest'
                                                        /></div>
                                                        <button id="closeContactContainer" onClick={() => this.handleClickFormDown()} className="close-contact opacity-hover"><span className="icon-cancel icon" /></button>
                                                    </div>
                                                    <fieldset disabled={isSubmitting}>
                                                        <form className="form-row" onSubmit={this.handleSubmit} autoComplete="off" method="post">
                                                            <div className="col-lg-12">
                                                                <div className="cform-group">
                                                                    <FormattedMessage id="Enter_full_name"
                                                                        defaultMessage="Enter full name">
                                                                        {placeholder =>
                                                                            <input type="text" placeholder={placeholder} name="name" value={name} onChange={this.handleChange} />
                                                                        }
                                                                    </FormattedMessage>
                                                                </div>
                                                                {errors.name.length > 0 &&
                                                                    <font style={{ float: "left", color: "#dc3545", width: "100%", fontSize: "80%" }}>
                                                                        <FormattedMessage
                                                                            id={errors.name}
                                                                            defaultMessage="Invalid name"
                                                                        />
                                                                    </font>}
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="cform-group">
                                                                    <FormattedMessage id="Enter_Email"
                                                                        defaultMessage="Enter Email">
                                                                        {placeholder =>
                                                                            <input type="text" placeholder={placeholder} name="email" value={email} onChange={this.handleChange} />
                                                                        }
                                                                    </FormattedMessage>
                                                                </div>
                                                                {errors.email.length > 0 &&
                                                                    <font style={{ float: "left", color: "#dc3545", width: "100%", fontSize: "80%" }}>
                                                                        <FormattedMessage
                                                                            id={errors.email}
                                                                            defaultMessage="Invalid email"
                                                                        />
                                                                    </font>}
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="cform-group d-flex">
                                                                    <FormattedMessage id="Enter_Phone"
                                                                        defaultMessage="Enter Phone">
                                                                        {placeholder =>
                                                                            <input type="text" placeholder={placeholder} name="mobile" value={mobile} onChange={this.handleChange} />
                                                                        }
                                                                    </FormattedMessage>
                                                                </div>
                                                                {errors.mobile.length > 0 &&
                                                                    <font style={{ float: "left", color: "#dc3545", width: "100%", fontSize: "80%" }}>
                                                                        <FormattedMessage
                                                                            id={errors.mobile}
                                                                            defaultMessage="Invalid mobile"
                                                                        />
                                                                    </font>}
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="cform-group">
                                                                    <FormattedMessage id="Im_interested"
                                                                        defaultMessage="I’m interested in Sale - Apartment New Cairo.">
                                                                        {placeholder =>
                                                                            <textarea rows={3} placeholder={placeholder} defaultValue={""} name="massage" value={massage} onChange={this.handleChange} />
                                                                        }
                                                                    </FormattedMessage>
                                                                </div>
                                                                {errors.massage.length > 0 &&
                                                                    <font style={{ float: "left", color: "#dc3545", width: "100%", fontSize: "80%" }}>
                                                                        <FormattedMessage
                                                                            id={errors.massage}
                                                                            defaultMessage="Invalid massage"
                                                                        />
                                                                    </font>}
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="cform-group" style={{ border: 'none' }}>
                                                                    <select id="typeID" name="typeID" value={typeID} onChange={this.handleChange} >
                                                                        <FormattedMessage id="Select_unit_type"
                                                                            defaultMessage="Select unit type">
                                                                            {placeholder =>
                                                                                <option value="" disabled selected>{placeholder}</option>
                                                                            }
                                                                        </FormattedMessage>
                                                                        {types && types.map(type =>
                                                                            <option value={type.id}>{type.name}</option>
                                                                        )}
                                                                    </select>
                                                                </div>
                                                                {errors.typeID.length > 0 &&
                                                                    <font style={{ float: "left", color: "#dc3545", width: "100%", fontSize: "80%" }}>
                                                                        <FormattedMessage
                                                                            id={errors.typeID}
                                                                            defaultMessage="Invalid type"
                                                                        />
                                                                    </font>}
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="cform-action">
                                                                    <div className="button-default width-fluid bt-black">
                                                                        <button type="submit" disabled={isSubmitting}>{isSubmitting ? <><i class="fas fa-spinner fa-pulse"></i> <FormattedMessage
                                                                            id='Waiting'
                                                                            defaultMessage='Waiting...'
                                                                        /></> : <FormattedMessage
                                                                                id='SendNow'
                                                                                defaultMessage='Send Now'
                                                                            />
                                                                        }</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div className="developer-widget">
                                                <div className="dev-link mb-4 d-flex">
                                                    <div>
                                                        {companyLogo == "" ? <img src="assets/img/notfound.png" className="img-fluid" alt="" />
                                                            : <img src={companyLogo} className="img-fluid" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />}
                                                    </div>
                                                    <div className="dev-widget-info flex-center">
                                                        <div className>
                                                            <div className="title"><FormattedMessage
                                                                id='About_Developer'
                                                                defaultMessage='About Developer'
                                                            /></div>
                                                            <div className="text">{companyInfo && companyInfo.name}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="read-more-parent dev-link-text">
                                                    <span id="readMoreCompanyContent" className="text read-more-content" data-length={100}>
                                                        <ReadMore>{companyInfo && companyInfo.string5 && companyInfo.string5.replace(/<\/?[^>]+(>|$)/g, "")}</ReadMore>
                                                    </span>
                                                    {/*    <button id="readMoreCompany" className="read-more-btn" onClick={() => { this.handleReadMore("readMoreCompany")} }> ...Read More</button>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*  <div className="projects-slider">
                                <div className="container-fluid">
                                    <div className="projects-slider-header flex-center-between">
                                        <div className="title"><FormattedMessage
                                            id='YouMayAlso'
                                            defaultMessage='YOU MAY AlSO LIKE'
                                        /> </div>
                                        <div className="ps-arrows">
                                            <button id="arrow-prevProperties" className="arrow-prevProperties arrow-prev"><span className="icon-arrow-left icon" /></button>
                                            <button id="arrow-nextProperties" className="arrow-nextProperties arrow-next"><span className="icon-arrow-right icon" /></button>
                                        </div>
                                    </div>
                                    <div id="properties" className="properties swiper-container swiper-similar-products">
                                        {projectInfo && projectInfo.relatedItems.length == 0 ? <FormattedMessage
                                            id='ThereisnProperty'
                                            defaultMessage="There isn't any property in project"
                                        /> :
                                            <div className="swiper-wrapper" style={{ direction: lang === 'ar' ?  'rtl': 'ltr' }}>
                                                {projectInfo && projectInfo.relatedItems.map(relatedItem =>
                                                    <div className="swiper-slide">
                                                        <div className="product-card" >
                                                            <Link to={relatedItem && "/Property/" + relatedItem.id}
                                                            > {relatedItem && relatedItem.image == null ? <img src="assets/img/notfound.png" className="product-card-view img-fluid" alt="" />
                                                                : <img src={relatedItem && relatedItem.image} className="product-card-view img-fluid" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />}
                                                            </Link>
                                                            <div className="product-card-title">{relatedItem && relatedItem.name}</div>
                                                            <div className="product-card-company">
                                                                <div className="pcc-logo">
                                                                    {projectInfo && projectInfo.logo == null ? <img src="assets/img/notfound.png" className="img-fluid" alt="" />
                                                                        : <img src={projectInfo && projectInfo.logo} className="img-fluid" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />}
                                                                </div>
                                                                <div className="pcc-data">
                                                                    <div className="pcc-title"><FormattedMessage
                                                                        id='Starting_Price'
                                                                        defaultMessage='Starting Price'
                                                                    /></div>
                                                                    <div className="pcc-price">{relatedItem && relatedItem.priceSales && numberWithCommas(relatedItem.priceSales)}  <FormattedMessage
                                                                        id='EGP'
                                                                        defaultMessage='EGP'
                                                                    /></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div> */}
                            <div className="projects-slider">
                                {/* Project Slider */}
                                <div className="container-fluid">
                                    <div className="projects-slider-header flex-center-between">
                                        <div className="title"><FormattedMessage
                                            id='YouMayAlso'
                                            defaultMessage='YOU MAY AlSO LIKE'
                                        /></div>
                                        <div className="ps-arrows">
                                            <button id="arrow-prevProjects" className="arrow-prevProjects arrow-prev"><span className="icon-arrow-left icon" /></button>
                                            <button className="arrow-nextProjects" className="arrow-nextProjects arrow-next"><span className="icon-arrow-right icon" /></button>
                                        </div>
                                    </div>
                                    <div id="projects" className="projects swiper-container swiper-similar-products">
                                        {projectsOfSameCompany && projectsOfSameCompany.length == 0 ? <div style={{ textAlign: 'center' }}> <FormattedMessage
                                            id='ThereisntProjects'
                                            defaultMessage="There isn't any project to developer"
                                        /> </div> :
                                            <div className="swiper-wrapper" style={{ direction: lang === 'ar' ? 'rtl' : 'ltr' }}>
                                                {projectsOfSameCompany && projectsOfSameCompany.map(item =>
                                                    <div className="swiper-slide">
                                                        <div className="product-card" >
                                                            <Link to={"/Project/" + item.id} onClick={() => { this.onSelect(item && item.id) }}>
                                                                {item.image == null ? <img src="assets/img/notfound.png" className="product-card-view img-fluid" alt="" />
                                                                    : <img src={item.image} className="product-card-view img-fluid" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />}
                                                            </Link>
                                                            {/*<img src={item && item.images && item.images[0] && item.images[0].image} className="product-card-view img-fluid" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} /> */}
                                                            <div className="product-card-title">{item && item.name}</div>
                                                            <div className="product-card-company">
                                                                <div className="pcc-logo">
                                                                    {companyInfo && companyInfo.image == null ? <img src="assets/img/notfound.png" className="img-fluid" alt="" />
                                                                        : <img src={companyInfo && companyInfo.image} className="img-fluid" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </>
        )
    }
}
