import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import SlimSelect from 'slim-select'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { GetLang } from '../_helpers/HelperFunctions'
import { FormattedMessage } from 'react-intl';
import Context from '../components/Context';


export class Header extends Component {
    static contextType = Context;  

    constructor(props) {
        super(props);
        this.state = {
           whatsAppNumber: ''          
        };
    }
    async fetch(method, endpoint, body) {
        try {
            const response = await fetch(`api/${endpoint}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    LangCode: GetLang(),
                },
            });
            if (!response.ok) {
                const error = (response && response.message) || response.statusText;
                throw error;
            } else
                return await response.json();
        } catch (error) {
            console.error(error);
            this.setState({ error });
        }
    }

    changeText = (lang) => {

        if (lang.toLowerCase() =="arabic")
            localStorage.setItem("lang", "ar");
        else
            localStorage.setItem("lang", "en");

        window.location.reload();

        //if (context.locale == 'en') {
        //    context.selectLanguage('ar')
        //} else {
        //    context.selectLanguage('en')
        //}
    }
    // Flexable Menu Body
    menuBodyHandler() {
        var top = $("#menuTop").innerHeight();
        var bottom = $("#menuBottom").innerHeight();
        $("#menuBody").css("height", `calc(100vh - ${top + bottom}px)`);
    }
    handleClickNavMenu() {
        $("#menuContainer").fadeIn(600);
        this.menuBodyHandler();
    }
    async componentDidMount() {
        const { socialBtns } = this.context;

        // let whatsAppNumber = await this.fetch('GET', "Generic/GetSocialBtns");
        // Language
        $(function () {
            // Get Page Name
             var href = document.location.href;
             var lastPathSegment = href.substr(href.lastIndexOf("/") + 1);

             // Get Page Lang ar
             var index = document.location.href.lastIndexOf("/") + 1;
             var filenameWithExtension = document.location.href.substr(index);
             var filename = filenameWithExtension.split(".")[0]; // Get File Name Without Extension
             var checkPageLang = filename.substr(filename.length - 2);
             var removeLatestAr = filename.substring(0, filename.length - 3);

             function checkPageUrlLang() {
               if (localStorage.getItem("lang") == "en" && checkPageLang == "ar") {
                 window.location.href = "index.html";
               }
             }
             checkPageUrlLang();

            function checkLangDir() {
                 if (localStorage.getItem("lang") == "ar") {
                   $("body").addClass("rtl");
                 } else {
                   $("body").removeClass("rtl");
                 }
            }
            checkLangDir();

             function redirectToPageLang() {
               //if (localStorage.getItem("lang") == "ar") {
               //  window.location.href = `${filename}_ar.html`;
               //} else {
               //  window.location.href = `${removeLatestAr}.html`;
                 //}
                 window.location.reload();
             }

            //$(
            //    "select#changeLang option[value=" + localStorage.getItem("lang") + "]"
            //).attr("selected", "selected");
            //$("#changeLang").on("change", function () {
            //    var lang = $(this).val();
            //    // Set Lang
            //    localStorage.setItem("lang", lang);
            //    redirectToPageLang();
            //});
            //new SlimSelect({
            //    select: "#changeLang",
            //    showSearch: false,
            //    hideSelectedOption: true,
            //    // selected: localStorage.getItem("lang"),
            //    // disabled: false,
            //    // selected: false,
            //    // allowDeselectOption: false,
            //    // hideSelectedOption: true,
            //    onChange: (info) => {
            //        console.log(info.value);
            //    },
            //});
        });
        await this.setState({ whatsAppNumber: socialBtns });

    }
    render() {
        let { whatsAppNumber } = this.state;
        let number = whatsAppNumber && whatsAppNumber.filter(obj => {
            return obj.name == "WhatsAppNumber"
        })
        let className = 'nav';

        var showlocale = 'English';

        if (localStorage.getItem("lang") == 'en') {
            showlocale = 'Arabic'
        } 

        return (
            <nav id="nav" className={className}>
                <div className="container-fluid">
                    <div className="navbar">
                        <div className="nav-logo">
                            <Link to="/"><img src="assets/img/logo.svg" className="img-fluid" alt="" /></Link>
                        </div>
                        <div className="nav-icons flex-center">
                            <div className="nav-icon-btn nav-btn-select">
                                <div className="selectbox-slim selectbox-language">
                                    <button
                                        className="btn btn-transparent text-white"
                                        onClick={() => { this.changeText(showlocale) }}>
                                        {showlocale.toLowerCase() == "arabic" ? "عربى" :"English"}
                                    </button>
                                    {/*<select id="changeLang">*/}
                                    {/*    <option value="en">ENG</option>*/}
                                    {/*    <option value="ar">عربي</option>*/}
                                    {/*</select>*/}
                                </div>
                            </div>
                            <div className="nav-icon-btn"><span className="icon-telephone icon" />
                                <a href={number && number[0] && "tel:" + number[0].value} className="tel-dropdown">{number && number[0] && number[0].value} </a>
                            </div>
                            {/*<div className="nav-icon-btn"><a href="login.html"><span className="icon-user icon" /></a></div>*/}
                            <div id="openMenu" className="nav-icon-btn" onClick={() => this.handleClickNavMenu()}>
                                <div className="menu-bar">
                                    <span />
                                    <span />
                                    <span />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}
