import React, { Component } from 'react';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import ionRangeSlider from 'ion-rangeslider';
import { numberWithCommas } from '../_helpers/HelperFunctions'
import { Loading } from '../components/Loading';
import SlimSelect from 'slim-select'
import { GetLang } from '../_helpers/HelperFunctions'
import { FormattedMessage } from 'react-intl';
import Context from '../components/Context';



const priceFrom = 500000;
const priceTo = 3000000;
const areaFrom = 50;
const areaTo = 300;

export class ModalFilter extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            minPriceSearch: 500000,
            maxPriceSearch: 3500000,
            minAreaSearch: 50,
            maxAreaSearch: 1000,
            propertyType: '',
            location: '',
            locations: '',
            categories: '',
            loadingFetch: true,
            error: ''
        };
    }

    async fetch(method, endpoint, body) {
        try {
            const response = await fetch(`api/${endpoint}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    LangCode: GetLang(),
                },
            });
            if (!response.ok) {
                const error = (response && response.message) || response.statusText;
                throw error;
            } else
                return await response.json();
        } catch (error) {
            console.error(error);
            this.setState({ error });
        }
    }

    async componentDidMount() {
         const { categories } = this.context;

         let that = this;
         //let categories = await this.fetch('GET', "Generic/GetCategories");
         let locations = await this.fetch('GET', "Projects/GetDistricts")
         this.setState({ locations, location: locations && locations[0] && locations[0].id, categories, propertyType: categories && categories[0] && categories[0].id, loadingFetch: false }); 

        if (this.state.error === '' && this.state.loadingFetch === false) {
            $(document).ready(function () {
                new SlimSelect({
                    select: "#propertyTypeModal",
                    showSearch: false,
                    onChange: (info) => {
                        console.log(info.value);
                    },
                });
                new SlimSelect({
                    select: "#locationModal",
                    showSearch: false,
                    onChange: (info) => {
                        console.log(info.value);
                    },
                });
                $(".js-range-slider-meter").ionRangeSlider({
                    onStart: function (data) {
                        $("#mfgdInputValArea-1").text(numberWithCommas(data.from));
                        $("#mfgdInputValArea-2").text(numberWithCommas(data.to));
                    },
                    onChange: function (data) {
                        $("#mfgdInputValArea-1").text(numberWithCommas(data.from));
                        $("#mfgdInputValArea-2").text(numberWithCommas(data.to));
                        that.setState({ minAreaSearch: data.from })
                        that.setState({ maxAreaSearch: data.to })
                    },
                    onUpdate: function (data) {
                        $("#mfgdInputValArea-1").text(numberWithCommas(data.from));
                        $("#mfgdInputValArea-2").text(numberWithCommas(data.to));
                        that.setState({ minAreaSearch: data.from })
                        that.setState({ maxAreaSearch: data.to })
                    },
                });
                $(".js-range-slider-price").ionRangeSlider({
                    onStart: function (data) {
                        $("#mfgdInputValPrice-1").text(numberWithCommas(data.from));
                        $("#mfgdInputValPrice-2").text(numberWithCommas(data.to));
                    },
                    onChange: function (data) {
                        $("#mfgdInputValPrice-1").text(numberWithCommas(data.from));
                        $("#mfgdInputValPrice-2").text(numberWithCommas(data.to));
                        that.setState({ minPriceSearch: data.from })
                        that.setState({ maxPriceSearch: data.to })
                    },
                    onUpdate: function (data) {
                        $("#mfgdInputValPrice-1").text(numberWithCommas(data.from));
                        $("#mfgdInputValPrice-2").text(numberWithCommas(data.to));
                        that.setState({ minPriceSearch: data.from })
                        that.setState({ maxPriceSearch: data.to })
                    },
                });
            });
        }
        this.backToSPopupHandle();
    }

    handleChange = (event) => {
        event.preventDefault();
        let { name, value } = event.target;
        this.setState({ [name]: value })
        //console.log(value)
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        let { location, propertyType, minPriceSearch, maxPriceSearch, minAreaSearch, maxAreaSearch } = this.state;
        let queryString = "?catID=" + propertyType + "&district=" + location + "&price=" + minPriceSearch + "-" + maxPriceSearch + "&unit=" + minAreaSearch + "-" + maxAreaSearch;

        let result = await this.fetch('GET', "Search" + queryString);
        if (result.length > 0) {
            $(".modal-backdrop").fadeOut(0);
            document.body.className = document.body.className.replace("modal-open", "");
            document.body.style.paddingRight = "";
            this.props.history.push({
                pathname: '/SearchResult',
                search: queryString,
            })
        }
        else
            this.NotFoundHandle();

    }

    handleClearSearch = () => {
        let rangePrice = $(".js-range-slider-price").data("ionRangeSlider");
        let rangeMeter = $(".js-range-slider-meter").data("ionRangeSlider");
        rangePrice.reset();
        rangeMeter.reset();
        $("#modalCheckboxContainer").slideUp(300);
        $(".multiple-checkbox-container").slideUp(300);
        $(".input-checkbox").prop("checked", false);

        this.setState({
            minPrice: priceFrom,
            maxPrice: priceTo,
            minArea: areaFrom,
            maxArea: areaTo,
            propertyType: this.state.categories && this.state.categories[0] && this.state.categories[0].id,
            location: ''
        });
    }

    // Back To Search Popup
    backToSPopupHandle() {
        $("#backToSPopup").fadeOut(0);
        $(".modal-s-center").addClass('active');
        $("#snr-popup").fadeOut(0);
        $("#clearAll").addClass('active');
    }

    // Back To Search Popup
    NotFoundHandle() {
        $("#backToSPopup").fadeIn(0);
        $(".modal-s-center").removeClass('active');
        $("#snr-popup").fadeIn(0);
        $("#clearAll").removeClass('active');
    }

    render() {
        let { error, locations, location, propertyType, loadingFetch, minPrice, maxPrice, minArea, maxArea, categories } = this.state;
        //if (error)
        //    return <Error/>        
        return (
            <div className="modal fade" id="modalSearchFilter" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">

                    <div className="modal-content">
                        <div className="modal-body">

                            {loadingFetch === true ? <Loading /> :
                                <>
                                    <div className="modal-s-top flex-center justify-content-between">
                                        <button className="flex-center filter-title"><span className="icon-filter icon" />
                                            <div className="title ml-3"><FormattedMessage
                                                id='FiltersModal'
                                                defaultMessage='Filters'
                                            /></div>
                                        </button>
                                        <button className="d-flex close-filter opacity-hover" data-dismiss="modal"><span className="icon-cancel icon" /></button>
                                    </div>

                                    {/* No Results */}
                                    <div id="snr-popup" className="search-no-results">
                                        <div className="snr-dropdown">
                                            <div className="snr-title flex-center">
                                                <img src="assets/img/icons/search-no-results.svg" className="img-fluid" alt="" />
                                                <span><FormattedMessage
                                                    id="NoResults"
                                                    defaultMessage="No Results"
                                                /></span>
                                            </div>
                                            <div className="snr-text"><FormattedMessage
                                                id="NoResultsContent"
                                                defaultMessage="Try adjusting your search by changing your location, removing filters"
                                            /></div>
                                        </div>
                                    </div>

                                    <div className="modal-s-center">
                                        <div className="modal-filter-grid-double">
                                            <div className="modal-filter-dropdown">
                                                {/* Selectbox */}
                                                <div className="selectbox-slim">
                                                    <label className="ss-label text-uppercase">
                                                        <FormattedMessage
                                                            id='All_Locations'
                                                            defaultMessage='All Locations'
                                                        /></label>
                                                    <select id="locationModal" name="location" value={location} onChange={this.handleChange} >
                                                        <FormattedMessage id="Select_location"
                                                            defaultMessage="Select location">
                                                            {placeholder =>
                                                                <option value="" disabled>{placeholder}</option>
                                                            }
                                                        </FormattedMessage>
                                                        {locations && locations.map(location =>
                                                            <option value={location.id}>{location.name}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="modal-filter-dropdown">
                                                <div className="selectbox-slim">
                                                    <label className="ss-label text-uppercase"><FormattedMessage
                                                        id='Property_Type'
                                                        defaultMessage='Property Type'
                                                    /></label>
                                                    <select id="propertyTypeModal" name="propertyType" value={propertyType} onChange={this.handleChange}>
                                                        {categories && categories.length !== 0 && categories.map(cat =>
                                                            <option value={cat.id}>{cat.name}</option>                                                           
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="modalCheckboxContainer" className="modal-checkbox-container mt-4">
                                            {/* Start Row */}
                                            <div className="modal-checkbox-row">
                                                <div className="checkbox-toggle-header flex-center-between">
                                                    {/* if u want to check all inputs just add this class checkAll */}
                                                    <div className="checkAll checkbox-group flex-center">
                                                        <div className="fake-checkbox">
                                                            <input id="modalCheckbox1" type="checkbox" className="input-checkbox" />
                                                            <div className="fake-cb-child">
                                                                <span />
                                                            </div>
                                                        </div>
                                                        <label className="checkbox-text" htmlFor="modalCheckbox1">Apartment
                        </label>
                                                    </div>
                                                    <button className="checkbox-toggle-btn flex-center">
                                                        <div className="ml-2 mr-2">All Types</div> <span className="icon-chevron-down icon" />
                                                    </button>
                                                </div>
                                                <div className="multiple-checkbox-container">
                                                    <div className="multiple-checkbox-grid">
                                                        <div className="checkbox-group flex-center">
                                                            <div className="fake-checkbox">
                                                                <input id="modalCheckbox2" type="checkbox" className="input-checkbox" />
                                                                <div className="fake-cb-child">
                                                                    <span />
                                                                </div>
                                                            </div>
                                                            <label className="checkbox-text" htmlFor="modalCheckbox2">Apartment
                          </label>
                                                        </div>
                                                        <div className="checkbox-group flex-center">
                                                            <div className="fake-checkbox">
                                                                <input id="modalCheckbox3" type="checkbox" className="input-checkbox" />
                                                                <div className="fake-cb-child">
                                                                    <span />
                                                                </div>
                                                            </div>
                                                            <label className="checkbox-text" htmlFor="modalCheckbox3">Duplex
                          </label>
                                                        </div>
                                                        <div className="checkbox-group flex-center">
                                                            <div className="fake-checkbox">
                                                                <input id="modalCheckbox4" type="checkbox" className="input-checkbox" />
                                                                <div className="fake-cb-child">
                                                                    <span />
                                                                </div>
                                                            </div>
                                                            <label className="checkbox-text" htmlFor="modalCheckbox4">Duplex
                          </label>
                                                        </div>
                                                        <div className="checkbox-group flex-center">
                                                            <div className="fake-checkbox">
                                                                <input id="modalCheckbox5" type="checkbox" className="input-checkbox" />
                                                                <div className="fake-cb-child">
                                                                    <span />
                                                                </div>
                                                            </div>
                                                            <label className="checkbox-text" htmlFor="modalCheckbox5">Penthouse
                          </label>
                                                        </div>
                                                        <div className="checkbox-group flex-center">
                                                            <div className="fake-checkbox">
                                                                <input id="modalCheckbox7" type="checkbox" className="input-checkbox" />
                                                                <div className="fake-cb-child">
                                                                    <span />
                                                                </div>
                                                            </div>
                                                            <label className="checkbox-text" htmlFor="modalCheckbox7">Twin House
                          </label>
                                                        </div>
                                                        <div className="checkbox-group flex-center">
                                                            <div className="fake-checkbox">
                                                                <input id="modalCheckbox8" type="checkbox" className="input-checkbox" />
                                                                <div className="fake-cb-child">
                                                                    <span />
                                                                </div>
                                                            </div>
                                                            <label className="checkbox-text" htmlFor="modalCheckbox8">Studio
                          </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Start Row */}
                                            <div className="modal-checkbox-row">
                                                <div className="checkbox-toggle-header flex-center-between">
                                                    {/* if u want to check all inputs just add this class checkAll */}
                                                    <div className="checkAll checkbox-group flex-center">
                                                        <div className="fake-checkbox">
                                                            <input id="modalCheckbox9" type="checkbox" className="input-checkbox" />
                                                            <div className="fake-cb-child">
                                                                <span />
                                                            </div>
                                                        </div>
                                                        <label className="checkbox-text" htmlFor="modalCheckbox9">Villa
                        </label>
                                                    </div>
                                                    <button className="checkbox-toggle-btn flex-center">
                                                        <div className="ml-2 mr-2">All Types</div> <span className="icon-chevron-down icon" />
                                                    </button>
                                                </div>
                                                <div className="multiple-checkbox-container">
                                                    <div className="multiple-checkbox-grid">
                                                        <div className="checkbox-group flex-center">
                                                            <div className="fake-checkbox">
                                                                <input id="modalCheckbox11" type="checkbox" className="input-checkbox" />
                                                                <div className="fake-cb-child">
                                                                    <span />
                                                                </div>
                                                            </div>
                                                            <label className="checkbox-text" htmlFor="modalCheckbox11">Villa
                          </label>
                                                        </div>
                                                        <div className="checkbox-group flex-center">
                                                            <div className="fake-checkbox">
                                                                <input id="modalCheckbox12" type="checkbox" className="input-checkbox" />
                                                                <div className="fake-cb-child">
                                                                    <span />
                                                                </div>
                                                            </div>
                                                            <label className="checkbox-text" htmlFor="modalCheckbox12">Stand Alone
                          </label>
                                                        </div>
                                                        <div className="checkbox-group flex-center">
                                                            <div className="fake-checkbox">
                                                                <input id="modalCheckbox13" type="checkbox" className="input-checkbox" />
                                                                <div className="fake-cb-child">
                                                                    <span />
                                                                </div>
                                                            </div>
                                                            <label className="checkbox-text" htmlFor="modalCheckbox13">TownHouse
                          </label>
                                                        </div>
                                                        <div className="checkbox-group flex-center">
                                                            <div className="fake-checkbox">
                                                                <input id="modalCheckbox14" type="checkbox" className="input-checkbox" />
                                                                <div className="fake-cb-child">
                                                                    <span />
                                                                </div>
                                                            </div>
                                                            <label className="checkbox-text" htmlFor="modalCheckbox14">I Villa
                          </label>
                                                        </div>
                                                        <div className="checkbox-group flex-center">
                                                            <div className="fake-checkbox">
                                                                <input id="modalCheckbox15" type="checkbox" className="input-checkbox" />
                                                                <div className="fake-cb-child">
                                                                    <span />
                                                                </div>
                                                            </div>
                                                            <label className="checkbox-text" htmlFor="modalCheckbox15">Palace
                          </label>
                                                        </div>
                                                        <div className="checkbox-group flex-center">
                                                            <div className="fake-checkbox">
                                                                <input id="modalCheckbox10" type="checkbox" className="input-checkbox" />
                                                                <div className="fake-cb-child">
                                                                    <span />
                                                                </div>
                                                            </div>
                                                            <label className="checkbox-text" htmlFor="modalCheckbox10">Twin
                          </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Start Row */}
                                            <div className="modal-checkbox-row">
                                                <div className="checkbox-toggle-header flex-center-between">
                                                    <div className="checkbox-group flex-center">
                                                        <div className="fake-checkbox">
                                                            <input id="modalCheckbox18" type="checkbox" className="input-checkbox" />
                                                            <div className="fake-cb-child">
                                                                <span />
                                                            </div>
                                                        </div>
                                                        <label className="checkbox-text" htmlFor="modalCheckbox18">Chalet
                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-price-range">
                                            <div className="title"><FormattedMessage
                                                id='Price_Range'
                                                defaultMessage='Price Range'
                                            /> </div>
                                            {/*<input type="range" min={minPrice} max={maxPrice} value={minPrice} className="js-range-slider-price" id="myRange" id="propertyType" name="propertyType" value={propertyType} onChange={this.handleChange} />*/}
                                            <input type="text" dir="rtl" data-drag-interval="false" className="js-range-slider-price" name="my_range" defaultValue data-type="double" data-min={0} data-max={150000000} data-from={priceFrom} data-to={priceTo} data-grid="false" data-step={500000} />
                                            <div className="modal-filter-grid-double">
                                                <div className="mfgd-input">
                                                    <div id="mfgdInputValPrice-1" className="mfgd-input-val mr-2"> </div> <FormattedMessage
                                                        id='EGP'
                                                        defaultMessage='EGP'
                                                    />
                                                </div>
                                                <div className="mfgd-input">
                                                    <div id="mfgdInputValPrice-2" className="mfgd-input-val mr-2"> </div> <FormattedMessage
                                                        id='EGP'
                                                        defaultMessage='EGP'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-price-range">
                                            <div className="title"><FormattedMessage
                                                id='Area'
                                                defaultMessage='Area'
                                            /> (<FormattedMessage
                                                    id='m2'
                                                    defaultMessage='m2'
                                                />)</div>
                                            {/*<input type="range" min={minArea} max={maxArea} value={minArea} className="js-range-slider-price" id="myRange" id="propertyType" name="propertyType" value={propertyType} onChange={this.handleChange} />*/}
                                            <input type="text" data-drag-interval="false" className="js-range-slider-meter" name="my_range" defaultValue data-type="double" data-min={50} data-max={5000} data-from={areaFrom} data-to={areaTo} data-grid="false" data-step={50} />
                                            <div className="modal-filter-grid-double">
                                                <div className="mfgd-input">
                                                    <div id="mfgdInputValArea-1" className="mfgd-input-val"> </div>
                                                </div>
                                                <div className="mfgd-input">
                                                    <div id="mfgdInputValArea-2" className="mfgd-input-val"> </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-s-bottom fadeInDown">
                                        <div className="modal-filter-grid-double mb-0">
                                            <div className="button-default cancel-btn opacity-hover">
                                                <button id="clearAll" onClick={() => this.handleClearSearch()} className="justify-content-center width-fluid"><FormattedMessage
                                                    id='Clear_All'
                                                    defaultMessage='Clear All'
                                                /></button>
                                                <button id="backToSPopup" className="justify-content-center width-fluid" onClick={() => this.backToSPopupHandle()}><FormattedMessage
                                                    id="Back"
                                                    defaultMessage="Back"
                                                /></button>
                                            </div>
                                            <div className="button-default bt-black">
                                                <button className="justify-content-center width-fluid" onClick={(e) => this.handleSubmit(e)}><FormattedMessage
                                                    id='Search'
                                                    defaultMessage='Search'
                                                /></button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

