//import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import Wrapper from "./components/Wrapper";
import {ContextProvider} from "./components/ContextProvider";


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <Wrapper>
            <ContextProvider>
                <App />
            </ContextProvider>
        </Wrapper>
    </BrowserRouter>,
    rootElement);

registerServiceWorker();

