import React, { Component } from 'react'
import '../css/main.css';
import '../css/contact.css';
import '../css/rtl.css';
import { Loading } from '../components/Loading';
import { Error } from '../components/Error';
import {
    GetLang, calcNavHeight, Toast, validEmailRegex, validmobileRegex, validNumbersRegex,
    validSpacesRegex, validSpecialCharRegex
} from '../_helpers/HelperFunctions';
import { FormattedMessage } from 'react-intl';


export class ContactUsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactUsInfo: '',
            firstName: '',
            lastName: '',
            email: '',
            mobile: '',
            massage: '',
            loadingFetch: true,
            isSubmitting: false,
            error: '',
            errors: {
                firstName: '',
                lastName: '',
                email: '',
                mobile: '',
                massage: ''
            }
        };
    }

    async fetch(method, endpoint, body) {
        try {
            const response = await fetch(`api/${endpoint}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    LangCode: GetLang(),
                },
            });
            if (!response.ok) {
                const error = (response && response.message) || response.statusText;
                if (method === "POST") {
                    Toast.fire({
                        icon: 'error',
                        title: "There is an error, Try later."
                    })
                } else
                    throw error;
            } else {
                if (method == "POST") {
                    Toast.fire({
                        icon: 'success',
                        title: "Your massage was sent successfully."
                    })
                    await this.setState({ firstName: '', lastName: '', email: '', mobile: '', massage: '' });
                }
                return await response.json();
            }
        } catch (error) {
            console.error(error);
            this.setState({ error });
        }
    }


    async componentDidMount() {
        calcNavHeight();
        this.setState({ loadingFetch: true, contactUsInfo: await this.fetch('GET', "Contact/GetContactUs"), loadingFetch: false });
        calcNavHeight();
    }

    handleChange = (event) => {
        event.preventDefault();
        let { name, value } = event.target;
        let { errors } = this.state;
        let emptyField = '';

        switch (name) {
            case 'firstName':
                errors.firstName = value.trim() == emptyField ? 'This_field_is_required' : '';
                if (errors.firstName == '')
                    errors.firstName = validSpacesRegex.test(value) ? '' : 'Invalid_name';
                if (errors.firstName == '')
                    errors.firstName = validNumbersRegex.test(value) ? '' : 'Invalid_name';
                if (errors.firstName == '')
                    errors.firstName = validSpecialCharRegex.test(value) ? '' : 'Invalid_name';
                break;
            case 'lastName':
                errors.lastName = value.trim() == emptyField ? 'This_field_is_required' : '';
                if (errors.lastName == '')
                    errors.lastName = validSpacesRegex.test(value) ? '' : 'Invalid_name';
                if (errors.lastName == '')
                    errors.lastName = validNumbersRegex.test(value) ? '' : 'Invalid_name';
                if (errors.lastName == '')
                    errors.lastName = validSpecialCharRegex.test(value) ? '' : 'Invalid_name';
                break;
            case 'email':
                errors.email = value.trim() == emptyField ? 'This_field_is_required' : '';
                if (errors.email == '')
                    errors.email = validEmailRegex.test(value) ? '' : 'Invalid_email';
                break;
            case 'mobile':
                errors.mobile = value.trim() == emptyField ? 'This_field_is_required' : '';
                if (errors.mobile == '')
                    errors.mobile = validmobileRegex.test(value) ? '' : 'Invalid_phone';
                break;
            case 'massage':
                errors.massage = value.trim() == emptyField ? 'This_field_is_required!' : '';
                if (errors.massage == '')
                    errors.massage = validSpecialCharRegex.test(value) ? '' : 'Invalid_massage';
                if (errors.massage == '')
                    errors.massage = validSpacesRegex.test(value) ? '' : 'This_field_is_required';
                break;
            default:
                break;
        }

        this.setState({ errors, [name]: value }, () => {
            console.log(errors)
        })
    }

    validateForm = (errors, state) => {
        let valid = true;
        if (state.firstName == '')
            errors.firstName = "This_field_is_required";
        if (state.lastName == '')
            errors.lastName = "This_field_is_required";
        if (state.email == '')
            errors.email = "This_field_is_required";
        if (state.mobile == '')
            errors.mobile = "This_field_is_required";
        if (state.massage == '')
            errors.massage = "This_field_is_required";

        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );

        this.setState({ errors }, () => {
            console.log(errors)
        })
        return valid;
    }

    showInMapClicked = () => {
        let { contactUsInfo } = this.state;
        window.open("https://maps.google.com?q=" + contactUsInfo.latitude + "," + contactUsInfo.longitude);
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        let { errors, firstName, lastName, email, mobile, massage } = this.state;
        let name = firstName + " " + lastName;
        let comment = massage;
        let feedBackCategoryName = "Users";
        let body = { "name": name, "email": email, "mobile": mobile, "comment": comment, "feedBackCategoryName": feedBackCategoryName };

        if (this.validateForm(errors, this.state)) {
            console.info('Valid Form');
            this.setState({ isSubmitting: true });
            await this.fetch('POST', "Contact/", body)
            this.setState({ isSubmitting: false });
        } else {
            console.error('Invalid Form')
        }
    }

    render() {
        document.title = "Contact us";

        let { errors, firstName, lastName, email, mobile, massage, isSubmitting, error, contactUsInfo, loadingFetch } = this.state;
        if (error)
            return <Error />

        let address = contactUsInfo && contactUsInfo.address && contactUsInfo.address.split(",");
        let contactmobileSplit = contactUsInfo && contactUsInfo.mobile && contactUsInfo.mobile.replace(/(\d{3})(\d{4})(\d{4})/, '$1 $2 $3');
        return (
            <div className="wrapper-container">
                {loadingFetch === true ? <Loading /> :
                    <>
                        {/* Contact Header */}
                        <div className="contact-header">
                            {(() => {
                                if (contactUsInfo && contactUsInfo.image == null) {
                                    return <img src="assets/img/contact.png" className="img-fluid animated fadeInLeft" alt="" />
                                }
                                else {
                                    return <img src={contactUsInfo && contactUsInfo.image} className="img-fluid animated fadeInLeft" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/contact.png" }} />
                                }
                            })()}
                        </div>
                        {/* Contact Header */}
                        {/* Contact Container */}
                        <div className="contact-container">
                            <div className="container-fluid">
                                <div className="contact-content">
                                    <div className="contact-flexable d-flex justify-content-between">
                                        <div className="contact-data">
                                            <div className="contact-text-container mb-5">
                                                <div className="sub-title"><FormattedMessage
                                                    id='ContactUs'
                                                    defaultMessage='Contact Us'
                                                /></div>
                                                <div className="bg-title text-uppercase"><FormattedMessage
                                                    id='How_can_we_help_you'
                                                    defaultMessage='How can we help you?'
                                                /></div>
                                                <div className="text">{contactUsInfo && contactUsInfo.description}</div>
                                            </div>
                                            <div className="contact-text-address-tel d-flex">
                                                <div className="ct-address">
                                                    <div className="text" title="Address">{contactUsInfo && address[0]},<br />{contactUsInfo && address[1]},<br />{contactUsInfo && address[2]},<br />{contactUsInfo && address[3]}, {contactUsInfo && address[4]}</div>
                                                </div>
                                                <div className="ct-tel">
                                                    <div className="text" title="Fax">{contactUsInfo && "+2 " + contactUsInfo.fax} </div>
                                                    <div className="text" title="Telephone">{contactUsInfo && "+2 "+ contactUsInfo.telephone} </div>
                                                    <div className="text" title="Mobile">{contactUsInfo && "+2 " + contactmobileSplit}</div>
                                                    <div className="text" title="Email">{contactUsInfo && contactUsInfo.email}</div>
                                                </div>
                                            </div>
                                            <div className="contact-directions pt-4">
                                                <div className="d-inline-block">
                                                    <a onClick={this.showInMapClicked} className="contact-direction-btn flex-center"><FormattedMessage
                                                        id='getDirections'
                                                        defaultMessage='Get Directions'
                                                    /> <span className="icon-arrow-right icon" /></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="contact-form">
                                            <div className="cform-title"><FormattedMessage
                                                id='SendusAMessage'
                                                defaultMessage='Send us A Message'
                                            /></div>
                                            <div className="cform-sub mb-4"><FormattedMessage
                                                id='For_all_other_inquiries_please_complete_the_form_below'
                                                defaultMessage='For all other inquiries, please complete the form below.'
                                            />
                                                </div>
                                            <fieldset disabled={isSubmitting}>
                                                <form className="form-row" onSubmit={this.handleSubmit} autoComplete="off" method="post">
                                                    <div className="col-lg-6 col-sm-6">
                                                        <div className="cform-group" >
                                                            <FormattedMessage id="firstName"
                                                                defaultMessage="First name">
                                                                {placeholder =>
                                                                    <input type="text" placeholder={placeholder} name="firstName" value={firstName} onChange={this.handleChange} />
                                                                }
                                                            </FormattedMessage>
                                                        </div>
                                                        {errors.firstName.length > 0 &&
                                                            <font style={{ float: "left", color: "#dc3545", width: "100%", fontSize: "80%" }}>
                                                            <FormattedMessage
                                                                id={errors.firstName}
                                                                defaultMessage="Invalid first name"
                                                            />
                                                            </font>}
                                                    </div>
                                                    <div className="col-lg-6 col-sm-6">
                                                        <div className="cform-group">
                                                            <FormattedMessage id="lastName"
                                                                defaultMessage="Last name">
                                                                {placeholder =>
                                                                    <input type="text" placeholder={placeholder} name="lastName" value={lastName} onChange={this.handleChange} />
                                                                }
                                                            </FormattedMessage>
                                                        </div>
                                                        {errors.lastName.length > 0 &&
                                                            <font style={{ float: "left", color: "#dc3545", width: "100%", fontSize: "80%" }}>
                                                            <FormattedMessage
                                                                id={errors.lastName}
                                                                defaultMessage="Invalid last name"
                                                            />
                                                            </font>}
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="cform-group">
                                                            <FormattedMessage id="email"
                                                                defaultMessage="Email">
                                                                {placeholder =>
                                                                    <input type="text" placeholder={placeholder} name="email" value={email} onChange={this.handleChange} />
                                                                }
                                                            </FormattedMessage>
                                                        </div>
                                                        {errors.email.length > 0 &&
                                                            <font style={{ float: "left", color: "#dc3545", width: "100%", fontSize: "80%" }}>
                                                            <FormattedMessage
                                                                id={errors.email}
                                                                defaultMessage="Invalid email"
                                                            />
                                                            </font>}
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="cform-group d-flex">
                                                            <FormattedMessage id="mobile"
                                                                defaultMessage="Phone">
                                                                {placeholder =>
                                                                    <input type="tel" placeholder={placeholder} name="mobile" value={mobile} onChange={this.handleChange} />
                                                                }
                                                            </FormattedMessage>
                                                        </div>
                                                        {errors.mobile.length > 0 &&
                                                            <font style={{ float: "left", color: "#dc3545", width: "100%", fontSize: "80%" }}>
                                                            <FormattedMessage
                                                                id={errors.mobile}
                                                                defaultMessage="Invalid mobile"
                                                            />
                                                            </font>}
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="cform-group">
                                                            <FormattedMessage id="massage"
                                                                defaultMessage="Write your message">
                                                                {placeholder =>
                                                                    <textarea rows={4} placeholder={placeholder} name="massage" value={massage} onChange={this.handleChange} />
                                                                }
                                                            </FormattedMessage>
                                                        </div>
                                                        {errors.massage.length > 0 &&
                                                            <font style={{ float: "left", color: "#dc3545", width: "100%", fontSize: "80%" }}>
                                                            <FormattedMessage
                                                                id={errors.massage}
                                                                defaultMessage="Invalid massage"
                                                            />
                                                            </font>}
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="cform-action">
                                                            <div className="button-default width-fluid bt-black">
                                                                <button type="submit" disabled={isSubmitting}>{isSubmitting ? <><i class="fab fa-spinner fa-pulse"></i> <FormattedMessage
                                                                    id='Waiting'
                                                                    defaultMessage='Waiting...'
                                                                /></> : <FormattedMessage
                                                                        id='SendNow'
                                                                        defaultMessage='Send Now'
                                                                    />
                                                                }</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        )
    }
}
