import React, { Component } from 'react'
import '../css/main.css';
import '../css/contact.css';
import '../css/rtl.css';
import { Loading } from '../components/Loading';
import { Error } from '../components/Error';
import { Link } from 'react-router-dom';
import {
    GetLang, calcNavHeight, numberWithCommas
} from '../_helpers/HelperFunctions';
import { FormattedMessage } from 'react-intl';
export class SearchResultPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            location: '',
            propertyType: '',
            price: '',
            unit: '',
            loadingFetch: true,
            searchResult: [],
            error: '',
        };
    }
    async fetch(method, endpoint, body) {
        try {
            const response = await fetch(`api/${endpoint}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    LangCode: GetLang(),
                },
            });
            if (!response.ok) {
                const error = (response && response.message) || response.statusText;
                throw error;
            } else
                return await response.json();
        } catch (error) {
            console.error(error);
            this.setState({ error });
        }
    }

    async componentDidMount() {
        let { location, propertyType, price, unit } = this.state;
        calcNavHeight();
        const search = this.props.location.search; // could be '?foo=bar'
        const params = new URLSearchParams(search);

        location = params.get('district');
        propertyType = params.get('cat');
        price = params.get('price');
        unit = params.get('unit');
        let queryString = "?cat=" + propertyType + "&district=" + location + "&price=" + price + "&unit=" + unit;

        await this.setState({
            location, propertyType, price, unit, loadingFetch: true,
            searchResult: await this.fetch('GET', "Search" + queryString), loadingFetch: false
        });
    }

    render() {
        document.title = "Search Result";

        let { searchResult, loadingFetch } = this.state;
        return (
            <div className="wrapper-container">
                {/* Contact Container */}
                <div className="contact-container">
                    <div className="container-fluid">
                        <div className="contact-content">
                            <div className="wrapper-container">
                                {searchResult && searchResult.length != 0 || typeof (searchResult) !== 'undefined' ? loadingFetch == true ? <Loading /> :
                                    <>
                                        <h1 style={{ textAlign: 'center' }}><FormattedMessage
                                            id='SearchResultPage'
                                            defaultMessage='Search Result'
                                        /></h1>
                                        <div class="row">
                                            {searchResult && searchResult.map(property =>
                                                <div class="col-3 mt-5" key={property && property.id}>
                                                    <Link className="product-card" to={"/Property/" + property.id} >
                                                        {property && property.image == null ? <img src="assets/img/notfound.png" className="product-card-view img-fluid" alt="" />
                                                            : <img src={property && property.image} className="product-card-view img-fluid" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />}
                                                        <div className="product-card-title">{property && property.name}</div>
                                                        <div className="product-card-company">
                                                            <div className="pcc-logo">
                                                                {property && property.companyImage == null ? <img src="assets/img/notfound.png" className="img-fluid" alt="" />
                                                                    : <img src={property && property.companyImage} className="img-fluid" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />}
                                                            </div>
                                                            <div className="pcc-data">
                                                                <div className="pcc-title"><FormattedMessage
                                                                    id='Starting_Price'
                                                                    defaultMessage='STARTING PRICE'
                                                                /></div>
                                                                <div className="pcc-price">{property && property.priceSales && numberWithCommas(property.priceSales)} <FormattedMessage
                                                                    id='EGP'
                                                                    defaultMessage='EGP'
                                                                /></div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )}
                                        </div>
                                    </> : <h1 style={{ textAlign: 'center' }}><FormattedMessage
                                        id='NotFound'
                                        defaultMessage='Results not found'
                                    /></h1>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/* Contact Container */}
            </div>
        )
    }
}
