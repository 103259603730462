import React, { Component } from 'react'
import '../css/main.css';
import '../css/about-services.css';
import '../css/rtl.css';
import { Loading } from '../components/Loading';
import { Error } from '../components/Error';
import { FormattedMessage } from 'react-intl';
import { ServiceIDParam, GetLang, calcNavHeight } from '../_helpers/HelperFunctions';



export class ServicesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serviceCategories: '',
            serviceCat: '',
            loadingFetch: true,
            error: ''
        };
    }
    async fetch(method, endpoint, body) {
        try {
            const response = await fetch(`api/${endpoint}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    LangCode: GetLang(),
                },
            });
            if (!response.ok) {
                const error = (response && response.message) || response.statusText;
                throw error;
            } else
                return await response.json();
        } catch (error) {
            console.error(error);
            this.setState({ error });
        }
    }

    async componentDidMount() {
        calcNavHeight();
        this.setState({ serviceCategories: await this.fetch('GET', "Service/Category/" + ServiceIDParam), serviceCat: await this.fetch('GET', "Service/Cat/" + ServiceIDParam), loadingFetch: false });
        calcNavHeight();
    }

    render() {
        document.title = "Our services";

        let { error, serviceCategories, serviceCat, loadingFetch } = this.state;
        if (error)
            return <Error />
        return (
            <div className="wrapper-container">
                {/* Services Header */}
                {loadingFetch === true ? <Loading /> :
                    <>
                        <div className="about-header">
                            {(() => {
                                if (serviceCategories && serviceCategories.images && (serviceCategories.images.length == 0 || serviceCategories.images[0].image == null))
                                    return <img src="assets/img/services.png" className="img-fluid animated fadeInLeft" alt="" />
                                else
                                    return < img src={serviceCategories && serviceCategories.images && serviceCategories.images[0] && serviceCategories.images[0].image} className="img-fluid  animated fadeInLeft" alt="service image" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/services.png" }} />
                            })()}
                        </div>
                        {/* Services Header */}
                        {/* Services Container */}
                        <div className="about-container">
                            <div className="container-fluid">
                                <div className="about-content">
                                    <div className="about-flexable d-flex justify-content-between">
                                        <div className="about-data">
                                            <div className="about-text-container">
                                                <div className="bg-title text-uppercase"><FormattedMessage
                                                    id='GENERAL_BRIEF'
                                                    defaultMessage='GENERAL BRIEF'
                                                /> </div>
                                                <div className="text">
                                                    <div dangerouslySetInnerHTML={{ __html: serviceCategories && serviceCategories.description }}></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="about-sidebar">
                                            {/*<div className="download-btn mb-5">*/}
                                            {/*    <a href="#" className="dl-btn text-center">Download Our Profile</a>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                    {(() => {
                                        if (serviceCat && serviceCat.services && serviceCat.services.length === 0)
                                            return <FormattedMessage
                                                id='There_is_any_Service'
                                                defaultMessage='There is any Service until now.'
                                            /> 
                                        else
                                            return <div className="services-grid">
                                                {serviceCat && serviceCat.services.map((service, index) =>
                                                    <div className={index % 2 == 0 ? "sg-card animated fadeInLeft" : "sg-card animated fadeInRight"} key={index}>
                                                        <div className="sg-text">
                                                            <div className="bg-title text-uppercase">{service && service.name}</div>
                                                            <div className="text">{service && service.description}</div>
                                                        </div>
                                                        <div className="sg-view">
                                                            {(() => {
                                                                if (service && service.image == null) {
                                                                    return <img src="assets/img/notfound.png" className="img-fluid" alt="" />
                                                                }
                                                                else
                                                                    return <img src={service && service.image} className="img-fluid" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />
                                                            })()}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                    })()}
                                </div>
                            </div>
                        </div>
                        {/* Services Container */}
                    </>
                }
            </div>
        )
    }
}
