import Context from './Context';
import React from 'react';
import { GetLang, HomeIDParam } from '../_helpers/HelperFunctions';


export class ContextProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            socialBtns: '',
            categories: '',
            locations: '',
            sections:''
        };
    }

    async fetch(method, endpoint, body) {
        try {
            const response = await fetch(`api/${endpoint}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    LangCode: GetLang(),
                },
            });
            if (!response.ok) {
                const error = (response && response.message) || response.statusText;
                throw error;
            } else
                return await response.json();
        } catch (error) {
            console.error(error);
           // this.setState({ error });
        }
    }

    async componentDidMount() {
        let socialBtns = await this.fetch('GET', "Generic/GetSocialBtns");
        let categories = await this.fetch('GET', "Generic/GetCategories");
        let locations = await this.fetch('GET', "Projects/GetDistricts");
        let sections = await this.fetch('GET', "Wiki/GetByCategory/" + HomeIDParam);

        await this.setState({ socialBtns, categories, locations, sections });
    }
   

    render() {
        let { socialBtns, categories, locations, sections } = this.state;
        if (socialBtns !== '' || categories !== '' || locations !== '' || sections!=='') {
            return (
                <Context.Provider
                    value={{
                        socialBtns: socialBtns,
                        categories: categories,
                        locations: locations,
                        sections: sections
                    }}
                >
                    {this.props.children}
                </Context.Provider>
            )
        } else {
            return null; // or loading graphic
        }        
    }
}


