import React, { Component } from 'react'
import '../css/main.css';
import '../css/articles.css';
import '../css/rtl.css';
import moment from 'moment'
import Pagination from '../components/Pagination';
import { Loading } from '../components/Loading';
import { Error } from '../components/Error';
import { PressIDParam, GetLang, calcNavHeight } from '../_helpers/HelperFunctions';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Context from '../components/Context';





export class ArticlesPage extends Component {
    static contextType = Context;  

    constructor(props) {
        super(props);
        this.state = {
            pageInfo: '',
            articles: [],
            socialBtns: '',
            loadingFetch: true,
            error: '',
            pageOfItems: []
        };
        this.onChangePage = this.onChangePage.bind(this);
    }

    async fetch(method, endpoint, body) {
        try {
            const response = await fetch(`api/${endpoint}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    LangCode: GetLang(),
                },
            });
            if (!response.ok) {
                const error = (response && response.message) || response.statusText;
                throw error;
            } else
                return await response.json();
        } catch (error) {
            console.error(error);
            this.setState({ error });
        }
    }

    async componentDidMount() {
        const { socialBtns } = this.context;

        calcNavHeight();
        this.setState({ loadingFetch: true, articles: await this.fetch('GET', "Wiki/GetByCategory/" + PressIDParam), socialBtns, pageInfo: await this.fetch('GET', "Wiki/GetWikiCategoryById/" + PressIDParam), loadingFetch: false }); //: await this.fetch('GET', "Generic/GetSocialBtns")
        calcNavHeight();

    }

    onChangePage(pageOfItems) {
        // update state with new page of items
        this.setState({ pageOfItems });
    }

    openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    render() {
        document.title = "Press";

        let { error, articles, loadingFetch, pageOfItems, pageInfo, socialBtns } = this.state;
        if (error)
            return <Error />

        var url;
        var renderSocialBtns = socialBtns && socialBtns.map(function (btn, idx) {
            switch (btn.name) {
                case "showfacebook":
                    url = socialBtns.filter(obj => {
                        return obj.name === "facebookurl"
                    })
                    if (url.length > 0 && btn.value.toLowerCase() != 'false' && url[0].value.trim() != '') {
                        return <a href={url[0].value} title={GetLang() === 'ar' ? "������" : "facebook"} target="_blank" rel="noopener noreferrer" className="news-social-icon">
                            <i className="fab fa-facebook-f icon" />
                        </a>

                    }
                    break;
                case "showlinkedin":
                    url = socialBtns.filter(obj => {
                        return obj.name === "linkedinurl"
                    })

                    if (url.length > 0 && btn.value.toLowerCase() != 'false' && url[0].value.trim() != '')
                        return <a href={url[0].value} target="_blank" rel="noopener noreferrer" title={GetLang() === 'ar' ? "��������" : "linkedin"} className="news-social-icon">
                            <i className="fab fa-linkedin-in icon" />
                        </a>;
                    break;
                case "showinsta":
                    url = socialBtns.filter(obj => {
                        return obj.name === "instaUrl"
                    })

                    if (url.length > 0 && btn.value.toLowerCase() != 'false' && url[0].value.trim() != '')
                        return <a href={url[0].value} target="_blank" rel="noopener noreferrer" title={GetLang() === 'ar' ? "��������" : "instagram"} className="news-social-icon">
                            <i className="fab fa-instagram icon" />
                        </a>;
                    break;
                case "showyoutube":
                    url = socialBtns.filter(obj => {
                        return obj.name === "youtubeurl"
                    })
                    if (url.length > 0 && btn.value.toLowerCase() != 'false' && url[0].value.trim() != '')
                        return <a href={url[0].value} target="_blank" rel="noopener noreferrer" title={GetLang() === 'ar' ? "������" : "youtube"} className="news-social-icon">
                            <i className="fab fa-youtube icon" />
                        </a>;
                    break;
                default:
                    return null;

            }
        })

        return (
            <div class="wrapper-container">
                {loadingFetch === true ? <Loading /> :
                    <div className="news">
                        <div className="container-fluid">
                            <div className="news-wrapper">
                                <div className="news-main-title">{pageInfo && pageInfo.name} </div>
                                <div className="article-line" />
                                <div className="news-socialmedia">
                                    {renderSocialBtns}
                                    <button target="_blank" rel="noopener noreferrer" className="news-social-icon" onClick={() => window.print()}>
                                        <span className="icon-printer icon" />
                                    </button>
                                </div>
                                <div className="news-main-text">
                                    {pageInfo && pageInfo.description}
                                </div>
                                {(() => {
                                    if (articles && articles.length === 0)
                                        return <FormattedMessage
                                            id='There_is_any_article_until_now'
                                            defaultMessage='There is any article until now.'
                                        /> 
                                    else
                                        return <>
                                            <div className="articles-grid">
                                                {pageOfItems && pageOfItems.map(article =>
                                                    <Link className="article-row animated fadeInLeft" key={article && article.id} to={article && "Article/" + article.id}>
                                                        <div className="article-img">
                                                            {(() => {
                                                                if (article && article.images&& (article.images.length == 0 || article.images[0].image == null))
                                                                    return <img src="assets/img/notfound.png" className="img-fluid" alt="" />
                                                                else
                                                                    return < img src={article && article.images && article.images[0] && article.images[0].image} className="img-fluid" alt="article image" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />
                                                            })()}
                                                        </div>
                                                        <div className="article-details">
                                                            <div className="article-title">
                                                                {article && article.name}
                                                            </div>
                                                            <div className="article-text">
                                                                {article && article.description}
                                                            </div>
                                                            <div className="article-date">
                                                                {article && article.dateCreated && moment(article.dateCreated).format('LLL')}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )}
                                            </div>
                                            <div className="articles-pagination">
                                                <div aria-label="Page navigation example">
                                                    <Pagination items={articles} onChangePage={this.onChangePage} />
                                                </div>
                                            </div>
                                        </>
                                })()}
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
