import Swal from 'sweetalert2/dist/sweetalert2.all.min.js'
import $ from 'jquery';

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const IntegersRegex = RegExp(/^\d+$/);
export const IntegersGreaterThanZeroRegex = RegExp(/^[1-9][0-9]*$/);
export const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
export const validmobileRegex = RegExp(/^01[0-2,5]\d{8}$/);
export const validNumbersRegex = RegExp(/^([^0-9]*)$/);
export const validSpacesRegex = RegExp(/^\S*$/);
export const validSpecialCharRegex = RegExp(/^[^<>'\"/;`%@]*$/);

export const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 4000
});

export const AboutIDParam = 5;
export const PressIDParam = 2;
export const ServiceIDParam = 1;
export const CareersApplicationIDParam = 2;
export const CareersIDParam = 2;
export const ConnectIDParam = 3;
export const HomeIDParam = 4;
export const TabsNavMenu = 10004;






export function GetLang() {
    if (localStorage.getItem("lang") === null) {
        var userLang = navigator.language || navigator.userLanguage;
        if (userLang === "en" || userLang === "ar") {
            localStorage.setItem("lang", userLang);
            return userLang;
        }
        else {
            localStorage.getItem("lang","en");
            return "en";
        }
    } else
        return localStorage.getItem("lang");
}

export function AddActiveNavAndPadding() {
    document.body.style.paddingTop = '66.6406px';
    var element = document.getElementById("nav");
    element.classList.add("active-page");   
}

// Calc Nav height to set padding to body
export function calcNavHeight() {
    if ($("body").find("#headerScroller").length == 0) {
        $("body").css("paddingTop", $("#nav").innerHeight() + "px");
        $("#nav").addClass("active-page");
    }
}

// Calc Nav height to set padding to body
export function removeHeight() {
    if ($("body").find("#headerScroller").length == 0) {
        $("body").css("paddingTop", "");
        $("#nav").removeClass("active-page");
    }
}


