import React, { Component } from 'react'
import '../css/main.css';
import '../css/rtl.css';
import { Loading } from '../components/Loading';
import { Link } from 'react-router-dom';
import {
    GetLang, calcNavHeight, numberWithCommas
} from '../_helpers/HelperFunctions';
import { FormattedMessage } from 'react-intl';

export class SearchResultProjectsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            location: '',
            propertyType: '',
            loadingFetch: true,
            searchResult: [],
            error: '',
        };
    }
    async fetch(method, endpoint, body) {
        try {
            const response = await fetch(`api/${endpoint}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    LangCode: GetLang(),
                },
            });
            if (!response.ok) {
                const error = (response && response.message) || response.statusText;
                throw error;
            } else
                return await response.json();
        } catch (error) {
            console.error(error);
            this.setState({ error });
        }
    }

    async componentDidMount() {
        let { location, propertyType } = this.state;
        calcNavHeight();
        const search = this.props.location.search; // could be '?foo=bar'
        const params = new URLSearchParams(search);

        location = params.get('district');
        propertyType = params.get('cat');
        let queryString = "?cat=" + propertyType + "&district=" + location;
        if (propertyType === '') {
            await this.setState({
                searchResult: undefined, loadingFetch: false
            });
        } else {
            await this.setState({
                location, propertyType, loadingFetch: true,
                searchResult: await this.fetch('GET', "Search/SearchProjects" + queryString), loadingFetch: false
            });
        }
    }

    render() {
        document.title = "Search Result";
        let { searchResult, loadingFetch } = this.state;
        return (
            <div className="wrapper-container">
                <div className="projects-container">
                    <div className="container-fluid">
                        {searchResult && searchResult.length != 0 || typeof (searchResult) !== 'undefined' ? loadingFetch == true ? <Loading /> :
                            <>
                                <h1 style={{ textAlign: 'center' }}><FormattedMessage
                                    id='SearchResultPage'
                                    defaultMessage='Search Result'
                                /></h1>
                                <div className="projects-grid">
                                    {searchResult && searchResult.map(project =>
                                        <Link to={"/Project/" + project.id} key={project && project.id} className="project-card">
                                            {project && project.logo == null ? <img src="assets/img/notfound.png" className="img-fluid" alt="" />
                                                : <img src={project && project.logo} className="img-fluid" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />}
                                        </Link>
                                    )}
                                </div>
                            </> : <h1 style={{ textAlign: 'center' }}><FormattedMessage
                                id='NotFound'
                                defaultMessage='Results not found'
                            /></h1>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
