import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { FormattedMessage } from 'react-intl';
import { Loading } from '../components/Loading';
import { Error } from '../components/Error';
import { GetLang, TabsNavMenu } from '../_helpers/HelperFunctions';
import Context from '../components/Context';


export class NavMenu extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            error: '',
            socialBtns: '',
            loadingFetch: true,
            categories: '',
            tabs: '',
        };
    }
    async fetch(method, endpoint, body) {
        try {
            const response = await fetch(`api/${endpoint}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    LangCode: GetLang(),
                },
            });
            if (!response.ok) {
                const error = (response && response.message) || response.statusText;
                throw error;
            } else
                return await response.json();
        } catch (error) {
            console.error(error);
            this.setState({ error });
        }
    }

    async componentDidMount() {
        const { socialBtns, categories } = this.context;
        
        //await this.fetch('GET', "Generic/GetCategories")
        this.setState({ categories: categories , tabs: await this.fetch('GET', "Wiki/GetByCategory/" + TabsNavMenu), socialBtns: socialBtns, loadingFetch: false });
    }

    handleClick = () => {
        $("#menuContainer").fadeOut(600);
    }

    render() {
        let { categories, error, loadingFetch, tabs, socialBtns } = this.state;
        var url;
        var renderSocialBtns = socialBtns && socialBtns.map(function (btn, idx) {
            switch (btn.name) {
                case "showfacebook":
                    url = socialBtns.filter(obj => {
                        return obj.name === "facebookurl"
                    })
                    if (url.length > 0 && btn.value.toLowerCase() != 'false' && url[0].value.trim() != '') {
                        //console.log(true)
                        return <a href={url[0].value} target="_blank" rel="noopener noreferrer" key={idx} className="social-icon opacity-hover">
                            <i className="fab fa-facebook-f" />
                        </a>
                    }
                    break;
                case "showlinkedin":
                    url = socialBtns.filter(obj => {
                        return obj.name === "linkedinurl"
                    })
                    if (url.length > 0 && btn.value.toLowerCase() != 'false' && url[0].value.trim() != '')
                        return <a href={url[0].value} target="_blank" rel="noopener noreferrer" key={idx} className="social-icon opacity-hover">
                            <i className="fab fa-linkedin-in" />
                        </a>

                    break;
                case "showinsta":
                    url = socialBtns.filter(obj => {
                        return obj.name === "instaUrl"
                    })
                    if (url.length > 0 && btn.value.toLowerCase() != 'false' && url[0].value.trim() != '')
                        return <a href={url[0].value} target="_blank" rel="noopener noreferrer" key={idx} className="social-icon opacity-hover">
                            <i className="fab fa-instagram" />
                        </a>
                    break;
                case "showyoutube":
                    url = socialBtns.filter(obj => {
                        return obj.name === "youtubeurl"
                    })
                    if (url.length > 0 && btn.value.toLowerCase() != 'false' && url[0].value.trim() != '')
                        return <a href={url[0].value} target="_blank" rel="noopener noreferrer" key={idx} className="social-icon opacity-hover">
                            <i className="fab fa-youtube" />
                        </a>
                    break;
                default:
                    return null;

            }
        })

        return (
            <div id="menuContainer" className="menu-container">
                <div className="menu-wrapper">
                    <div id="menuTop" className="menu-top">
                        <nav className="nav">
                            <div className="container-fluid">
                                <div className="navbar">
                                    <div className="nav-logo">
                                        <Link to="/"><img src="assets/img/logo-black.svg" className="img-fluid" alt="" onClick={() => this.handleClick()} /></Link>
                                    </div>
                                    <div id="closeMenu" className="nav-icons flex-center" onClick={() => this.handleClick()}>
                                        <div className="nav-icon-btn"><span className="icon-cancel black-color icon" /></div>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                    <div id="menuBody" className="menu-body">
                        <div className="container-fluid">
                            {loadingFetch === true ? <Loading /> :
                                <div className="menu-body-row d-flex justify-content-between">
                                    <div className="menu-sidebar">


                                        {tabs && tabs.length !== 0 && tabs.map((cat,indx) =>
                                            <a className="ms-link flex-center" key={indx} href={cat && cat.targetUrl} onClick={() => this.handleClick()} >
                                                <div className="ms-text">
                                                    <div className="ms-sub-title text-uppercase">
                                                        {cat && cat.description}
                                                    </div>
                                                    <div className="title">
                                                        {cat && cat.name}
                                                   </div>
                                                </div>
                                                <div className="ms-icon">
                                                    <span className="icon-arrow-right icon" />
                                                </div>
                                            </a>
                                        )}
                                        {/*<Link className="ms-link flex-center" to={"/About"} onClick={() => this.handleClick()} >*/}
                                        {/*    <div className="ms-text">*/}
                                        {/*        <div className="ms-sub-title text-uppercase"><FormattedMessage*/}
                                        {/*            id='About_us'*/}
                                        {/*            defaultMessage='About Us'*/}
                                        {/*        /></div>*/}
                                        {/*        <div className="title"><FormattedMessage*/}
                                        {/*            id='Who_we_are'*/}
                                        {/*            defaultMessage='Who we are'*/}
                                        {/*        /></div>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="ms-icon">*/}
                                        {/*        <span className="icon-arrow-right icon" />*/}
                                        {/*    </div>*/}
                                        {/*</Link>*/}
                                        {/*<Link className="ms-link flex-center" to={"/Services"} onClick={() => this.handleClick()} >*/}
                                        {/*    <div className="ms-text">*/}
                                        {/*        <div className="ms-sub-title text-uppercase"><FormattedMessage*/}
                                        {/*            id='Our_services'*/}
                                        {/*            defaultMessage='Our Services'*/}
                                        {/*        /> </div>*/}
                                        {/*        <div className="title"><FormattedMessage*/}
                                        {/*            id='Properties_Management'*/}
                                        {/*            defaultMessage='Properties Management'*/}
                                        {/*        /> </div>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="ms-icon">*/}
                                        {/*        <span className="icon-arrow-right icon" />*/}
                                        {/*    </div>*/}
                                        {/*</Link>*/}
                                        {/*<Link className="ms-link flex-center" to={"/ContactUs"} onClick={() => this.handleClick()} >*/}
                                        {/*    <div className="ms-text">*/}
                                        {/*        <div className="title"><FormattedMessage*/}
                                        {/*            id='ContactUs'*/}
                                        {/*            defaultMessage='Contact us'*/}
                                        {/*        /></div>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="ms-icon">*/}
                                        {/*        <span className="icon-arrow-right icon" />*/}
                                        {/*    </div>*/}
                                        {/*</Link>*/}
                                        {/*<Link className="ms-link flex-center" to={"/Careers"} onClick={() => this.handleClick()} >*/}
                                        {/*    <div className="ms-text">*/}
                                        {/*        <div className="title"><FormattedMessage*/}
                                        {/*            id='Careers'*/}
                                        {/*            defaultMessage='Careers'*/}
                                        {/*        /></div>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="ms-icon">*/}
                                        {/*        <span className="icon-arrow-right icon" />*/}
                                        {/*    </div>*/}
                                        {/*</Link>*/}
                                    </div>
                                    <div className="menu-body-cards">
                                        {error === '' ?
                                            <div className="menu-body-grid">
                                                {categories && categories.length !== 0 && categories.map((cat,indx) =>
                                                    <Link className="mbg-card flex-center" key={indx} to={"/Projects/" + cat.id} onClick={() => this.handleClick()} >
                                                        <span className={cat.styleValue + " icon"} />
                                                        <div className="mbg-title">
                                                            {cat.name}
                                                            {/*    <FormattedMessage*/}
                                                            {/*    id={cat.name.toLowerCase()}*/}
                                                            {/*    defaultMessage=*/}
                                                            {/*/>*/}
                                                        </div>
                                                    </Link>
                                                )}
                                                {/*<Link className="mbg-card flex-center" to={"/Projects/All"} onClick={() => this.handleClick()} >*/}
                                                {/*    <span className="icon-diamond icon" />*/}
                                                {/*    <div className="mbg-title"><FormattedMessage*/}
                                                {/*        id='ExclusiveProjects'*/}
                                                {/*        defaultMessage='Exclusive Projects'*/}
                                                {/*    /></div>*/}
                                                {/*</Link>*/}
                                            </div> : ''}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div id="menuBottom" className="menu-bottom">
                        <div className="container-fluid">
                            <div className="footer-bottom flex-center justify-content-between">
                                <div className="text black-color"><FormattedMessage
                                    id='rights'
                                    defaultMessage='Capital Lords 2021 © All rights reserved'
                                /></div>
                                {/*<div className="footer-b-links">*/}
                                {/*    <a href="#">Cookies Policy</a>*/}
                                {/*    <a href="#">Legal Notice</a>*/}
                                {/*    <a href="#">Privacy Policy </a>*/}
                                {/*    <a href="#">Sit map</a>*/}
                                {/*</div>*/}
                                <div className="menu-socialmedia flex-center">
                                    <div className="text ml-2 mr-2"><FormattedMessage
                                        id='Follow_us_on'
                                        defaultMessage='Follow us on'
                                    /></div>
                                    <div className="menu-social">
                                        {renderSocialBtns}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
