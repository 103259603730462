import React, { Component } from 'react'

export class Loading extends Component {
    render() {
        return (
            <div className="windows8 loadDiv" > {/*style={{margin:'0 auto',marginTop:'6%',paddingBottom:'10px'}}*/}
                <div className="wBall" id="wBall_1">
                    <div className="wInnerBall"></div>
                </div>
                <div className="wBall" id="wBall_2">
                    <div className="wInnerBall"></div>
                </div>
                <div className="wBall" id="wBall_3">
                    <div className="wInnerBall"></div>
                </div>
                <div className="wBall" id="wBall_4">
                    <div className="wInnerBall"></div>
                </div>
                <div className="wBall" id="wBall_5">
                    <div className="wInnerBall"></div>
                </div>
            </div>
        )
    }
}
