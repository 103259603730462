import React, { Component } from 'react'
import { ModalFilter } from '../components/ModalFilter';
import { Link } from 'react-router-dom';
import { ServicesNewsCards } from '../components/ServicesNewsCards';
import SlimSelect from 'slim-select'
import Swiper, { Navigation, Pagination } from "swiper"
// core version + navigation, pagination modules:
//import 'swiper/swiper.scss';
//import 'swiper/components/navigation/navigation.scss';
//import 'swiper/components/pagination/pagination.scss';
//import 'swiper/components/scrollbar/scrollbar.scss';
import $ from 'jquery';
import { Loading } from '../components/Loading';
import { Error } from '../components/Error';
import { ServiceIDParam, GetLang } from '../_helpers/HelperFunctions';
import { FormattedMessage } from 'react-intl';
import '../css/swiper-bundle.min.css'
import '../css/main.css';
import '../css/rtl.css';
import '../css/paginationSwiper.css';
//import "swiper/swiper-bundle.min.css";
import Context from '../components/Context';



// configure Swiper to use modules
Swiper.use([Navigation, Pagination]);

export class HomePage extends Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            wantTo: 'buy',
            categories: '',
            sections: [],
            propertyType: '',
            location: '',
            locations: '',
            imagesSlider: '',
            servicesInfo: '',
            projects: '',
            loadingFetch: true,
            error: ''
        };
    }

    async fetch(method, endpoint, body) {
        try {
            const response = await fetch(`api/${endpoint}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    LangCode: GetLang(),
                },
            });
            if (!response.ok) {
                const error = (response && response.message) || response.statusText;
                throw error;
            } else
                return await response.json();
        } catch (error) {
            console.error(error);
            this.setState({ error });
        }
    }

    // Callback IF Condition Header Bottom
    headerBottomScrollerChecker() {
        var mainHeaderBottom =
            $("#headerScroller").position().top +
            $("#headerScroller").outerHeight(true);
        if ($(window).scrollTop() >= mainHeaderBottom) {
            $("#nav").addClass("active");
            $(".transition-title").removeClass("active");
        } else {
            $("#nav").removeClass("active");
        }
    }

    // Back To Search
    backToSearchHandle() {
        $(".main-adv-search").addClass('active');
        $("#back-to-search").fadeOut(0);
        $("#snr-header").fadeOut(0);
        $(".filter-slider-bar").addClass('active');
    }

    // Not found
    NotFoundHandle() {
        $(".main-adv-search").removeClass('active');
        $("#back-to-search").fadeIn(0);
        $("#snr-header").fadeIn(0);
        $(".filter-slider-bar").removeClass('active');
    }

    async componentDidMount() {
        //  func.load();
        const { categories, locations, sections } = this.context;

        document.body.style.paddingTop = '80.625px';
        var element = document.getElementById("nav");
        element.classList.add("active-page");

        let that = this;

        $(window).scroll(function () {
            if ($(window).scrollTop() > 0) {
                $("#navigationBar").addClass("active");
            } else {
                $("#navigationBar").removeClass("active");
            }
        });

        // Header Scroller
        $(window).on("scroll", function () {
            if ($(window).scrollTop() > 0 && $("#headerScroller")[0]) {
                $(".transition-title").addClass("active");
                that.headerBottomScrollerChecker();
            } else {
                $(".transition-title").removeClass("active");
                $("#nav").removeClass("active");
            }
        });

        await this.setState({
            loadingFetch: true
        });
        // let locations = await this.fetch('GET', "Projects/GetDistricts");
        let projects = await this.fetch('GET', "Projects/GetRecommended");
        let imagesSlider = await this.fetch('GET', "HomeSlider/GetByCatName/Filter");
        let servicesInfo = await this.fetch('GET', "Service/Category/" + ServiceIDParam);
        // let categories = await this.fetch('GET', "Generic/GetCategories");
        // let sections = await this.fetch('GET', "Wiki/GetByCategory/" + HomeIDParam);


        let firstProjects = projects && projects.slice(0, 12);

        await this.setState({ locations, location: locations && locations[0] && locations[0].id, projects: firstProjects, imagesSlider, servicesInfo, categories, propertyType: categories && categories[0] && categories[0].id, sections, loadingFetch: false });
        if (this.state.error === '' && this.state.loadingFetch === false) {
            $(document).ready(function () {
                new SlimSelect({
                    select: "#propertyType",
                    showSearch: false,
                    onChange: (info) => {
                        console.log(info.value);
                    },
                });
                new SlimSelect({
                    select: "#wantTo",
                    showSearch: false,
                    onChange: (info) => {
                        console.log(info.value);
                    },
                });
                new SlimSelect({
                    select: "#location",
                    showSearch: false,
                    onChange: (info) => {
                        console.log(info.value);
                    },
                });
                new Swiper('.swiper-container', {
                    // Optional parameters
                    direction: 'horizontal',
                    loop: false,
                    // If we need pagination
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },

                    // Navigation arrows
                    navigation: {
                        nextEl: '.arrow-next',
                        prevEl: '.arrow-prev',
                    },
                    autoplay: false,
                    speed: 700,

                    // And if we need scrollbar
                    scrollbar: {
                        el: '.swiper-scrollbar',
                    },
                    //renderBullet: function (index, className) {
                    //    return '<span class="' + className + '" style=' + { "height": "36px", "display": "block", "borderRadius": "0", "background": "#000", "opacity": ".2", "margin": "5px 0 !important", "width": "3px", "position": "static" }+'>' + (index + 1) + '</span>';
                    //}
                });
                document.body.style.removeProperty("padding-top")
                var element = document.getElementById("nav");
                element.classList.remove("active-page");
            })
        }
        this.backToSearchHandle();
    }

    handleChange = (event) => {
        event.preventDefault();
        let { name, value } = event.target;
        if (name === "wantTo") {
            if (value.toLowerCase !== "rent") {
                const arrOfRent = this.state.categories && this.state.categories.filter(category => category.nameENG.toLowerCase() === "rent" && category.nameENG.toLowerCase() !== "all");
                this.setState({ propertyType: arrOfRent && arrOfRent[0] && arrOfRent[0].id })
            } else {
                const arrOfBuy = this.state.categories && this.state.categories.filter(category => category.nameENG.toLowerCase() !== "rent" && category.nameENG.toLowerCase() !== "vacation" && category.nameENG.toLowerCase() !== "all");
                this.setState({ propertyType: arrOfBuy && arrOfBuy[0] && arrOfBuy[0].id })
            }

        }
        this.setState({ [name]: value })
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        let { location, propertyType } = this.state;       

        let queryString = "?catID=" + propertyType + "&district=" + location;

        let result = await this.fetch('GET', "Search/SearchProjects" + queryString)

        if (result.length > 0) {
            this.props.history.push({
                pathname: '/SearchResultProjects',
                search: queryString,
            })
        } else
            this.NotFoundHandle();

    }

    render() {
        document.title = "Home";

        let { error, locations, sections, projects, location, wantTo, imagesSlider, propertyType, servicesInfo, loadingFetch, categories } = this.state;
        if (error)
            return <Error />
        let lang = GetLang();
        console.log("categories",categories)
        const arrOfBuy = categories && categories.filter(category => category.nameENG.toLowerCase() !== "rent" && category.nameENG.toLowerCase() !== "vacation"&& category.nameENG.toLowerCase() !== "all");
        const arrOfRent = categories && categories.filter(category => category.nameENG.toLowerCase() === "rent" || category.nameENG.toLowerCase() === "vacation"&& category.nameENG.toLowerCase() !== "all");
        const infoVacation = categories && categories.filter(category => category.nameENG.toLowerCase() === "all");
        const sectionsSliceTop = sections && sections.slice(0, 2);
        return (
            <>
                <ModalFilter history={this.props.history} />
                <div id="Home" className="wrapper-container">
                    {loadingFetch == true ? <Loading /> :
                        <>
                            <div id="headerScroller" className="home-header animated fadeInDown">
                                <div className="swiper-container home-swiper-container">
                                    {/* Additional required wrapper */}
                                    <div className="swiper-wrapper" style={{ direction: lang === 'ar' ? 'rtl' : 'ltr' }}>
                                        {imagesSlider && imagesSlider.map(image => {
                                            if (image.video !== null) {
                                                return <div className="swiper-slide animated fadeInDown">
                                                    <div className="slide-home-card">
                                                        <div className="slide-home-video">
                                                            <video autoPlay loop muted>
                                                                <source src={image && image.video} type="video/mp4" />
                                                      Your browser does not support the video tag.
                                                    </video>
                                                        </div>
                                                        <div className="overlay" />
                                                    </div>
                                                </div>
                                            } else {
                                                return <div className="swiper-slide animated fadeInDown" key={image && image.id}>
                                                    <div className="slide-home-card" style={{ backgroundImage: `url(${image.image}),url("assets/img/home/slider-1.png")` }}>
                                                        <div className="overlay" />
                                                    </div>
                                                </div>
                                            }
                                        })}
                                    </div>
                                    {/* Swiper Pagination And Arrows */}
                                    <div className="container-fluid position-relative">
                                        <div className="swiper-pagination-arrows">
                                            <div className="swiper-arrows">
                                                <button className="arrow-icon arrow-next"><span className="icon-arrow-right icon" /></button>
                                                <button className="arrow-icon arrow-prev"><span className="icon-arrow-left icon" /></button>
                                            </div>
                                            <div className="swiper-pagination" /> {/*style={{ "background": "#fff","display": "block", "margin": "5px 0 !important", "width": "3px", "height": "36px", "borderRadius": "0", "position": "static" }} */}
                                        </div>
                                    </div>
                                    {/* Search Filter */}
                                    <div className="search-filter">
                                        <div className="search-filter-container">
                                            <div className="bg-title search-filter-header text-uppercase"><FormattedMessage
                                                id='Find_your_dream_property'
                                                defaultMessage='Find your dream property.'
                                            />
                                            </div>
                                            <form onSubmit={this.handleSubmit} autoComplete="off" method="get">
                                                <div className="filter-slider-bar d-flex">
                                                    <div className="selectbox-slim">
                                                        <label className="ss-label text-uppercase"><FormattedMessage
                                                            id='I_Want_To'
                                                            defaultMessage='I Want To'
                                                        /></label>
                                                        <select id="wantTo" name="wantTo" value={wantTo} onChange={this.handleChange}>
                                                            {/* id for Run Select Box */}
                                                            <FormattedMessage id="Buy"
                                                                defaultMessage="Buy">
                                                                {placeholder =>
                                                                    <option value="buy">{placeholder}</option>
                                                                }
                                                            </FormattedMessage>
                                                            <FormattedMessage id="rent"
                                                                defaultMessage="Rent">
                                                                {placeholder =>
                                                                    <option value="rent">{placeholder}</option>
                                                                }
                                                            </FormattedMessage>
                                                        </select>
                                                    </div>
                                                    <div className="selectbox-slim">
                                                        <label className="ss-label text-uppercase"><FormattedMessage
                                                            id='Property_Type'
                                                            defaultMessage='Property Type'
                                                        /></label>
                                                        <select id="propertyType" name="propertyType" value={propertyType} onChange={this.handleChange}>
                                                            {/* id for Run Select Box */}
                                                            {wantTo === "buy" ?
                                                                arrOfBuy && arrOfBuy.length !== 0 && arrOfBuy.map(cat =>
                                                                    <option value={cat.id}>{cat.name}</option>
                                                                    //<FormattedMessage id={cat.name.toLowerCase()}
                                                                    //    defaultMessage={cat.name}>
                                                                    //    {placeholder =>
                                                                    //        <option value={cat.name.toLowerCase()}>{placeholder}</option>
                                                                    //    }
                                                                    //</FormattedMessage>
                                                                )
                                                                : arrOfRent && arrOfRent.length !== 0 && arrOfRent.map(cat =>
                                                                    <option value={cat.id}>{cat.name}</option>
                                                                    //<FormattedMessage id={cat.name.toLowerCase()}
                                                                    //    defaultMessage={cat.name}>
                                                                    //    {placeholder =>
                                                                    //        <option value={cat.name.toLowerCase()}>{placeholder}</option>
                                                                    //    }
                                                                    //</FormattedMessage>
                                                                )
                                                            }

                                                        </select>
                                                    </div>
                                                    <div className="selectbox-slim">
                                                        <label className="ss-label text-uppercase"><FormattedMessage
                                                            id='Locations'
                                                            defaultMessage='Locations'
                                                        /></label>
                                                        <select id="location" name="location" value={location} onChange={this.handleChange} >
                                                            <FormattedMessage id="Select_location"
                                                                defaultMessage="Select location">
                                                                {placeholder =>
                                                                    <option value="" disabled>{placeholder}</option>
                                                                }
                                                            </FormattedMessage>
                                                            {locations && locations.map(location =>
                                                                <option key={location && location.id} value={location.id}>{location.name}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                    {/* Filter Action */}
                                                    <div className="filter-bar-action flex-center">
                                                        <div className="button-default bt-black flex-center-center width-fluid">
                                                            <button id="actionTest" type="submit" className="text-center width-fluid flex-center-center"><span className="icon-search icon" />
                                                                <FormattedMessage
                                                                    id='Search'
                                                                    defaultMessage='Search'
                                                                /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <div id="snr-header" className="search-no-results animated fadeInDown">
                                                <div className="snr-title flex-center">
                                                    <img src="assets/img/icons/search-no-results.svg" className="img-fluid" alt="" />
                                                    <span><FormattedMessage
                                                        id="NoResults"
                                                        defaultMessage="No Results"
                                                    /></span>
                                                </div>
                                                <div className="snr-text"><FormattedMessage
                                                    id="NoResultsContent"
                                                    defaultMessage="Try adjusting your search by changing your location, removing filters"
                                                /></div>
                                            </div>
                                            <div className="search-filter-advanced">
                                                <div className="d-inline-block">
                                                    <button id="back-to-search" className="advanced-search-link opacity-hover back-to-search flex-center" onClick={() => { this.backToSearchHandle() }}>
                                                        <span className="icon-arrow-left icon"></span>
                                                        <span className="asl-text text-uppercase"><FormattedMessage
                                                            id="Back"
                                                            defaultMessage="Back"
                                                        /></span>
                                                    </button>

                                                    <button data-toggle="modal" data-target="#modalSearchFilter" className="advanced-search-link main-adv-search opacity-hover flex-center">
                                                        <span className="asl-text text-uppercase"><FormattedMessage
                                                            id='Advanced_Search'
                                                            defaultMessage='Advanced Search'
                                                        /></span>
                                                        <span className="icon-arrow-right icon" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Home Grid */}
                            <div className="home-grid-container">
                                <div className="container-fluid">
                                    <div className="home-grid-double">
                                        {sectionsSliceTop && sectionsSliceTop.map(section => {
                                            if (section == null)
                                                return <div className="home-grid-card"> </div>
                                            else
                                                return <a className="home-grid-card" href={section && section.targetUrl} >
                                                    {(() => {
                                                        if (section && section.image == null)
                                                            return <img src="assets/img/home/card-1.png" className="img-fluid" alt="" />
                                                        else
                                                            return <img src={section && section.image} className="img-fluid" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/home/card-1.png" }} />
                                                    })()}
                                                    <div className="home-grid-card-data gradient-easing">
                                                        <div className="bg-title text-uppercase">
                                                            {section && section.name}
                                                            <span className="icon-arrow-right icon" />
                                                        </div>
                                                    </div>
                                                </a>
                                        }
                                        )}


                                        {/*<Link className="home-grid-card" to={"Projects/" + infoVacation[0].id} >*/}
                                        {/*    {(() => {*/}
                                        {/*        if (imagesSlider && (imagesSlider.length == 0 || imagesSlider[1].image == null))*/}
                                        {/*            return <img src="assets/img/home/card-2.png" className="img-fluid" alt="" />*/}
                                        {/*        else*/}
                                        {/*            return <img src={imagesSlider && imagesSlider[0] && imagesSlider[1].image} className="img-fluid" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/home/card-2.png" }} />*/}
                                        {/*    })()}*/}
                                        {/*    <div className="home-grid-card-data gradient-easing">*/}
                                        {/*        <div className="bg-title text-uppercase">*/}
                                        {/*            <FormattedMessage*/}
                                        {/*                id='Find_Your'*/}
                                        {/*                defaultMessage='Find Your'*/}
                                        {/*            />*/}
                                        {/*            <br /><FormattedMessage*/}
                                        {/*                id='Vacation_Home'*/}
                                        {/*                defaultMessage='Vacation Home'*/}
                                        {/*            />*/}
                                        {/*            <span className="icon-arrow-right icon" />*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</Link>*/}
                                    </div>
                                </div>
                            </div>
                            {/* Home Projects */}
                            <div className="projects-container">
                                <div className="container-fluid">
                                    <div className="projects-title bg-title text-center text-uppercase"><FormattedMessage
                                        id='Explore_Real_Estate_Projects_in_Egypt'
                                        defaultMessage='Explore 300+ Real Estate Projects in Egypt'
                                    /> </div>
                                    <div className="projects-grid">
                                        {projects && projects.map(project =>
                                            <Link key={project && project.id} to={project && "/Project/" + project.id} className="project-card">
                                                <img src={project && project.logo} className="img-fluid" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />
                                            </Link>
                                        )}
                                    </div>
                                    <div className="projects-grid-button text-center">
                                        <div className="d-inline-block">
                                            <Link to={"Projects/" + infoVacation[0].id} className="nl-btn flex-center-center"><FormattedMessage
                                                id='Explore'
                                                defaultMessage='Explore'
                                            /> <span className="icon-arrow-right icon" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ServicesNewsCards />
                        </>
                    }
                </div>
            </>
        )
    }
}
