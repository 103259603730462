import React, { Component } from 'react'

export class NotFoundPage extends Component {

    render() {
        document.title = "Not found";

        return (
            <div className="justify-content-center align-items-center" style={{ height: "auto" }}>
                <div className="d-flex justify-content-center align-items-center text-center" style={{ paddingTop: "16%", marginBottom:"28px" }}>
                    <div style={{ height: "auto" }}>
                        <img id="bgImageModalViewPhoto" src="assets/img/logo-black.svg" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />
                    </div>
                </div>
                <div className="col-12 d-flex justify-content-center align-items-center" style={{ height: "auto" }}>

                    <h1 className="mr-3 pr-3 align-top border-right inline-block align-content-center">404</h1>
                    <div className="inline-block align-middle">
                        <h2 className="font-weight-normal lead" id="desc">The page you requested was not found.</h2>
                    </div>
                </div>
            </div>

        )
    }
}
