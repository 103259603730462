import React, { Component } from 'react'
import '../css/main.css';
import '../css/projects.css';
import '../css/rtl.css';
import { ModalFilterProjects } from '../components/ModalFilterProjects'
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { Loading } from '../components/Loading';
import { Error } from '../components/Error';
import { GetLang, calcNavHeight } from '../_helpers/HelperFunctions';
import { FormattedMessage } from 'react-intl';
import Context from '../components/Context';



export class ProjectsPage extends Component {
    static contextType = Context;  

    constructor(props) {
        super(props);
        this.state = {
            type: this.props.match.params.id,
            isListProperties:false,
            projects: '',
            filteredProjects: '',
            loadingFetch: true,
            loadFilteredProjects: false,
            error: '',
            disabledSearch: false,
            searchValue: '',
            categories: ''
        };
    }

    async fetch(method, endpoint, body) {
        try {
            const response = await fetch(`api/${endpoint}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    LangCode: GetLang(),
                },
            });
            if (!response.ok) {
                const error = (response && response.message) || response.statusText;
                throw error;
            } else
                return await response.json();
        } catch (error) {
            console.error(error);
            this.setState({ error });
        }
    }


    async componentDidMount() {
        calcNavHeight();
        const { categories } = this.context;

        // let categories = await this.fetch('GET', "Generic/GetCategories");

        let categoryInfo;
        let isListProperties=false;

        categoryInfo = categories.filter(obj => {
            return obj.id == this.state.type
        })

        let apiCallResult;
        if (categoryInfo && categoryInfo[0] && categoryInfo[0].bool1 == true) {
            isListProperties = true;
            apiCallResult = await this.fetch('GET', "Items/GetByType/" + this.state.type);
        }
        else {
            apiCallResult = await this.fetch('GET', "Projects/FilterProjects/" + this.state.type);
        }

        this.setState({ loadingFetch: true, isListProperties: isListProperties, categories, projects: apiCallResult, filteredProjects: apiCallResult, loadingFetch: false });
        calcNavHeight();
    }

    handleSearchChange = e => {
        const { value } = e.target;
        const lowercasedValue = value.toLowerCase();

        if (this.state.projects.length > 0) {
            this.setState({ searchValue: value });
            this.setState(prevState => {
                const filteredProjects = prevState.projects.filter(el =>
                    el.name.toLowerCase().includes(lowercasedValue)
                );
                return { filteredProjects };
            });
        }
    };

    dropDown() {
        var dropdownElement = document.getElementById("dropdownShapes");
        var iconElement = document.getElementById("icon");
        if (dropdownElement.style.display != "block") {
            iconElement.classList.add("active");
            $("#dropdownShapes").slideDown(400);
        } else {
            iconElement.classList.remove("active");
            $("#dropdownShapes").slideUp(400);
        }
    }

    async onSelect(type) {
        let { categories, isListProperties } = this.state;

        //let categoryInfo;
        //categoryInfo = categories.filter(obj => {
        //    return obj.id == type
        //})
        //let apiCallResult;
        //await this.setState({ loadFilteredProjects: true, disabledSearch: true, searchValue: '' });
        //if (categoryInfo && categoryInfo[0] && categoryInfo[0].bool1 == true) {
        //    apiCallResult = await this.fetch('GET', "Items/GetByType/" + type);
        //}
        //else {
        //    apiCallResult = await this.fetch('GET', "Projects/GetByCategoryID/" + type);
        //}


        this.props.history.push({
            pathname: `/Projects/${type}`
        })

        var container = $(".dropdown-shapes-container");
        $(".dropdown-shapes-list").slideUp();
        container.find('.selected-item-icon').removeClass('active');

        //let apiCallResult = await this.fetch('GET', "Projects/GetByCategoryID/" + type);

        //await this.setState({ type: type, loadFilteredProjects: false, disabledSearch: false, searchValue: '' }); //, projects: apiCallResult, filteredProjects: apiCallResult
    }

    async componentWillReceiveProps(nextProps) {
        await this.setState({ loadFilteredProjects: true, disabledSearch: true, searchValue: ''});

        let { isListProperties, categories } = this.state;

        let categoryInfo;
        categoryInfo = categories.filter(obj => {
            return obj.id == nextProps.match.params.id
        })
        let apiCallResult;
        if (categoryInfo && categoryInfo[0] && categoryInfo[0].bool1 == true) {
            isListProperties = true;
            apiCallResult = await this.fetch('GET', "Items/GetByType/" + nextProps.match.params.id);
        }
        else {
            isListProperties = false;
            apiCallResult = await this.fetch('GET', "Projects/FilterProjects/" + nextProps.match.params.id);
        }
        await this.setState({ type: nextProps.match.params.id, projects: apiCallResult, filteredProjects: apiCallResult, isListProperties: isListProperties, loadFilteredProjects: false, disabledSearch: false });
        //console.log('Component received new props', nextProps.match.params.id);
    }

    render() {
        document.title = "Projects";

        let { error, projects, loadingFetch, filteredProjects, type, loadFilteredProjects, disabledSearch, searchValue, categories, isListProperties } = this.state;
        let lang = GetLang();
        if (error) {
            return <Error />
        }
      
        let arrOfBuy = categories && categories.filter(category => category.id == type);

        $("#ph-view").removeClass();
        $("#ph-view").addClass(arrOfBuy && arrOfBuy[0] && arrOfBuy[0].styleValue + " img-fluid icon");

        $("#ph-view-overlay").removeClass();
        $("#ph-view-overlay").addClass(arrOfBuy && arrOfBuy[0] && arrOfBuy[0].styleValue + " ph-view-overlay");

        // $('#ph-view').attr('src', `assets/img/icons/${type}.svg`);
        // $("#ph-view-overlay").attr('src', `assets/img/icons/${type}.svg`);

        let color = arrOfBuy && arrOfBuy[0] && arrOfBuy[0].colorCode;
        $(".vh-data").css('backgroundColor', `${color}`);

        $(document).click(function (e) {
            var container = $(".dropdown-shapes-container");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                $(".dropdown-shapes-list").slideUp();
                container.find('.selected-item-icon').removeClass('active');
            }
        });

        return (
            <>
                <ModalFilterProjects type={type} history={this.props.history} />
                <div className="wrapper-container">
                    {loadingFetch === true ? <Loading /> :
                        <>
                            {/* Vacation Filter */}
                            <div className="vacation-filter">
                                <div className="container-fluid">
                                    <div className="vacation-content">
                                        <div className="projects-row-bar d-flex">
                                            <div className="dropdown-shapes-container flex-center">
                                                <div className="selected-item flex-center-between" onClick={() => this.dropDown()}>
                                                    <div className="selected-item-header flex-center">
                                                        <span className={arrOfBuy && arrOfBuy[0] && arrOfBuy[0].styleValue + " img-fluid selected-item-logo"} style={{ fontSize: "28px" }} />
                                                        {/*<img src={`assets/img/icons/${type}.svg`} className="img-fluid selected-item-logo" alt="" />*/}
                                                        <div className="selected-item-title text-capitalize">
                                                            {arrOfBuy && arrOfBuy[0] && arrOfBuy[0].name}
                                                        </div>
                                                    </div>
                                                    <span className="icon-chevron-down selected-item-icon" id="icon" />
                                                </div>
                                                <div className="dropdown-shapes-list" id="dropdownShapes">
                                                    {categories && categories.length !== 0 && categories.map(cat =>
                                                        <Link to={cat.id} onClick={() => { this.onSelect(cat.id) }} className="dropdown-shape-row flex-center" data-text={cat.name}>
                                                            <span className={cat.styleValue + " img-fluid dropdown-item-logo"} style={{ fontSize: "28px" }} />
                                                            <div className="title text-capitalize">
                                                                {cat.name}
                                                            </div>
                                                        </Link>
                                                    )}

                                                    {/*<Link to="Commercial" onClick={() => { this.onSelect("Commercial") }} className="dropdown-shape-row flex-center" data-text="Commercial">*/}
                                                    {/*    <img src="assets/img/icons/commercial.svg" className="img-fluid dropdown-item-logo" alt="" />*/}
                                                    {/*    <div className="title text-capitalize"><FormattedMessage*/}
                                                    {/*        id='Commercial'*/}
                                                    {/*        defaultMessage='Commercial'*/}
                                                    {/*    /></div>*/}
                                                    {/*</Link>*/}
                                                    {/*<Link to="Rent" onClick={() => { this.onSelect("Rent") }} className="dropdown-shape-row flex-center" data-text="Rent">*/}
                                                    {/*    <img src="assets/img/icons/administrative.svg" className="img-fluid dropdown-item-logo" alt="" />*/}
                                                    {/*    <div className="title text-capitalize"><FormattedMessage*/}
                                                    {/*        id='Rent'*/}
                                                    {/*        defaultMessage='Rent'*/}
                                                    {/*    /></div>*/}
                                                    {/*</Link>*/}
                                                </div>
                                            </div>
                                            <div className="projects-search">
                                                <div className="ps-group flex-center height-fluid pl-3 pr-3">
                                                    <span className="icon-search icon" />
                                                    <FormattedMessage id="Search_for_Projects"
                                                        defaultMessage="Search by project name">
                                                        {placeholder =>
                                                            <input type="text" disabled={disabledSearch} placeholder={placeholder} name='searchValue' value={searchValue} onChange={this.handleSearchChange} />
                                                        }
                                                    </FormattedMessage>
                                                </div>
                                            </div>
                                            <div>
                                                <button className="projects-filter-btn flex-center" data-toggle="modal" data-target="#modalSearchFilter">
                                                    <span className="icon-filter icon" />
                                                    <div className="fil-txt"><FormattedMessage
                                                        id='Filters'
                                                        defaultMessage='Filters'
                                                    /></div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {(() => {
                                if (loadFilteredProjects == true)
                                    return <Loading />
                                else if (projects && projects.length == 0)
                                    return <h3 style={{ textAlign: 'center', marginBottom: "15%" }}><FormattedMessage
                                        id='There_is_any_project'
                                        defaultMessage='There is any project until now.'
                                    /></h3>

                                else
                                    return <>
                                        <div className="vacation-header animated fadeInDown">
                                            <div className="container-fluid">
                                                <div className="vh-row d-flex flex-wrap">
                                                    <div id="project-bg" className="vh-bg" style={{ backgroundImage: `url(${arrOfBuy && arrOfBuy[0] && arrOfBuy[0].image}),url("assets/img/projects.png")` }} />
                                                    <div className="vh-data" style={{ backgroundColor: color }}>
                                                        <div className="vh-icon flex-center">
                                                            <span id="ph-view" className={arrOfBuy && arrOfBuy[0] && arrOfBuy[0].styleValue + " img-fluid icon"} style={{ fontSize: "50px" }} />
                                                            {/*<img id="ph-view" src={`assets/img/icons/${type}.svg`} className="img-fluid icon" alt="" />*/}
                                                            <div id="ph-title" className="vh-title text-uppercase title white-color">
                                                                {(() => {                                                                    
                                                                    if (lang === "en") {
                                                                        return <>
                                                                            <FormattedMessage
                                                                                id='Find_Your'
                                                                                defaultMessage='Find your'
                                                                            /> <br /> <span className="ph-name">
                                                                                {arrOfBuy && arrOfBuy[0] && arrOfBuy[0].name}
                                                                               </span> <FormattedMessage
                                                                                id="Property"
                                                                                defaultMessage="Property "
                                                                            />
                                                                        </>

                                                                    } else if (lang === "ar") {
                                                                        return <>
                                                                            <FormattedMessage
                                                                                id='Find_Your'
                                                                                defaultMessage='Find your'
                                                                            /> <br /> <span className="ph-name"><FormattedMessage
                                                                                id="Property"
                                                                                defaultMessage="Property "
                                                                            /></span>
                                                                            {arrOfBuy && arrOfBuy[0] && arrOfBuy[0].name}
                                                                        </>
                                                                    }
                                                                })()}
                                                            </div>
                                                        </div>
                                                        <span id="ph-view-overlay" className={arrOfBuy && arrOfBuy[0] && arrOfBuy[0].styleValue + " ph-view-overlay"} style={{ fontSize: "90px" }} />                                                            

                                                        {/*    <img id="ph-view-overlay" className="ph-view-overlay" src={`assets/img/icons/${type}.svg`} alt="" />*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="projects-container">
                                            <div className="container-fluid">
                                                <div className="projects-cards-container">
                                                    <div id className="projects-grid animated fadeInDown" >
                                                        {filteredProjects && filteredProjects.map(project =>
                                                        {
                                                            if (isListProperties == true) {
                                                                return <Link to={{ pathname: project && "/Property/" + project.id }} className="project-card" title={project && project.name}>
                                                                    {project && project.projectLogo == null ? <img src="assets/img/notfound.png" className="img-fluid" alt="" />
                                                                        : <img src={project && project.projectLogo} className="img-fluid" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />}
                                                                </Link>
                                                            } else {
                                                                return <Link to={{ pathname: project && "/Project/" + project.id }} className="project-card" title={project && project.name}>
                                                                    {project && project.logo == null ? <img src="assets/img/notfound.png" className="img-fluid" alt="" />
                                                                        : <img src={project && project.logo} className="img-fluid" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />}
                                                                </Link>
                                                            }
                                                        }
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                            })()}
                        </>
                    }
                </div>
            </>
        )
    }
}
