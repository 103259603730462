import React, { Component } from 'react';
import { Header } from './Header';
import { NavMenu } from './NavMenu';
import { Footer } from './Footer';


export class Layout extends Component {
    static displayName = Layout.name;
    render() {
        return (
            <>
                <Header />
                <NavMenu />
                {this.props.children}
                <Footer />
            </>
        );
    }
}
