import React, { Component } from 'react'
import '../css/main.css';
import '../css/careers.css';
import '../css/rtl.css';
import { Loading } from '../components/Loading';
import { Error } from '../components/Error';
import { FormattedMessage } from 'react-intl';
import { CareersIDParam, GetLang, calcNavHeight } from '../_helpers/HelperFunctions';
import { Link } from 'react-router-dom';
import Context from '../components/Context';


export class AllJobsPage extends Component {
    static contextType = Context;  

    constructor(props) {
        super(props);
        this.state = {
            infoPage: '',
            careers: '',
            socialBtns: '',
            loadingFetch: true,
            error: ''
        };
    }

    async fetch(method, endpoint, body) {
        try {
            const response = await fetch(`api/${endpoint}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    LangCode: GetLang(),
                },
            });
            if (!response.ok) {
                const error = (response && response.message) || response.statusText;
                throw error;
            } else
                return await response.json();
        } catch (error) {
            console.error(error);
            this.setState({ error });
        }
    }

    async componentDidMount() {
        const { socialBtns } = this.context;

        calcNavHeight();
        this.setState({ loadingFetch: true, infoPage: await this.fetch('GET', "Service/Category/" + CareersIDParam), careers: await this.fetch('GET', "Service/Cat/" + CareersIDParam), socialBtns, loadingFetch: false }); //await this.fetch('GET', "Generic/GetSocialBtns")
        calcNavHeight();
    }
    render() {
        document.title = "All jobs";
        let { error, careers, loadingFetch, socialBtns, infoPage } = this.state;

        if (error)
            return <Error />
        var url;
        var renderSocialBtns = socialBtns && socialBtns.map(function (btn, idx) {
            switch (btn.name) {
                case "showfacebook":
                    url = socialBtns.filter(obj => {
                        return obj.name === "facebookurl"
                    })
                    if (url.length > 0 && btn.value.toLowerCase() != 'false' && url[0].value.trim() != '') {
                        return <a href={url[0].value} target="_blank" rel="noopener noreferrer" className="social-icon facebook">
                            <i className="fab fa-facebook-f" />
                            <span><FormattedMessage
                                id="showfacebook"
                                defaultMessage="Facebook"
                            /></span>
                        </a>

                    }
                    break;
                case "showlinkedin":
                    url = socialBtns.filter(obj => {
                        return obj.name === "linkedinurl"
                    })
                    if (url.length > 0 && btn.value.toLowerCase() != 'false' && url[0].value.trim() != '')
                        return <a href={url[0].value} target="_blank" rel="noopener noreferrer" className="social-icon linkedin">
                            <i className="fab fa-linkedin-in" />
                            <span><FormattedMessage
                                id="showlinkedin"
                                defaultMessage="Linkedin"
                            /></span>
                        </a>;
                    break;
                case "showinsta":
                    url = socialBtns.filter(obj => {
                        return obj.name === "instaUrl"
                    })
                    if (url.length > 0 && btn.value.toLowerCase() != 'false' && url[0].value.trim() != '')
                        return <a href={url[0].value} target="_blank" rel="noopener noreferrer" className="social-icon instagram">
                            <i className="fab fa-instagram" />
                            <span><FormattedMessage
                                id="showinsta"
                                defaultMessage="Instagram"
                            /></span>
                        </a>;
                    break;
                case "showyoutube":
                    url = socialBtns.filter(obj => {
                        return obj.name === "youtubeurl"
                    })
                    if (url.length > 0 && btn.value.toLowerCase() != 'false' && url[0].value.trim() != '')
                        return <a href={url[0].value} target="_blank" rel="noopener noreferrer" className="social-icon youtube">
                            <i className="fab fa-youtube" />
                            <span><FormattedMessage
                                id="showyoutube"
                                defaultMessage="YouTube"
                            /></span>
                        </a>;
                    break;
                default:
                    return null;

            }
        })

        return (
            <div className="wrapper-container">
                {/* Slider main container */}
                {loadingFetch === true ? <Loading /> :
                    <div className="careers">
                        <div className="container-fluid">
                            <div className="careers-content">
                                <div className="career-cards text-center">
                                    <div className="bg-title black-color mb-5">{infoPage && infoPage.string3}</div>
                                    <div className="careers-grid">
                                        {careers && careers.services.map((service, index) =>
                                            <Link to={"Job/" + service.id} className="career-card" key={index}>
                                                {(() => {
                                                    if (service && service.image == null) {
                                                        return <img src="assets/img/application.jpg" className="career-card-img img-fluid" alt="" />
                                                    }
                                                    else
                                                        return <img src={service && service.image} className="career-card-img img-fluid" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/application.jpg" }} />
                                                })()}
                                                <div className="career-card-data">
                                                    <div>
                                                        <div className="career-card-data-position">{service && service.name}</div>
                                                        <div className="career-card-data-desc">{service && service.description}</div>
                                                    </div>
                                                </div>
                                            </Link>
                                        )}
                                    </div>
                                    <div className="careers-socialmedia pt-5 mt-4 pb-0 text-center">
                                        {renderSocialBtns}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
