import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';

export class Error extends Component {
    render() {
        return (
            <div class="wrapper-container">
                <div className="loadDiv" style={{ "marginLeft": "34%", "marginRight": "28%" }}>  {/*style={{ marginLeft: 'auto', marginRight: 'auto' }}*/}
                    <h1><img src={`assets/img/icons/error.svg`} className="img-fluid selected-item-logo" alt="" /><FormattedMessage
                        id='error'
                        defaultMessage='There is an error, Try later.'
                    /></h1>
                </div>
            </div>
        )
    }
}
