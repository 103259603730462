import React, { Component } from 'react'
import '../css/main.css';
import '../css/careers.css';
import '../css/rtl.css';
import { Loading } from '../components/Loading';
import { Error } from '../components/Error';
import { FormattedMessage } from 'react-intl';
import { CareersIDParam, GetLang } from '../_helpers/HelperFunctions';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import Context from '../components/Context';



export class CareersPage extends Component {
    static contextType = Context;  

    constructor(props) {
        super(props);
        this.state = {
            infoPage: '',
            socialBtns: '',
            careers: '',
            loadingFetch: true,
            error: ''
        };
    }

    async fetch(method, endpoint, body) {
        try {
            const response = await fetch(`api/${endpoint}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    LangCode: GetLang(),
                },
            });
            if (!response.ok) {
                const error = (response && response.message) || response.statusText;
                throw error;
            } else
                return await response.json();
        } catch (error) {
            console.error(error);
            this.setState({ error });
        }
    }
    // Callback IF Condition Header Bottom
    headerBottomScrollerChecker() {
        var mainHeaderBottom =
            $("#headerScroller").position().top +
            $("#headerScroller").outerHeight(true);
        if ($(window).scrollTop() >= mainHeaderBottom) {
            $("#nav").addClass("active");
            $(".transition-title").removeClass("active");
        } else {
            $("#nav").removeClass("active");
        }
    }

    async componentDidMount() {
        const { socialBtns } = this.context;

        document.body.style.paddingTop = '80.625px';
        var element = document.getElementById("nav");
        element.classList.add("active-page");

        let that = this;

        $(window).scroll(function () {
            if ($(window).scrollTop() > 0) {
                $("#navigationBar").addClass("active");
            } else {
                $("#navigationBar").removeClass("active");
            }
        });

        // Header Scroller
        $(window).on("scroll", function () {
            if ($(window).scrollTop() > 0 && $("#headerScroller")[0]) {
                $(".transition-title").addClass("active");
                that.headerBottomScrollerChecker();
            } else {
                $(".transition-title").removeClass("active");
                $("#nav").removeClass("active");
            }
        });

        this.setState({ loadingFetch: true, infoPage: await this.fetch('GET', "Service/Category/" + CareersIDParam), careers: await this.fetch('GET', "Service/Cat/" + CareersIDParam), socialBtns, loadingFetch: false }); // await this.fetch('GET', "Generic/GetSocialBtns")
        document.body.style.removeProperty("padding-top")
        var element = document.getElementById("nav");
        element.classList.remove("active-page");        
    }

    handleAnimateDown(id) {
        var target = $("#" + id).attr("data-target");
        var targetHeight = $("#" + id).attr("data-target-height");
        var spaceOfContainer = 0;
        if (targetHeight) {
            spaceOfContainer = targetHeight;
        } else {
            spaceOfContainer = 0;
        }
        $("html, body").animate(
            {
                // scrollTop: $("#" + target).top,
                scrollTop: $(target).offset().top - spaceOfContainer,
            },
            "slow"
        );
    }


    render() {
        document.title = "Careers";

        let { error, infoPage, careers, loadingFetch, socialBtns } = this.state;       
        if (error)
            return <Error />

        let img = infoPage && infoPage.images && infoPage.images[0] && infoPage.images[0].azureUri;
        var url;
        var renderSocialBtns = socialBtns && socialBtns.map(function (btn, idx) {
            switch (btn.name) {
                case "showfacebook":
                    url = socialBtns.filter(obj => {
                        return obj.name === "facebookurl"
                    })
                    if (url.length > 0 && btn.value.toLowerCase() != 'false' && url[0].value.trim() != '') {
                        return <a href={url[0].value} target="_blank" rel="noopener noreferrer" className="social-icon facebook">
                            <i className="fab fa-facebook-f" />
                            <span><FormattedMessage
                                id="showfacebook"
                                defaultMessage="Facebook"
                            /></span>
                        </a>

                    }
                    break;
                case "showlinkedin":
                    url = socialBtns.filter(obj => {
                        return obj.name === "linkedinurl"
                    })
                    if (url.length > 0 && btn.value.toLowerCase() != 'false' && url[0].value.trim() != '')
                        return <a href={url[0].value} target="_blank" rel="noopener noreferrer" className="social-icon linkedin">
                            <i className="fab fa-linkedin-in" />
                            <span><FormattedMessage
                                id="showlinkedin"
                                defaultMessage="Linkedin"
                            /></span>
                        </a>;
                    break;
                case "showinsta":
                    url = socialBtns.filter(obj => {
                        return obj.name === "instaUrl"
                    })
                    if (url.length > 0 && btn.value.toLowerCase() != 'false' && url[0].value.trim() != '')
                        return <a href={url[0].value} target="_blank" rel="noopener noreferrer" className="social-icon instagram">
                            <i className="fab fa-instagram" />
                            <span><FormattedMessage
                                id="showinsta"
                                defaultMessage="Instagram"
                            /></span>
                        </a>;
                    break;
                case "showyoutube":
                    url = socialBtns.filter(obj => {
                        return obj.name === "youtubeurl"
                    })
                    if (url.length > 0 && btn.value.toLowerCase() != 'false' && url[0].value.trim() != '')
                        return <a href={url[0].value} target="_blank" rel="noopener noreferrer" className="social-icon youtube">
                            <i className="fab fa-youtube" />
                            <span><FormattedMessage
                                id="showyoutube"
                                defaultMessage="YouTube"
                            /></span>
                        </a>;
                    break;
                default:
                    return null;

            }
        })
        return (
            <div className="wrapper-container">
                {/* Slider main container */}
                {loadingFetch === true ? <Loading /> :
                    <>
                        <div id="headerScroller" className="home-header">
                            <div className="careers-header" style={{ backgroundImage: img === null ? 'assets/img/application.png' : `url(${img}),url("assets/img/services/service-1.png")` }}>
                                <div className="overlay" />
                                <div className="careers-data text-center">
                                    <div className="bg-title">
                                        {infoPage && infoPage.description}
                                    </div>
                                    <div className="careers-action mb-3">
                                        <Link to="/Connect" className="career-btn">
                                            <FormattedMessage
                                                id='Connect'
                                                defaultMessage='Connect'
                                            /></Link>
                                        <button className="career-btn arrow-target" id="JobOpenings" onClick={() => this.handleAnimateDown("JobOpenings")} data-target="#job-openings" data-target-height={50}>
                                            <FormattedMessage
                                                id='JobOpenings'
                                                defaultMessage='Job openings'
                                            /></button>
                                    </div>
                                    <div className="d-inline-block">
                                        <button data-target="#introduction" id="introduction" data-target-height={199} onClick={() => this.handleAnimateDown("introduction")} className="career-down arrow-target flex-center-center"><span className="icon-chevron-down icon" /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="careers">
                            <div className="container-fluid">
                                <div className="careers-content">
                                    <div className="careers-st-container">
                                        <div className="careers-socialmedia text-center">
                                            {renderSocialBtns}
                                        </div>
                                        <div id="introduction" className="careers-text text-center">
                                            <div className="bg-title">{infoPage && infoPage.string1} </div>
                                            <div className="text text-left">
                                                {infoPage && infoPage.string2}
                                            </div>
                                        </div>
                                    </div>
                                    <div id="job-openings" className="career-cards">
                                        <div className="careers-grid">
                                            {careers && careers.services && careers.services.slice(0, 5).map((service, index) =>
                                                <Link to={"Job/" + service.id} className="career-card" key={index}>
                                                    {(() => {
                                                        if (service && service.image == null) {
                                                            return <img src="assets/img/application.jpg" className="career-card-img img-fluid" alt="" />
                                                        }
                                                        else
                                                            return <img src={service && service.image} className="career-card-img img-fluid" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/application.jpg" }} />
                                                    })()}
                                                    <div className="career-card-data">
                                                        <div>
                                                            <div className="career-card-data-position">{service && service.name}</div>
                                                            <div className="career-card-data-desc">{service && service.description}</div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            )}
                                        </div>
                                        <div className="career-grid-action pt-5 pb-4 text-center">
                                            <Link to="AllJobs" className="career-btn"><FormattedMessage
                                                id='AllJobs'
                                                defaultMessage='All Jobs'
                                            /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        )
    }
}
