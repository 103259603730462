import React, { Component } from 'react'
import '../css/main.css';
import '../css/articles.css';
import '../css/rtl.css';
import { Link } from 'react-router-dom';
import { Loading } from '../components/Loading';
import { Error } from '../components/Error';
import { GetLang, calcNavHeight } from '../_helpers/HelperFunctions';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import Context from '../components/Context';


export class ArticlePage extends Component {
    static contextType = Context;  

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            articleInfo: '',
            socialBtns: '',
            loadingFetch: true,
            error: '',
        };
    }

    async fetch(method, endpoint, body) {
        try {
            const response = await fetch(`api/${endpoint}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    LangCode: GetLang(),
                },
            });
            if (!response.ok) {
                const error = (response && response.message) || response.statusText;
                throw error;
            } else
                return await response.json();
        } catch (error) {
            console.error(error);
            this.setState({ error });
        }
    }

    openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    async componentDidMount() {
        const { socialBtns } = this.context;

        calcNavHeight();
        this.setState({ loadingFetch: true, articleInfo: await this.fetch('GET', "Wiki/GetNew/" + this.state.id), socialBtns, loadingFetch: false }); //: await this.fetch('GET', "Generic/GetSocialBtns")
        calcNavHeight();
    }


    render() {
        document.title = "Article";

        let { error, articleInfo, loadingFetch, socialBtns } = this.state;
        if (error)
            return <Error />

        var url;
        var renderSocialBtns = socialBtns && socialBtns.map(function (btn, idx) {
            switch (btn.name) {
                case "showfacebook":
                    url = socialBtns.filter(obj => {
                        return obj.name === "facebookurl"
                    })
                    if (url.length > 0 && btn.value.toLowerCase() != 'false' && url[0].value.trim() != '') {
                        return <a href={url[0].value} title={GetLang() === 'ar' ? "فيسبوك" : "facebook"} target="_blank" rel="noopener noreferrer" className="news-social-icon">
                            <i className="fab fa-facebook-f icon" />
                        </a>

                    }
                    break;
                case "showlinkedin":
                    url = socialBtns.filter(obj => {
                        return obj.name === "linkedinurl"
                    })

                    if (url.length > 0 && btn.value.toLowerCase() != 'false' && url[0].value.trim() != '')
                        return <a href={url[0].value} target="_blank" rel="noopener noreferrer" title={GetLang() === 'ar' ? "لينكيدان" : "linkedin"} className="news-social-icon">
                            <i className="fab fa-linkedin-in icon" />
                        </a>;
                    break;
                case "showinsta":
                    url = socialBtns.filter(obj => {
                        return obj.name === "instaUrl"
                    })

                    if (url.length > 0 && btn.value.toLowerCase() != 'false' && url[0].value.trim() != '')
                        return <a href={url[0].value} target="_blank" rel="noopener noreferrer" title={GetLang() === 'ar' ? "انستجرام" : "instagram"} className="news-social-icon">
                            <i className="fab fa-instagram icon" />
                        </a>;
                    break;
                case "showyoutube":
                    url = socialBtns.filter(obj => {
                        return obj.name === "youtubeurl"
                    })
                    if (url.length > 0 && btn.value.toLowerCase() != 'false' && url[0].value.trim() != '')
                        return <a href={url[0].value} target="_blank" rel="noopener noreferrer" title={GetLang() === 'ar' ? "يوتيوب" : "youtube"} className="news-social-icon">
                            <i className="fab fa-youtube icon" />
                        </a>;
                    break;
                default:
                    return null;

            }
        })

        return (
            <div className="wrapper-container">
                {loadingFetch === true ? <Loading /> :
                    <div className="news animated fadeInLeft">
                        <div className="container-fluid">
                            <div className="news-wrapper">
                                <div className="view-article-header">
                                    {(() => {
                                        if (articleInfo && articleInfo.image == null || articleInfo && articleInfo.image == '') {
                                            return <img src="assets/img/services/service-3.png" className="img-fluid" alt="" />
                                        } else
                                            return < img src={articleInfo && articleInfo.image} className="img-fluid" alt="article image" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/services/service-3.png" }} />
                                    })()}
                                </div>
                                <div className="news-main-title">
                                    {articleInfo && articleInfo.name}
                                </div>
                                <div className="article-line" />
                                <div className="news-socialmedia">
                                    {renderSocialBtns} 
                                    <button target="_blank" rel="noopener noreferrer" title={GetLang() === 'ar' ? "أطبع" : "Download"} className="news-social-icon" onClick={() => window.print()}>
                                        <span className="icon-printer icon" />
                                    </button>
                                    <button target="_blank" rel="noopener noreferrer" title={GetLang() === 'ar' ? "تحميل" : "Download"} className="news-social-icon" onClick={(e) => { this.openInNewTab(articleInfo && articleInfo.files && articleInfo.files[0] && articleInfo.files[0].azureUri) }}>
                                        <span className="ico" />
                                    </button>
                                </div>
                                <div className="view-article-date-name">
                                    <div className="article-date">{articleInfo && articleInfo.dateCreatedModel && moment(articleInfo.dateCreatedModel).format('LLL')}</div>
                                </div>
                                <div className="news-main-text">
                                    {articleInfo && articleInfo.description}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
