import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import Arabic  from '../_helpers/languages/arabic.json'
import English from '../_helpers/languages/English.json'
import { GetLang } from '../_helpers/HelperFunctions'


export const Context = React.createContext();
const local = GetLang();
let lang;
if (local === 'ar') {
    lang = Arabic;
} else {
    lang = English;
}
const Wrapper = (props) => {
    const [locale, setLocale] = useState(local);
    const [messages, setMessages] = useState(lang);
    function selectLanguage(e) {
        const newLocale = e.target.value;
        setLocale(newLocale);
        if (newLocale === 'en') {
            setMessages(English);
        } else {
            setMessages(Arabic);
        }
    }
    return (
        <Context.Provider value={{ locale, selectLanguage }}>
            <IntlProvider messages={messages} locale={locale}>
                {props.children}
            </IntlProvider>
        </Context.Provider>
    );
}
export default Wrapper;