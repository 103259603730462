import React, { Component } from 'react'
import '../css/project-1.css';
import '../css/project-2.css';
import '../css/main.css';
import '../css/rtl.css';
import { Link, Redirect } from 'react-router-dom';
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import Swiper, { SwiperOptions } from "swiper"
//import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import Context from '../components/Context';
import Helmet from 'react-helmet'

// CSS
//swiper css must come first
import "swiper/swiper.min.css";
import SlimSelect from 'slim-select'
import { Loading } from '../components/Loading';
import { Error } from '../components/Error';
import { ReadMore } from '../components/ReadMore';
import $ from 'jquery';
import { FormattedMessage } from 'react-intl';
import { IntegersRegex, IntegersGreaterThanZeroRegex, numberWithCommas, validEmailRegex, validmobileRegex, validNumbersRegex, validSpacesRegex, validSpecialCharRegex, Toast, GetLang, calcNavHeight } from '../_helpers/HelperFunctions'

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export class PropertyPage extends Component {
    static contextType = Context;  

    constructor(props) {
        super(props);
        this.state = {
            copySatus:'CopyLink',
            types: '',
            name: '',
            email: '',
            mobile: '',
            massage: '',
            typeID: '',
            whatsAppNumber:'',
            isSubmitting: false,
            errors: {
                name: '',
                email: '',
                mobile: '',
                massage: '',
                typeID: ''
            },
            propertyID: this.props.match.params.id,
            propertyInfo: '',
            projectInfo: '',
            companyInfo: '',
            loadingFetch: true,
            error: '',
            imagesSwiper: '',
            imagesShow:''
        };
        this.renderImgs = this.renderImgs.bind(this);
    }

    async fetch(method, endpoint, body) {
        try {
            const response = await fetch(`api/${endpoint}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    LangCode: GetLang(),
                },
            });
            if (!response.ok) {
                const error = (response && response.message) || response.statusText;
                if (method === "POST") {
                    Toast.fire({
                        icon: 'error',
                        title: "There is error, try later."
                    })
                } else
                    throw error;
            } else {
                if (method == "POST") {
                    Toast.fire({
                        icon: 'success',
                        title: "Your massage was send successfully."
                    })
                    this.setState({ name: '', email: '', massage: '', mobile: '', typeID: '' });
                }
                return await response.json();
            }
        } catch (error) {
            console.error(error);
            this.setState({ error });
        }
    }

    async componentDidMount() {
        let { errors } = this.state;

        let IsInt = IntegersRegex.test(this.state.propertyID) ? true : false;
        let IsGreateThanZero = IntegersGreaterThanZeroRegex.test(this.state.propertyID) ? true : false;
        calcNavHeight();
        //document.body.style.paddingTop = '80.625px';
        //var element = document.getElementById("nav");
        //element.classList.add("active-page");
        if (IsInt !== true && IsGreateThanZero !== true) {
            window.location.assign('/404')
        } else {
            let projectInfo = '';
            let companyInfo = '';
            let propertyInfo = await this.fetch('GET', "Items/" + this.state.propertyID);
            if (typeof (propertyInfo) !== 'undefined' && propertyInfo != null) {

                projectInfo = await this.fetch('GET', "Projects/" + propertyInfo.projectId);
                companyInfo = await this.fetch('GET', "Companies/" + propertyInfo.companyId);
                let types = await this.fetch('GET', "Generic/GetTypes");
                const { socialBtns } = this.context;
                // let whatsAppNumber = await this.fetch('GET', "Generic/GetSocialBtns");
                let that = this;

                await this.setState({ loadingFetch: true, propertyInfo, projectInfo, companyInfo, types, whatsAppNumber: socialBtns, loadingFetch: false });

                var photosTabSwiper = [];
                var photosShow = [];


                for (var i = 0; i < propertyInfo.genitemImages.length; i++) {
                    var photo = Object.create(propertyInfo.genitemImages[i]);
                    var photo2 = Object.create(propertyInfo.genitemImages[i]);

                    photo.image = photo.image.replace("IPT_", "IPTS_");
                    photo2.image = photo2.image.replace("IPT_", "IIC_");

                    photosTabSwiper.push(photo);
                    photosShow.push(photo2);

                }

                await this.setState({
                    imagesSwiper: photosTabSwiper, imagesShow: photosShow
                });


                //var uri = window.location.href;
                //var encodeurl = encodeURI(uri);
                
                //$('meta[property=og\\:url]').attr('content', encodeurl);
                //$('meta[property=twitter\\:url]').attr('content', encodeurl);

                //if (projectInfo && projectInfo.logo != null) {
                //    $('meta[property=og\\:image]').attr('content', projectInfo && projectInfo.logo);
                //    $('meta[property=twitter\\:image]').attr('content', projectInfo && projectInfo.logo);
                //}
                //if (propertyInfo && propertyInfo.name != null) {
                //    $('meta[property=og\\:title]').attr('content', propertyInfo && propertyInfo.name);
                //    $('meta[property=twitter\\:title]').attr('content', propertyInfo && propertyInfo.name);
                //}
                //if (propertyInfo && propertyInfo.string5 != null) {
                //    $('meta[property=og\\:description]').attr('content', propertyInfo && propertyInfo.string5);
                //    $('meta[property=twitter\\:description]').attr('content', propertyInfo && propertyInfo.string5);
                //}


                if (this.state.error === '' && this.state.loadingFetch === false) {
                    $(document).ready(function () {
                        new SlimSelect({
                            select: '#typeID',
                            showSearch: true,
                            onChange: (info) => {
                                errors.typeID = '';
                                that.setState({ typeID: info.value, errors });
                            }
                        });
                        // Swiper                           

                    })
                }
            } else {
                console.log('Undefined or Null')
            }
            calcNavHeight();
        }
    }


    handleClickFormUp() {
        $("#toggleContactContainer").slideDown(500);
    }

    handleClickFormDown() {
        $("#toggleContactContainer").slideUp(500);
    }

    handleClickImage(id) {
        let element = document.getElementById(id);
        let src = element.attributes.src;
        console.log(src && src.nodeValue)
        let modifiedimg = src && src.nodeValue && src.nodeValue.replace("IPTS_", "IIS_");
        //var src = $(this).find('img').attr('src');
        $('#shcView').fadeOut(200, function () {
            $(this).attr('src', modifiedimg); // src.nodeValue);
        }).fadeIn(200);
        $(this).addClass('active').siblings().removeClass('active');
    }

    handleChange = (event) => {
        event.preventDefault();
        let { name, value } = event.target;
        let { errors } = this.state;
        let emptyField = '';

        switch (name) {
            case 'name':
                errors.name = value.trim() == emptyField ? 'This_field_is_required' : '';
                if (errors.name == '')
                    errors.name = validSpacesRegex.test(value) ? '' : 'Invalid_name';
                if (errors.name == '')
                    errors.name = validNumbersRegex.test(value) ? '' : 'Invalid_name';
                if (errors.name == '')
                    errors.name = validSpecialCharRegex.test(value) ? '' : 'Invalid_name';
                break;
            case 'email':
                errors.email = value.trim() == emptyField ? 'This_field_is_required' : '';
                if (errors.email == '')
                    errors.email = validEmailRegex.test(value) ? '' : 'Invalid_email';
                break;
            case 'mobile':
                errors.mobile = value.trim() == emptyField ? 'This_field_is_required' : '';
                if (errors.mobile == '')
                    errors.mobile = validmobileRegex.test(value) ? '' : 'Invalid_phone';
                break;
            case 'massage':
                errors.massage = value.trim() == emptyField ? 'This_field_is_required' : '';
                if (errors.massage == '')
                    errors.massage = validSpecialCharRegex.test(value) ? '' : 'Invalid_name';
                if (errors.massage == '')
                    errors.massage = validSpacesRegex.test(value) ? '' : 'This_field_is_required';
                break;
            default:
                break;
        }

        this.setState({ errors, [name]: value }, () => {
            console.log(errors)
        })
    }

    validateForm = (errors, state) => {
        let valid = true;
        if (state.name == '')
            errors.name = "This_field_is_required";
        if (state.email == '')
            errors.email = "This_field_is_required";
        if (state.mobile == '')
            errors.mobile = "This_field_is_required";
        if (state.massage == '')
            errors.massage = "This_field_is_required";
        if (state.typeID == '')
            errors.typeID = "This_field_is_required";


        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );

        this.setState({ errors }, () => {
            console.log(errors)
        })
        return valid;
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        let { errors, name, email, mobile, massage, typeID, propertyInfo, projectInfo } = this.state;
        let extendString1 = "Project Id: " + projectInfo.id + ", Project Name: " + projectInfo.name + ", Unit Id: " + propertyInfo.id
            + ", Unit Name: " + propertyInfo.name + ", Category: Rent";

        let body = { "name": name, "email1": email, "mobile1": mobile, "description": massage, "typeId": typeID, "extendString1": extendString1 };

        if (this.validateForm(errors, this.state)) {
            console.info('Valid Form');
            this.setState({ isSubmitting: true });
            await this.fetch('POST', "Generic/AddContact", body)
            this.setState({ isSubmitting: false });

        } else {
            console.error('Invalid Form')
        }
    }

    async onSelect(id) {
        this.props.history.push({
            pathname: `/Property/${id}`
        })
        await this.setState({ loadingFetch: true });

        let propertyInfo = await this.fetch('GET', "Items/" + id);
        let projectInfo = await this.fetch('GET', "Projects/" + propertyInfo.projectId);
        let companyInfo = await this.fetch('GET', "Companies/" + propertyInfo.companyId);


        var uri = window.location.href;
        var encodeurl = encodeURI(uri);

        //$('meta[property=og\\:url]').attr('content', encodeurl);
        //$('meta[property=twitter\\:url]').attr('content', encodeurl);

        //if (projectInfo && projectInfo.logo != null) {
        //    $('meta[property=og\\:image]').attr('content', projectInfo && projectInfo.logo);
        //    $('meta[property=twitter\\:image]').attr('content', projectInfo && projectInfo.logo);
        //}
        //if (propertyInfo && propertyInfo.name != null) {
        //    $('meta[property=og\\:title]').attr('content', propertyInfo && propertyInfo.name);
        //    $('meta[property=twitter\\:title]').attr('content', propertyInfo && propertyInfo.name);
        //}
        //if (propertyInfo && propertyInfo.string5 != null) {
        //    $('meta[property=og\\:description]').attr('content', propertyInfo && propertyInfo.string5);
        //    $('meta[property=twitter\\:description]').attr('content', propertyInfo && propertyInfo.string5);
        //}

        await this.setState({ propertyID: id, propertyInfo, projectInfo, companyInfo, loadingFetch: false });

        var photosTabSwiper = [];
        var photosShow = [];


        for (var i = 0; i < propertyInfo.genitemImages.length; i++) {
            var photo = Object.create(propertyInfo.genitemImages[i]);
            var photo2 = Object.create(propertyInfo.genitemImages[i]);

            photo.image = photo.image.replace("IPT_", "IPTS_");
            photo2.image = photo2.image.replace("IPT_", "IIC_");

            photosTabSwiper.push(photo);
            photosShow.push(photo2);

        }

        await this.setState({
            imagesSwiper: photosTabSwiper, imagesShow: photosShow
        });       
    }

    renderImgs() {
        let { imagesShow } = this.state;

        var PhotoImagesCarosul = imagesShow; //propertyInfo && propertyInfo.genitemImages;

        for (var i = 0; i < PhotoImagesCarosul.length; i++) {
            if (PhotoImagesCarosul[i].image)
                PhotoImagesCarosul[i].image = PhotoImagesCarosul[i].image.replace("IPT_", "IIC_");
        }

        let photosCount = PhotoImagesCarosul && PhotoImagesCarosul.length;
        if (photosCount > 4) {
            let imgsShow = PhotoImagesCarosul && PhotoImagesCarosul.slice(0, 4);
            let plusNumber = photosCount - 3;
            return imgsShow && imgsShow.map((itemImage, i) => {
                if (i === 3) {
                    return (
                        <div class="d2-image-view-mi d2-image-view">
                            <img id={itemImage.id} src={itemImage && itemImage.image} className="img-fluid" alt="Image property" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />
                            <button data-tab="photosTab" class="md-tab-btn more-imgs flex-center-center" onClick={() => this.handleClickTab("photosTab")} >+{plusNumber}</button>
                        </div>
                    )
                } else
                    return (
                        <div class="d2-image-view">
                            <img id={itemImage.id} onClick={() => { this.handleClickImage(itemImage.id) }} src={itemImage && itemImage.image} className="img-fluid" alt="Image property" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />
                        </div>
                    )
            })
        } else if (photosCount == 4) {
            return PhotoImagesCarosul && PhotoImagesCarosul.map(itemImage => {
                return (
                    <div class="d2-image-view">
                        <img id={itemImage.id} onClick={() => { this.handleClickImage(itemImage.id) }} src={itemImage && itemImage.image} className="img-fluid" alt="Image property" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />
                    </div>
                )
            })
        } else if (photosCount < 4 && photosCount !== 0) {
            let diff = 4 - photosCount;
            let divs = [];
            for (var i = 1; i < diff; i++) {
                divs.push(<div class="d2-image-view">
                </div>);
            }
            return (
                <>
                    {PhotoImagesCarosul && PhotoImagesCarosul.map(itemImage => {
                        return (
                            <div class="d2-image-view">
                                <img id={itemImage.id} onClick={() => { this.handleClickImage(itemImage.id) }} src={itemImage && itemImage.image} className="img-fluid" alt="Image property" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />
                            </div>
                        )
                    }
                    )}
                    {divs}
                </>
            )
        }
        else if (photosCount == 0) {
            return (
                <>
                    <div class="d2-image-view">
                    </div>
                    <div class="d2-image-view">
                    </div>
                    <div class="d2-image-view">
                    </div>
                    <div class="d2-image-view">
                    </div>
                </>
            )
        }
    }

    handleSwiperThumbPhotos() {
        let img = $(".swiper-thumb-photos .swiper-slide.swiper-slide-active").find("img").attr("src");
        let modifiedimg = img && img.replace("IPTS_", "IPT_");
        $("#bgImageModalViewPhoto").attr(
            "src",
            modifiedimg           
        );
    }

    handleClickCloseModalTab() {
        //let { projectInfo } = this.state;
        $(".modal-tab").removeClass("active");
        $(".wrapper-container").removeClass("toggle-wrapper-active");
        //$("#videoPlayer").get(0).pause();
        //if (projectInfo.string2 !== null) {
        //    var frame = document.getElementById("videoPlayer");
        //    frame.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
        //}
        // $(".vp-overlay").addClass("active");
        // $("#toggleVpBtn").find("img").attr("src", "assets/img/icons/play.svg");
    }

    handleClickTab(tabName, e) {
        var tab = tabName;
        $("#" + tab + "Btn").addClass("active").siblings().removeClass("active");
        $(".modal-tab").addClass("active");
        $(".wrapper-container").addClass("toggle-wrapper-active");
        $("#" + tab)
            .addClass("active")
            .siblings()
            .removeClass("active");
        $(".mtaf-photos").addClass("active");
        window.scrollTo(0, 0);
    }

    handleClickPrevPhoto() {
        let img = $(".swiper-thumb-photos .swiper-slide.swiper-slide-active")
            .next()
            .find("img")
            .attr("src");
        let modifiedimg = img && img.replace("IPTS_", "IPT_");
        $("#bgImageModalViewPhoto").attr(
            "src", modifiedimg            
        );
    }

    handleClickNextPhoto() {
        let img = $(".swiper-thumb-photos .swiper-slide.swiper-slide-active")
            .next()
            .find("img")
            .attr("src");
        let modifiedimg = img && img.replace("IPTS_", "IPT_");
        $("#bgImageModalViewPhoto").attr(
            "src", modifiedimg            
        );
    }

    componentWillUpdate(nextProps, nextState) {
        //console.log('Component WILL UPDATE!');
    }

    fallbackCopyTextToClipboard(text) {
    let that = this;
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
        that.setState({ copySatus: "CopyLinkDone" })
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }
    document.body.removeChild(textArea);
    }

    copyTextToClipboard(text) {
    let that = this;
    if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(function () {
        console.log('Async: Copying to clipboard was successful!');
        that.setState({ copySatus: "CopyLinkDone" })
    }, function (err) {
        console.error('Async: Could not copy text: ', err);
    });
}

    handleCopy = (encodeurl) => {
        let that = this;
        if (navigator.clipboard != undefined) {//Chrome
            navigator.clipboard.writeText(encodeurl).then(function () {
                console.log('Copying to clipboard was successful!');
                that.setState({ copySatus: "CopyLinkDone" })
            }, function (err) {
                console.error('Could not copy text: ', err);
            });
        }
        else if (window.clipboardData) { // Internet Explorer
            window.clipboardData.setData("Text", encodeurl);
        }
    }
      
    componentDidUpdate(prevProps, prevState) {
        new Swiper(".otherProperties", {
            // Optional parameters
            direction: "horizontal",
            loop: false,
            slidesPerView: 4,
            spaceBetween: 30,
            autoplay: {
                delay: 4000,
            },
            speed: 1000,
            // Navigation arrows
            navigation: {
                nextEl: ".arrow-nextProperties",
                prevEl: ".arrow-prevProperties",
            },
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                // when window width is >= 480px
                480: {
                    slidesPerView: 1.8,
                    spaceBetween: 30,
                },
                // when window width is >= 640px
                640: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
            },
        });
        new Swiper(".otherProjects", {
            // Optional parameters
            direction: "horizontal",
            loop: false,
            slidesPerView: 4,
            spaceBetween: 30,
            autoplay: {
                delay: 4000,
            },
            speed: 1000,
            // Navigation arrows
            navigation: {
                nextEl: ".arrow-nextProjects",
                prevEl: ".arrow-prevProjects",
            },
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                // when window width is >= 480px
                480: {
                    slidesPerView: 1.8,
                    spaceBetween: 30,
                },
                // when window width is >= 640px
                640: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
            },
        });
        new Swiper(".swiper-thumb-photos", {
            spaceBetween: 15,
            // touchRatio: 0.2,
            slideToClickedSlide: true,
            loop: true,
            direction: "vertical",
            observer: true,
            observeParents: true,
            autoHeight: false,
            slidesPerView: "auto",
            loopedSlides: 3,
            freeMode: false,
            watchSlidesVisibility: true,
            slidesPerView: 3,
            navigation: {
                nextEl: "#mssNextPhotoBtn",
                prevEl: "#mssPrevPhotoBtn",
            },
            slideToClickedSlide: true,
            pagination: {
                el: ".photos-fraction-counter",
                type: "fraction",

                renderFraction: function (currentClass, totalClass) {
                    return (
                        '<span class="' +
                        currentClass +
                        '"></span>' +
                        " " +
                        '<span class="' +
                        totalClass +
                        '"></span>'
                    );
                },
            },
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                    direction: "horizontal",
                },
                // when window width is >= 480px
                480: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                    direction: "horizontal",
                },
                // when window width is >= 640px
                768: {
                    slidesPerView: 4,
                    spaceBetween: 15,
                    direction: "horizontal",
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                    direction: "vertical",
                },
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                    direction: "vertical",
                },
            },
        });
    }
    render() {
        document.title = "Property";

        let { error, propertyInfo, projectInfo, companyInfo, loadingFetch, imagesShow, copySatus, propertyID, whatsAppNumber, isSubmitting, errors, imagesSwiper, email, mobile, massage, types, typeID, name } = this.state;
        if (error)
            return <Error />
        let lang = GetLang();
        let number = whatsAppNumber && whatsAppNumber.filter(obj => {
            return obj.name == "WhatsAppNumber"
        })
        let adminmail = whatsAppNumber && whatsAppNumber.filter(obj => {
            return obj.name == "AdminMail"
        })

        var uri = window.location.href;
        var encodeurl = encodeURI(uri);

        //$('meta[property=og\\:url]').attr('content', encodeurl);
        //$('meta[property=twitter\\:url]').attr('content', encodeurl);

        //if (projectInfo && projectInfo.logo != null) {
        //    $('meta[property=og\\:image]').attr('content', projectInfo && projectInfo.logo);
        //    $('meta[property=twitter\\:image]').attr('content', projectInfo && projectInfo.logo);
        //}
        //if (propertyInfo && propertyInfo.name != null) {
        //    $('meta[property=og\\:title]').attr('content', propertyInfo && propertyInfo.name);
        //    $('meta[property=twitter\\:title]').attr('content', propertyInfo && propertyInfo.name);
        //}
        //if (propertyInfo && propertyInfo.string5 != null) {
        //    $('meta[property=og\\:description]').attr('content', propertyInfo && propertyInfo.string5);
        //    $('meta[property=twitter\\:description]').attr('content', propertyInfo && propertyInfo.string5);
        //}
               
        let rentPropertiesOfSameProject = projectInfo && projectInfo.relatedItems && projectInfo.relatedItems.filter(function (el) {
            return el.id != propertyID;
        });

        //let projectsOfSameCompany = companyInfo && companyInfo.projects && companyInfo.projects.filter(function (el) {
        //    return el.id != propertyInfo.projectId;
        //});
        $(document).ready(function () {
            // Add minus icon for collapse element which is open by default
            $(".collapse.show").each(function () {
                $(this)
                    .parent()
                    .find(".icon-minus")
                    .removeClass("icon-minus")
                    .addClass("icon-plus");
            });

            // Toggle plus minus icon on show hide of collapse element
            $(".collapse")
                .on("show.bs.collapse", function () {
                    $(this)
                        .parent()
                        .find(".icon-plus")
                        .removeClass("icon-plus")
                        .addClass("icon-minus");
                })
                .on("hide.bs.collapse", function () {
                    $(this)
                        .parent()
                        .find(".icon-minus")
                        .removeClass("icon-minus")
                        .addClass("icon-plus");
                });
        });

        var uri = window.location.href;
        var encodeurl = encodeURI(uri);

        //console.log("propertyInfo.genitemImages", propertyInfo && propertyInfo.genitemImages)
        //console.log("imagesSwiper", imagesSwiper)
        //console.log("imagesShow", imagesShow)

        


        let intialImgPhotosTab = propertyInfo && propertyInfo.genitemImages && propertyInfo.genitemImages[0] && propertyInfo.genitemImages[0].image;
        let intialPhotoShow = propertyInfo && propertyInfo.genitemImages && propertyInfo.genitemImages[0] && propertyInfo.genitemImages[0].image && propertyInfo.genitemImages[0].image.replace("IPT_", "IIS_");



        if (projectInfo && projectInfo.logo)
            projectInfo.logo = projectInfo.logo && projectInfo.logo.replace("Lo_", "LOG_");

        for (var i = 0; i < rentPropertiesOfSameProject.length; i++) {
            if (rentPropertiesOfSameProject[i].image)
                rentPropertiesOfSameProject[i].image = rentPropertiesOfSameProject[i].image.replace("IPT_", "II_");

            if (rentPropertiesOfSameProject[i].projectLogo)
                rentPropertiesOfSameProject[i].projectLogo = rentPropertiesOfSameProject[i].projectLogo.replace("Lo_", "PLS_");            
        }
                                              
        return (
                <>
                    {/* <Helmet>

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={encodeurl} />
                    <meta property="og:title" content={propertyInfo && propertyInfo.name} />
                    <meta property="og:description" content="Share Properties and projects with your friends, With Capital Lords!" />
                    <meta property="og:image" content={projectInfo && projectInfo.logo} />
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content={encodeurl}/>
                    <meta property="twitter:title" content={propertyInfo && propertyInfo.name} />
                    <meta property="twitter:description" content="Share Properties and projects with your friends, With Capital Lords!" />
                    <meta property="twitter:image" content={projectInfo && projectInfo.logo} />
                    
                </Helmet> */}

                    <div className="modal-tab animated fadeInDown">
                        <div className="container-fluid">
                            <div className="modal-tab-container">
                                <div className="modal-tab-flexable">
                                    <div className="modal-tab-content">
                                        {/* Photos Tab */}
                                        <div id="photosTab" className="modal-project-tab">
                                            <div className="ds-modal-tab-flexable d-flex">
                                                <div className="modal-tab-normal-main-image">
                                                <img id="bgImageModalViewPhoto" src={intialImgPhotosTab} alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />
                                                </div>
                                                <div id="modalPhotosContainer" className="modal-tab-normal-images">
                                                    <div className="modal-tab-overview">
                                                        <div className="swiper-container swiper-thumb-photos swiper-wrapper-modal-tab" onClick={() => this.handleSwiperThumbPhotos()}>
                                                            <div className="swiper-wrapper" style={{ direction: lang === 'ar' ? 'rtl' : 'ltr' }}>
                                                            {imagesSwiper && imagesSwiper.map(itemImage =>
                                                                    <div className="swiper-slide">
                                                                        <img id={itemImage.id} src={itemImage && itemImage.image} className="img-fluid" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-tab-actions">
                                        <div className="modal-top-actions">
                                            <button id="exitModalTabBtn" className="mss-btn mb-5" onClick={() => this.handleClickCloseModalTab()}><span className="icon-cancel icon" /></button>
                                            <button id="photosTabBtn" className="mss-btn md-tab-btn" data-tab="photosTab" onClick={() => this.handleClickTab("photosTab")}><span className="icon-photo icon" /></button>
                                        </div>
                                        {/*<div className="modal-tab-tel">*/}
                                        {/*    <a href="tel:01234567890" data-toggle="tooltip" data-placement="left" title={"01234567890"} className="mss-btn mss-btn-black md-tab-btn"><span className="icon-telephone icon" /></a>*/}
                                        {/*</div>*/}
                                        <div className="modal-tab-arrows-fraction">
                                            <div className="mtaf mtaf-photos">
                                                <div className="photos-fraction-counter fraction-counter" />
                                                <div className="modal-tab-arrows">
                                                    <button id="mssPrevPhotoBtn" onClick={() => this.handleClickPrevPhoto()} className="mss-btn-arrow"><span className="icon-arrow-left icon" /></button>
                                                    <button id="mssNextPhotoBtn" onClick={() => this.handleClickNextPhoto()} className="mss-btn-arrow"><span className="icon-arrow-right icon" /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="modalShare" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="ms-header flex-center-between">
                                        <button data-dismiss="modal"><span className="icon-cancel icon" /></button>
                                        <div className="title"><FormattedMessage
                                            id='Share'
                                            defaultMessage='Share'
                                        /></div>
                                        <div />
                                    </div>
                                    <div className="ms-body">
                                        <button id="copyboard" onClick={() => { this.handleCopy(encodeurl) }} className="ms-row copyboard flex-center-between" data-clipboard-text="copy capital lords link">
                                            <div className="ms-row-title"><FormattedMessage
                                                id={copySatus}
                                                defaultMessage={copySatus}
                                            /></div> <span className="icon-copy icon" />
                                        </button>
                                        <a target="_blank" rel="noopener noreferrer" href={adminmail && adminmail[0] && "mailto:" + adminmail[0].value + "?subject=Share&body=" + encodeurl} className="ms-row flex-center-between">
                                            <div className="ms-row-title"><FormattedMessage
                                                id='email'
                                                defaultMessage='email'
                                            /></div> <span className="icon-envelope icon" />
                                        </a>
                                        <a target="_blank" rel="noopener noreferrer" href={number && number[0] && "https://wa.me/" + number[0].value + "?text=" + encodeurl} className="ms-row flex-center-between">
                                            <div className="ms-row-title"><FormattedMessage
                                                id='WhatsApp'
                                                defaultMessage='WhatsApp'
                                            /></div> <span className="icon-whatsapp icon" />
                                        </a>
                                        {/*<a target="_blank" rel="noopener noreferrer" href="https://www.messenger.com" className="ms-row flex-center-between">*/}
                                        {/*    <div className="ms-row-title"><FormattedMessage*/}
                                        {/*        id='Messenger'*/}
                                        {/*        defaultMessage='Messenger'*/}
                                        {/*    /></div> <span className="icon-messenger icon" />*/}
                                        {/*</a>*/}
                                        <a target="_blank" rel="noopener noreferrer" href={"https://www.facebook.com/sharer.php?u=" + encodeurl} className="ms-row flex-center-between">
                                            <div className="ms-row-title"><FormattedMessage
                                                id='Facebook'
                                                defaultMessage='Facebook'
                                            /></div> <span className="icon-facebook icon" />
                                        </a>
                                        <a target="_blank" rel="noopener noreferrer" href={"https://twitter.com/intent/tweet?url=" + encodeurl} className="ms-row flex-center-between">
                                            <div className="ms-row-title"><FormattedMessage
                                                id='twitter'
                                                defaultMessage='twitter'
                                            /></div> <span className="icon-twitter icon" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper-container">
                        {loadingFetch === true ? <Loading /> :
                            <>
                                <div className="p2-container animated fadeInDown">
                                    <div className="container-fluid">
                                        {/* P2 Header */}
                                        <div className="p2-header">
                                            <div className="breadcrumb-container flex-center-between">
                                                <div aria-label="breadcrumb">
                                                    <ol className="breadcrumb">
                                                        {/*<li className="breadcrumb-item"><a href="#">Vacation Compounds </a></li>*/}
                                                        {/*<li className="breadcrumb-item"><a href="#">North Coast </a></li>*/}
                                                        <li className="breadcrumb-item"><a href={propertyInfo && "Project/" + propertyInfo.projectId}>{projectInfo && projectInfo.name}</a></li>
                                                        <li className="breadcrumb-item active" aria-current="page">{propertyInfo && propertyInfo.name}</li>
                                                    </ol>
                                                </div>
                                                <div className="breadcrumb-actions d-flex">
                                                    <button class="bc-ac-btn opacity-hover flex-center" data-toggle="modal" data-target="#modalShare">
                                                        <span class="icon-upload icon"></span>
                                                        <FormattedMessage
                                                            id='Share'
                                                            defaultMessage='Share'
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* P2 Slider */}
                                        <div className="p2-slideshow">
                                        <div className="p2-slider-left">
                                            {propertyInfo && propertyInfo.genitemImages && propertyInfo.genitemImages.length === 0 ? <img id="shcView" src="assets/img/notfound.png" className="mg-fluid p2-slide-main-view" alt="" style={{ height: "89.5%" }} />
                                                : <img id="shcView" src={intialPhotoShow} className="mg-fluid p2-slide-main-view" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />}
                                                {/*    <img id="shcView" src={propertyInfo && propertyInfo.genitemImages && propertyInfo.genitemImages[0] && propertyInfo.genitemImages[0].image} className="img-fluid p2-slide-main-view" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />*/}
                                            </div>
                                            <div className="p2-slider-right">
                                                <div id="slidesGrid" className="p2-slides-grid">
                                                    {this.renderImgs()}
                                                    {/*{propertyInfo && propertyInfo.genitemImages && propertyInfo.genitemImages.map(itemImage =>*/}
                                                    {/*    <div className="d2-image-view"><img id={itemImage.id} onClick={() => { this.handleClickImage(itemImage.id) }} src={itemImage && itemImage.image} className="img-fluid" alt="Image property" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} /></div>*/}
                                                    {/*)}                                               */}
                                                </div>
                                                <div className="p2-actions">
                                                    <a href={number && number[0] && number[0].value && "https://wa.me/" + number[0].value + "?text=This is Capital Lords"} target="_blank" rel="noopener noreferrer" className="dc-btn ask-btn mb-0 align-center-center"><span className="icon-whatsapp icon" />
                                                        <FormattedMessage
                                                            id='ASK_NOW'
                                                            defaultMessage='ASK NOW'
                                                        />
                                                    </a>
                                                    <button id="toggleContact" className="dc-btn mb-0 register-btn" onClick={() => this.handleClickFormUp()}>
                                                        <FormattedMessage
                                                            id='REGISTER_INTEREST'
                                                            defaultMessage='REGISTER INTEREST'
                                                        /></button>
                                                    <div id="toggleContactContainer" className="contact-form project-contact-form" style={{ width: "100%" }}>
                                                        <div className="cform-title flex-center-between">
                                                            <div className="title"><FormattedMessage
                                                                id='REGISTER_INTEREST'
                                                                defaultMessage='Register Interest'
                                                            /></div>
                                                            <button id="closeContactContainer" onClick={() => this.handleClickFormDown()} className="close-contact opacity-hover"><span className="icon-cancel icon" /></button>
                                                        </div>
                                                        <fieldset disabled={isSubmitting}>
                                                            <form className="form-row" onSubmit={this.handleSubmit} autoComplete="off" method="post">
                                                                <div className="col-lg-12">
                                                                    <div className="cform-group">
                                                                        <FormattedMessage id="Enter_full_name"
                                                                            defaultMessage="Enter full name">
                                                                            {placeholder =>
                                                                                <input type="text" placeholder={placeholder} name="name" value={name} onChange={this.handleChange} />
                                                                            }
                                                                        </FormattedMessage>
                                                                    </div>
                                                                    {errors.name.length > 0 &&
                                                                        <font style={{ float: "left", color: "#dc3545", width: "100%", fontSize: "80%" }}>
                                                                            <FormattedMessage
                                                                                id={errors.name}
                                                                                defaultMessage="Invalid name"
                                                                            />
                                                                        </font>}
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="cform-group">
                                                                        <FormattedMessage id="Enter_Email"
                                                                            defaultMessage="Enter Email">
                                                                            {placeholder =>
                                                                                <input type="text" placeholder={placeholder} name="email" value={email} onChange={this.handleChange} />
                                                                            }
                                                                        </FormattedMessage>
                                                                    </div>
                                                                    {errors.email.length > 0 &&
                                                                        <font style={{ float: "left", color: "#dc3545", width: "100%", fontSize: "80%" }}>
                                                                            <FormattedMessage
                                                                                id={errors.email}
                                                                                defaultMessage="Invalid email"
                                                                            />
                                                                        </font>}
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="cform-group d-flex">
                                                                        <FormattedMessage id="Enter_Phone"
                                                                            defaultMessage="Enter Phone">
                                                                            {placeholder =>
                                                                                <input type="text" placeholder={placeholder} name="mobile" value={mobile} onChange={this.handleChange} />
                                                                            }
                                                                        </FormattedMessage>
                                                                    </div>
                                                                    {errors.mobile.length > 0 &&
                                                                        <font style={{ float: "left", color: "#dc3545", width: "100%", fontSize: "80%" }}>
                                                                            <FormattedMessage
                                                                                id={errors.mobile}
                                                                                defaultMessage="Invalid mobile"
                                                                            />
                                                                        </font>}
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="cform-group">
                                                                        <FormattedMessage id="Im_interested"
                                                                            defaultMessage="I’m interested in Sale - Apartment New Cairo.">
                                                                            {placeholder =>
                                                                                <textarea rows={3} placeholder={placeholder} defaultValue={""} name="massage" value={massage} onChange={this.handleChange} />
                                                                            }
                                                                        </FormattedMessage>
                                                                    </div>
                                                                    {errors.massage.length > 0 &&
                                                                        <font style={{ float: "left", color: "#dc3545", width: "100%", fontSize: "80%" }}>
                                                                            <FormattedMessage
                                                                                id={errors.massage}
                                                                                defaultMessage="Invalid massage"
                                                                            />
                                                                        </font>}
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="cform-group" style={{ border: 'none' }}>
                                                                        <select id="typeID" name="typeID" value={typeID} onChange={this.handleChange} >
                                                                            <FormattedMessage id="Select_unit_type"
                                                                                defaultMessage="Select unit type">
                                                                                {placeholder =>
                                                                                    <option value="" disabled selected>{placeholder}</option>
                                                                                }
                                                                            </FormattedMessage>
                                                                            {types && types.map(type =>
                                                                                <option value={type.id}>{type.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                    {errors.typeID.length > 0 &&
                                                                        <font style={{ float: "left", color: "#dc3545", width: "100%", fontSize: "80%" }}>
                                                                            <FormattedMessage
                                                                                id={errors.typeID}
                                                                                defaultMessage="Invalid type"
                                                                            />
                                                                        </font>}
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="cform-action">
                                                                        <div className="button-default width-fluid bt-black">
                                                                            <button type="submit" disabled={isSubmitting}>{isSubmitting ? <><i class="fas fa-spinner fa-pulse"></i> <FormattedMessage
                                                                                id='Waiting'
                                                                                defaultMessage='Waiting...'
                                                                            /></> : <FormattedMessage
                                                                                    id='SendNow'
                                                                                    defaultMessage='Send Now'
                                                                                />
                                                                            }</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* P2 Body */}
                                        <div className="p2-profile-bar-grid">
                                            <div className="p2-profile-left">
                                                <div className="p2-avatar">
                                                    <img src={projectInfo && projectInfo.logo} className="img-fluid" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />
                                                </div>
                                                <div className="p2-profile-info">
                                                    <div className="p2-info-row">
                                                        <div className="p2-info-row-title"><FormattedMessage
                                                            id='PROJECT_NAME'
                                                            defaultMessage='PROJECT NAME'
                                                        />   </div>
                                                        <div className="p2-info-row-desc">{projectInfo && projectInfo.name}</div>
                                                    </div>
                                                    <div className="p2-info-row">
                                                        <div className="p2-info-row-title"> <FormattedMessage
                                                            id='Starting_Price'
                                                            defaultMessage='STARTING PRICE'
                                                        /> </div>
                                                        <div className="p2-info-row-desc">{propertyInfo && propertyInfo.priceSales && numberWithCommas(propertyInfo.priceSales)} <FormattedMessage
                                                            id='EGP'
                                                            defaultMessage='EGP'
                                                        /></div>
                                                        {/*    <div className="p2-info-row-date mt-2">Last Updated 28 Oct 2020</div>*/}
                                                    </div>
                                                    <div className="p2-info-row">
                                                        <div className="p2-info-row-title"><FormattedMessage
                                                            id='PROPERTY_TYPE'
                                                            defaultMessage='PROPERTY TYPE'
                                                        /></div>
                                                        <div className="p2-info-row-desc">{propertyInfo && propertyInfo.typeName} </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p2-profile-right">
                                                <div className="p2-place-grid">
                                                    {propertyInfo && propertyInfo.specList && propertyInfo.specList.slice(0, 5).map(spec => {
                                                        if (spec && spec.name === null)
                                                            return <button className="project-action-btn">
                                                                <span className={spec && spec.specificationIcon} />
                                                                <div className="title">{spec && spec.specificationName} {spec && spec.value}</div>
                                                            </button>
                                                        else
                                                            return <button className="project-action-btn">
                                                                <span className={spec && spec.specificationIcon} />
                                                                <div className="title">{spec && spec.value} {spec && spec.specificationName}</div>
                                                            </button>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        {/* P2 Body */}
                                        <div className="p2-body">
                                            <div className="p2-accordion-grid">
                                                {/* Accordion Left */}
                                                <div className="p2-accordion-left">
                                                    <div className="accordion-card">
                                                        <div className="card">
                                                            <div className="card-header accordion-card-header" id="headingOne">
                                                                <button className="toggle-accordion-btn flex-center-between" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                    <div className="title"><FormattedMessage
                                                                        id='Description'
                                                                        defaultMessage='Description'
                                                                    /></div>
                                                                    <span className="icon-plus icon" />
                                                                </button>
                                                            </div>
                                                            {/* multipleOPened if u want to open one only */}
                                                            <div id="collapseOne" className="collapse" aria-labelledby="headingOne">
                                                                <div className="card-body">
                                                                    <div className="text accordion-text">
                                                                        <div className="content" dangerouslySetInnerHTML={{ __html: propertyInfo && propertyInfo.string5 }}></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-card">
                                                        <div className="card">
                                                            <div className="card-header accordion-card-header" id="headingThree">
                                                                <button className="toggle-accordion-btn flex-center-between" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                                                    <div className="title">
                                                                        <FormattedMessage
                                                                            id='About_Developer'
                                                                            defaultMessage='About Developer'
                                                                        /> </div>
                                                                    <span className="icon-plus icon" />
                                                                </button>
                                                            </div>
                                                            <div id="collapseThree" className="collapse" aria-labelledby="headingThree">
                                                                <div className="card-body">
                                                                    <div className="accordion-img-text-widget d-flex">
                                                                        <div className="acc-img">
                                                                            <img src={companyInfo && companyInfo.image} className="img-fluid" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />
                                                                        </div>
                                                                        <div className="acc-img-text">
                                                                            <div className="accordion-text">
                                                                                <ReadMore>{companyInfo && companyInfo.string5 && companyInfo.string5.replace(/<\/?[^>]+(>|$)/g, "")}</ReadMore>
                                                                            </div>
                                                                            <div className="accordion-text mt-2">{companyInfo && companyInfo.name}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Accordion Right */}
                                                <div className="p2-accordion-right">
                                                    <div className="accordion-card">
                                                        <div className="card">
                                                            <div className="card-header accordion-card-header" id="headingTwo">
                                                                <button className="toggle-accordion-btn flex-center-between" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                                                    <div className="title"><FormattedMessage
                                                                        id='Amenities'
                                                                        defaultMessage='Amenities'
                                                                    /></div>
                                                                    <span className="icon-plus icon" />
                                                                </button>
                                                            </div>
                                                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo">
                                                                <div className="card-body">
                                                                    <div className="accordion-list">
                                                                        {propertyInfo && propertyInfo.amenitiesList && propertyInfo.amenitiesList.slice(0, 4).map(Amenity => {
                                                                            return <div className="accl-row">
                                                                                <div className="accl-title">{Amenity && Amenity.classificationName}</div>
                                                                                <div className="accl-info"> {Amenity && Amenity.value}</div>
                                                                            </div>
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-card">
                                                        <div className="card">
                                                            <div className="card-header accordion-card-header" id="headingFour">
                                                                <button className="toggle-accordion-btn flex-center-between" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                                                    <div className="title"><FormattedMessage
                                                                        id='Location'
                                                                        defaultMessage='Location'
                                                                    /></div>
                                                                    <span className="icon-plus icon" />
                                                                </button>
                                                            </div>
                                                            <div id="collapseFour" className="collapse" aria-labelledby="headingFour">
                                                                <div className="card-body">
                                                                    <div className="accordion-map">
                                                                        <iframe src={propertyInfo && 'https://maps.google.com/maps?q=' + propertyInfo.latitude + ',' + propertyInfo.longitude + '&t=&z=12&ie=UTF8&iwloc=&output=embed'} width="100%" height={180} style={{ border: 0 }} allowFullScreen loading="lazy" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Project Details */}
                                <div className="projects-slider">
                                    <div className="container-fluid">
                                        <div className="projects-slider-header flex-center-between">
                                            <div className="title">
                                                <FormattedMessage
                                                    id='YouMayAlso'
                                                    defaultMessage='YOU MAY AlSO LIKE'
                                                />
                                            </div>
                                            <div className="ps-arrows">
                                                <button className="arrow-prevProperties arrow-prev"><span className="icon-arrow-left icon" /></button>
                                                <button className="arrow-nextProperties arrow-next"><span className="icon-arrow-right icon" /></button>
                                            </div>
                                        </div>
                                        <div id="otherProperties" className="otherProperties swiper-container swiper-similar-products">
                                            {rentPropertiesOfSameProject && rentPropertiesOfSameProject.length == 0 ? <div style={{ textAlign: 'center' }}> <FormattedMessage
                                                id='ThereisntProjects'
                                                defaultMessage="There isn' t any property to same project"
                                            /> </div> :
                                                <div className="swiper-wrapper" style={{ direction: lang === 'ar' ? 'rtl' : 'ltr' }}>
                                                    {rentPropertiesOfSameProject && rentPropertiesOfSameProject.map(relatedItem =>
                                                        <div className="swiper-slide">
                                                            <div className="product-card" >
                                                                <Link to={"/Property/" + relatedItem.id} onClick={() => { this.onSelect(relatedItem.id) }}><img src={relatedItem && relatedItem.image} className="product-card-view img-fluid" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} /> </Link>
                                                                <div className="product-card-title">{relatedItem && relatedItem.name}</div>
                                                                <div className="product-card-company">
                                                                    <div className="pcc-logo">
                                                                        <img src={relatedItem && relatedItem.projectLogo} className="img-fluid" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />
                                                                    </div>
                                                                    <div className="pcc-data">
                                                                        <div className="pcc-title"><FormattedMessage
                                                                            id='Starting_Price'
                                                                            defaultMessage='STARTING PRICE'
                                                                        /></div>
                                                                        <div className="pcc-price">{relatedItem && relatedItem.priceSales && numberWithCommas(relatedItem.priceSales)} <FormattedMessage
                                                                            id='EGP'
                                                                            defaultMessage='EGP'
                                                                        /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="projects-slider">
                                <div className="container-fluid">
                                    <div className="projects-slider-header flex-center-between">
                                        <div className="title"><FormattedMessage
                                            id='YouMayAlso'
                                            defaultMessage='YOU MAY AlSO LIKE'
                                        /></div>
                                        <div className="ps-arrows">
                                            <button id="arrow-prevProjects" className="arrow-prevProjects arrow-prev"><span className="icon-arrow-left icon" /></button>
                                            <button id="arrow-nextProjects" className="arrow-nextProjects arrow-next"><span className="icon-arrow-right icon" /></button>
                                        </div>
                                    </div>
                                    <div id="otherProjects" className="otherProjects swiper-container swiper-similar-products">
                                        {projectsOfSameCompany && projectsOfSameCompany.length == 0 ? <FormattedMessage
                                            id='ThereisntProjects'
                                            defaultMessage="There isn't any project to developer"
                                        /> :
                                            <div className="swiper-wrapper" style={{ direction: lang === 'ar' ? 'rtl' : 'ltr' }}>
                                                {projectsOfSameCompany && projectsOfSameCompany.map(item =>
                                                    <div className="swiper-slide">
                                                        <div className="product-card" >
                                                            <Link to={"/Project/" + item.id}>
                                                                {item && item.image == null ? <img src="assets/img/notfound.png" className="product-card-view img-fluid" alt="" />
                                                                    : <img src={item && item.image} className="product-card-view img-fluid" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />}
                                                            </Link>
                                                            <div className="product-card-title">{item && item.name}</div>
                                                            <div className="product-card-company">
                                                                <div className="pcc-logo">
                                                                    <img src={item && item.logo} className="img-fluid" alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>*/}
                            </>
                        }
                    </div>
                </>                
        )
    }
}
