import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { GetLang, validEmailRegex, Toast } from '../_helpers/HelperFunctions';
import Context  from '../components/Context';


export class Footer extends Component {
    static contextType = Context;  
    constructor(props) {       
        super(props);
        this.state = {
            categories: '',
            socialBtns: '',
            email: '',
            isSubmitting: false,
            errors: {
                email: ''
            }
        };
    }

    async fetch(method, endpoint, body) {
        try {
            const response = await fetch(`api/${endpoint}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    LangCode: GetLang(),
                },
            });
            if (!response.ok) {
                const error = (response && response.message) || response.statusText;
                if (method === "POST") {
                    Toast.fire({
                        icon: 'error',
                        title: "There is an error, Try later."
                    })
                } else
                    throw error;
            } else {
                if (method == "POST") {
                    Toast.fire({
                        icon: 'success',
                        title: "Your massage was sent successfully."
                    })
                    await this.setState({ email: '' });
                }
                return await response.json();
            }
        } catch (error) {
            console.error(error);
            this.setState({ error });
        }
    }

    handleChange = (event) => {
        event.preventDefault();
        let { name, value } = event.target;
        let { errors } = this.state;
        let emptyField = '';

        switch (name) {
            case 'email':
                errors.email = value.trim() == emptyField ? 'This_field_is_required' : '';
                if (errors.email == '')
                    errors.email = validEmailRegex.test(value) ? '' : 'Invalid_email';
                break;
            default:
                break;
        }

        this.setState({ errors, [name]: value }, () => {
            console.log(errors)
        })
    }

    validateForm = (errors, state) => {
        let valid = true;
        if (state.email == '')
            errors.email = "This_field_is_required";

        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );

        this.setState({ errors }, () => {
            console.log(errors)
        })
        return valid;
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        let { errors, email, } = this.state;
        let body = { "email": email };

        if (this.validateForm(errors, this.state)) {
            console.info('Valid Form');
            this.setState({ isSubmitting: true });
            await this.fetch('POST', "NewsLetter/AddSubscriber", email)
            this.setState({ isSubmitting: false, email: '' });
        } else {
            console.error('Invalid Form')
        }
    }



    async componentDidMount() {
        const { socialBtns, categories } = this.context;

        //await this.fetch('GET', "Generic/GetCategories")
        this.setState({ categories , socialBtns: socialBtns}); // socialBtns: await this.fetch('GET', "Generic/GetSocialBtns")
    }

    render() {
        let { categories, email, errors, isSubmitting, socialBtns } = this.state;
        let lang = GetLang();
        var url;
        var renderSocialBtns = socialBtns && socialBtns.map(function (btn, idx) {
           // console.log(btn);
            switch (btn.name) {
                case "showfacebook":
                    url = socialBtns.filter(obj => {
                        return obj.name === "facebookurl"
                    })
                    if (url.length > 0 && btn.value.toLowerCase() != 'false' && url[0].value.trim() != '') {
                        //console.log(true)
                        return <a href={url[0].value} target="_blank" rel="noopener noreferrer" key={idx} className="social-icon">
                            <i className="fab fa-facebook-f" />
                        </a>;
                    }
                    break;
                case "showlinkedin":
                    url = socialBtns.filter(obj => {
                        return obj.name === "linkedinurl"
                    })
                    if (url.length > 0 && btn.value.toLowerCase() != 'false' && url[0].value.trim() != '')
                        return <a href={url[0].value} target="_blank" rel="noopener noreferrer" key={idx} className="social-icon">
                            <i className="fab fa-linkedin-in" />
                        </a>;
                    break;
                case "showinsta":
                    url = socialBtns.filter(obj => {
                        return obj.name === "instaUrl"
                    })
                    if (url.length > 0 && btn.value.toLowerCase() != 'false' && url[0].value.trim() != '')
                        return <a href={url[0].value} target="_blank" rel="noopener noreferrer" key={idx} className="social-icon">
                            <i className="fab fa-instagram" />
                        </a>;
                    break;
                case "showyoutube":
                    url = socialBtns.filter(obj => {
                        return obj.name === "youtubeurl"
                    })
                    if (url.length > 0 && btn.value.toLowerCase() != 'false' && url[0].value.trim() != '')
                        return <a href={url[0].value} target="_blank" rel="noopener noreferrer" key={idx} className="social-icon">
                            <i className="fab fa-youtube" />
                        </a>;
                    break;
                default:
                    return null;

            }
        })

        return (
            <footer className="footer" >
                <div className="container-fluid">
                    <div className="footer-content">
                        <div className="footer-top">
                            <div className="footer-logo">
                                <Link to="/"><img src="assets/img/logo.svg" className="img-fluid" alt="" /></Link>
                            </div>
                            <div className="footer-links">
                                <div className="footer-title">Capital Lords</div>
                                <div className="footer-link"><Link to="/About"><FormattedMessage
                                    id='About_us'
                                    defaultMessage='About Us'
                                /></Link></div>
                                <div className="footer-link"><Link to="/ContactUs"><FormattedMessage
                                    id='ContactUs'
                                    defaultMessage='Contact Us'
                                /></Link></div>
                                <div className="footer-link"><Link to="/Press"><FormattedMessage
                                    id='Press'
                                    defaultMessage='Press'
                                /></Link></div>
                                <div className="footer-link"><Link to="/Careers"><FormattedMessage
                                    id='Careers'
                                    defaultMessage='Careers'
                                /></Link></div>
                            </div>
                            <div className="footer-links">
                                <div className="footer-title"><FormattedMessage
                                    id='Projects'
                                    defaultMessage='Projects'
                                /></div>
                                {/*<div className="footer-link">*/}
                                {/*    <Link to={"/Projects/All"}><FormattedMessage*/}
                                {/*    id='all'*/}
                                {/*    defaultMessage='All'*/}
                                {/*/></Link>*/}
                                {/*</div>*/}
                                {categories && categories.length !== 0 && categories.map((cat, indx) =>
                                    <div className="footer-link" key={indx}><Link to={"/Projects/" + cat.id}>
                                        {cat.name}
                                        {/*    <FormattedMessage*/}
                                        {/*    id={cat.name.toLowerCase()}*/}
                                        {/*    defaultMessage={cat.name}*/}
                                        {/*/>*/}
                                    </Link></div>
                                )}
                            </div>
                            <div className="footer-links">
                                <div className="footer-title"><FormattedMessage
                                    id='Services'
                                    defaultMessage='Services'
                                /> </div>
                                <div className="footer-link"><Link to="/Services"><FormattedMessage
                                    id='Our_services'
                                    defaultMessage='Our services'
                                /></Link></div>
                            </div>
                            <div className="footer-contact">
                                <div className="footer-title"><FormattedMessage
                                    id='News&Offers'
                                    defaultMessage='News &amp; Offers'
                                />
                                </div>
                                <form onSubmit={this.handleSubmit} autoComplete="off" method="post">
                                    <div className="footer-contact-group mb-3">
                                        <FormattedMessage id="YourEmailhere"
                                            defaultMessage="Your Email here">
                                            {placeholder =>
                                                <input type="text" disabled={isSubmitting} placeholder={placeholder} name="email" value={email} onChange={this.handleChange} />
                                            }
                                        </FormattedMessage>
                                        <div className="button-default bt-black">
                                            <button type="submit" disabled={isSubmitting}>{isSubmitting ? <>
                                                <i class="fab fa-spinner fa-pulse"></i> <FormattedMessage
                                                    id='Waiting'
                                                    defaultMessage='Waiting...'
                                                />
                                            </> : <>
                                                    <FormattedMessage
                                                        id='Subscribe'
                                                        defaultMessage='Subscribe'
                                                    /> <span className="icon-arrow-right icon" />
                                                </>
                                            }</button>
                                        </div>
                                    </div>
                                    {errors.email.length > 0 &&
                                        <div style={{
                                            textAlign: lang == 'ar' ? 'right' : 'left'
                                        }}>
                                            <font style={{ color: "#dc3545", width: "100%", fontSize: "80%" }}>
                                                <FormattedMessage
                                                    id={errors.email}
                                                    defaultMessage="Invalid email"
                                                />
                                            </font>
                                        </div>
                                    }
                                </form>
                                <div className="text mb-4 white-color">
                                    <FormattedMessage
                                        id='SignUpNews'
                                        defaultMessage='Sign up to receive exclusive news and offers about the latest launches'
                                    />
                                </div>
                                <div className="socialmedia flex-center justify-content-between white-color">
                                    <div className="follow-text"><FormattedMessage
                                        id='Follow_us_on'
                                        defaultMessage='Follow us on'
                                    /></div>
                                    <div className="follow-line" />
                                    <div className="social-icons">
                                        {renderSocialBtns}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer-bottom flex-center justify-content-between">
                            <div className="text white-color"><FormattedMessage
                                id='rights'
                                defaultMessage='Capital Lords 2021 © All rights reserved'
                            /></div>
                            {/*<div className="footer-b-links">*/}
                            {/*    <a href="#">Cookies Policy</a>*/}
                            {/*    <a href="#">Legal Notice</a>*/}
                            {/*    <a href="#">Privacy Policy </a>*/}
                            {/*    <a href="#">Sit map</a>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </footer >
        )
    }
}
