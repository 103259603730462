import React, { Component } from 'react';
import { GetLang } from '../_helpers/HelperFunctions';
import { FormattedMessage } from 'react-intl';
import Context from '../components/Context';


export class ServicesNewsCards extends Component {
    static contextType = Context;  

    constructor(props) {
        super(props);
        this.state = {
            sections: []
        };
    }
    async fetch(method, endpoint, body) {
        try {
            const response = await fetch(`api/${endpoint}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    LangCode: GetLang(),
                },
            });
            if (!response.ok) {
                const error = (response && response.message) || response.statusText;
                throw error;
            } else
                return await response.json();
        } catch (error) {
            console.error(error);
            this.setState({ error });
        }
    }

    async componentDidMount() {
        const { sections } = this.context;
        const sectionsSliceDown = sections && sections.slice(2, 4);

        //Find index of specific object using findIndex method.    
        var objIndex = sectionsSliceDown.findIndex((obj => obj.displayOrdering == 3));

        if (objIndex !== null && sectionsSliceDown[objIndex] !== null) {
            //Update object's name property.
            if (sectionsSliceDown[objIndex].image)
                sectionsSliceDown[objIndex].image = sectionsSliceDown[objIndex].image.replace("XL_", "SL_");

        }
        
        //let sections  = await this.fetch('GET', "Wiki/GetByCategory/" + HomeIDParam);
        await this.setState({ sections });
    }


    render() {
        let { sections } = this.state;
        const sectionsSliceDown = sections && sections.slice(2, 4);
        //let cleanText = sectionsSliceDown && sectionsSliceDown[1] && sectionsSliceDown[1].description && sectionsSliceDown[1].description.replace(/<\/?[^>]+(>|$)/g, "");

        return (
            <>
                <div className="services-about-container">
                    <div className="container-fluid">
                        <div className="sa-grid">
                            {sectionsSliceDown && sectionsSliceDown[0] != null ?
                                <div className="sa-card service-card">
                                    <div className="sa-data" style={{ maxWidth: "50%" }}>
                                        <div className="width-fluid">
                                            <div className="sa-sub-title flex-center text-uppercase">
                                                {sectionsSliceDown && sectionsSliceDown[0] && sectionsSliceDown[0].name}
                                                <div className="sub-title-vline" />
                                            </div>
                                            <div title={sectionsSliceDown && sectionsSliceDown[0] && sectionsSliceDown[0].description} className="bg-title mb-4 text-uppercase">{sectionsSliceDown && sectionsSliceDown[0] && sectionsSliceDown[0].description && sectionsSliceDown[0].description.length >= 60 ? sectionsSliceDown && sectionsSliceDown[0] && sectionsSliceDown[0].description && sectionsSliceDown[0].description.substring(0, 56) + "..." : sectionsSliceDown && sectionsSliceDown[0] && sectionsSliceDown[0].description}</div>
                                        </div>
                                        <div className="button-default bt-black">
                                            <a href={sectionsSliceDown && sectionsSliceDown[0] && sectionsSliceDown[0].targetUrl}>
                                                <FormattedMessage
                                                    id='Explore'
                                                    defaultMessage='Explore'
                                                />
                                                <span className="icon-arrow-right icon" /></a>
                                        </div>
                                    </div>
                                    <div className="sa-img">
                                        {(() => {
                                            if (sectionsSliceDown && sectionsSliceDown[0] && sectionsSliceDown[0].image == null)
                                                return <img src="assets/img/home/service.png" className="img-fluid" alt="" />
                                            else
                                                return <img src={sectionsSliceDown && sectionsSliceDown[0] && sectionsSliceDown[0].image} className="img-fluid" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/home/service.png" }} />
                                        })()}
                                    </div>
                                </div> : <div className="sa-card"></div>
                            }
                            {sectionsSliceDown && sectionsSliceDown[1] != null ?
                                <div className="sa-card about-card">
                                    <div className="sa-data">
                                        <div className="width-fluid">
                                            <div className="sa-sub-title flex-center text-uppercase">
                                                {sectionsSliceDown && sectionsSliceDown[1] && sectionsSliceDown[1].name}
                                                <div className="sub-title-vline" />
                                            </div>
                                            <div className="bg-title white-color mb-4 text-uppercase">
                                                {sectionsSliceDown && sectionsSliceDown[1] && sectionsSliceDown[1].description >= 89 ? sectionsSliceDown && sectionsSliceDown[1] && sectionsSliceDown[1].description && sectionsSliceDown[1].description.substring(0, 85) + "..." : sectionsSliceDown && sectionsSliceDown[1] && sectionsSliceDown[1].description && sectionsSliceDown[1].description}
                                            </div>
                                        </div>
                                        <div className="button-default bt-white">
                                            <a href={sectionsSliceDown && sectionsSliceDown[1] && sectionsSliceDown[1].targetUrl}>
                                                <FormattedMessage
                                                    id='Explore'
                                                    defaultMessage='Explore'
                                                />
                                                <span className="icon-arrow-right icon" /></a>
                                        </div>
                                    </div>
                                </div> : <div className="sa-card"></div>}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
