import React, { Component } from 'react'
import '../css/main.css';
import '../css/connect.css';
import '../css/rtl.css';
import moment from 'moment'
import Pagination from '../components/Pagination';
import { Loading } from '../components/Loading';
import { Error } from '../components/Error';
import Swal from 'sweetalert2'
import { GetLang, Toast, validEmailRegex, ConnectIDParam, removeHeight } from '../_helpers/HelperFunctions';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import $ from 'jquery';


export class ConnectPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            conatctInfoPage: '',
            wikis: '',
            email: '',
            loadingFetch: true,
            isSubmitting: false,
            errors: {
                email: ''
            },
            error: '',
        };
    }
    async fetch(method, endpoint, body) {
        try {
            const response = await fetch(`api/${endpoint}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    LangCode: GetLang(),
                },
            });
            if (!response.ok) {
                const error = (response && response.message) || response.statusText;
                if (method === "POST") {
                    Swal.fire(
                        'Error!',
                        'There is an error, Try later.',
                        'error'
                    )                      
                } else
                    throw error;
            } else {
                if (method == "POST") {
                    Swal.fire(
                        'Success!',
                        'Your massage was sent successfully.!',
                        'success'
                    )                    
                    await this.setState({ email: '' });
                }
                return await response.json();
            }
        } catch (error) {
            console.error(error);
            this.setState({ error });
        }
    }

    handleChange = (event) => {
        event.preventDefault();
        let { name, value } = event.target;
        let { errors } = this.state;
        let emptyField = '';

        switch (name) {
            case 'email':
                errors.email = value.trim() == emptyField ? 'This_field_is_required' : '';
                if (errors.email == '')
                    errors.email = validEmailRegex.test(value) ? '' : 'Invalid_email';
                break;
            default:
                break;
        }

        this.setState({ errors, [name]: value }, () => {
            console.log(errors)
        })
    }

    validateForm = (errors, state) => {
        let valid = true;
        if (state.email == '')
            errors.email = "This_field_is_required";

        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );

        this.setState({ errors }, () => {
            console.log(errors)
        })
        return valid;
    }

    async componentDidMount() {
        removeHeight();
        this.setState({ loadingFetch: true, wikis: await this.fetch('GET', "Wiki/GetByCategory/" + ConnectIDParam), conatctInfoPage: await this.fetch('GET', "Wiki/GetWikiCategoryById/" + ConnectIDParam), loadingFetch: false });
        removeHeight();
    }   
    handleSubmit = async (event) => {
        event.preventDefault();
        let { errors, email, } = this.state;
        let body = { "email": email };

        if (this.validateForm(errors, this.state)) {
            console.info('Valid Form');
            this.setState({ isSubmitting: true });
            await this.fetch('POST', "NewsLetter/AddSubscriber", email)
            this.setState({ isSubmitting: false, email: '' });
        } else {
            console.error('Invalid Form')
        }
    }


    render() {
        document.title = "Connect";

        let { conatctInfoPage, email, error, errors, isSubmitting, loadingFetch, wikis } = this.state;
        let lang = GetLang();
        if (lang === 'ar')
            $('body').attr('class', 'rtl');
        else
            $('body').attr('class', 'ltr');

        if (error)
            return <Error />
        return (
            <div className="wrapper-container">
                {loadingFetch === true ? <Loading /> :
                    <div className="wrapper-connect">
                        <div className="connect-left">
                            <div className="connect-content">
                                <Link className="connect-nav" to="/">
                                    <Link className="back-to-careers" to="/"><span className="icon-home icon" /></Link>
                                </Link>
                                <div className="connect-body">
                                    <div className="connect-container register-connect text-center active">
                                        <div className="connect-title mb-3">{conatctInfoPage && conatctInfoPage.name}</div>
                                        <div className="connect-text mb-4">
                                            {conatctInfoPage && conatctInfoPage.description}
                                        </div>
                                        <form onSubmit={this.handleSubmit} autoComplete="off" method="post" className="connect-form">

                                            <div className="connect-form-group mb-4">
                                                <FormattedMessage id="YourEmailhere"
                                                    defaultMessage="Your Email here">
                                                    {placeholder =>
                                                        <input type="text" disabled={isSubmitting} placeholder={placeholder} name="email" value={email} onChange={this.handleChange} />
                                                    }
                                                </FormattedMessage>
                                            </div>
                                            {errors.email.length > 0 &&
                                                <div style={{
                                                    textAlign: lang == 'ar' ? 'right' : 'left'
                                                }}>
                                                    <font style={{ color: "#dc3545", width: "100%", fontSize: "80%" }}>
                                                        <FormattedMessage
                                                            id={errors.email}
                                                            defaultMessage="Invalid email"
                                                        />
                                                    </font>
                                                </div>
                                            }
                                            <button className="connect-form-btn" type="submit" disabled={isSubmitting}>{isSubmitting ? <>
                                                <i class="fab fa-spinner fa-pulse"></i> <FormattedMessage
                                                    id='Waiting'
                                                    defaultMessage='Waiting...'
                                                />
                                            </> :
                                                <FormattedMessage
                                                    id='Connect'
                                                    defaultMessage='Connect'
                                                />
                                            } </button>
                                        </form>
                                    </div>
                                    {/*              <div className="connect-container login-connect text-center">*/}
                                    {/*                  <div className="connect-title mb-3">Sign in </div>*/}
                                    {/*                  <div className="connect-text mb-4">*/}
                                    {/*                      I have read the Privacy Policy and confirm that Capital Lords store my personal details to be able to*/}
                                    {/*                      contact me for job opportunities.*/}
                                    {/*</div>*/}
                                    {/*                  <form className="connect-form">*/}
                                    {/*                      <div className="connect-form-group mb-4">*/}
                                    {/*                          <input type="text" placeholder="Enter email" />*/}
                                    {/*                      </div>*/}
                                    {/*                      <button className="connect-form-btn">Sign In</button>*/}
                                    {/*                  </form>*/}
                                    {/*              </div>*/}
                                </div>
                                {/*        <div className="connect-footer text-center">*/}
                                {/*            <div className="connect-foot-text"><span className="cft-msg">Already connected?</span> <button className="toggle-connect-btn">Sign*/}
                                {/*in.</button>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                            </div>
                        </div>
                        <div className="connect-right">
                            <div className="connect-benefits">
                                {wikis && wikis.map(wiki =>
                                    <div className="con-benefit-row">
                                        {(() => {
                                            if (wiki && wiki.image == null) {
                                                return <img src="assets/img/icons/love.svg" className="cbr-img img-fluid" alt="" />
                                            }
                                            else
                                                return <img src={wiki && wiki.image} className="cbr-img img-fluid" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/icons/love.svg" }} />
                                        })()}
                                        <div>
                                            <div className="cbr-title">
                                                {wiki && wiki.name}
                                            </div>
                                            <div className="cbr-text">
                                                {wiki && wiki.description}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
