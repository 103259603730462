import React, { Component } from 'react'
import '../css/main.css';
import '../css/about-services.css';
import '../css/rtl.css';
import { Link } from 'react-router-dom';
import { Loading } from '../components/Loading';
import { Error } from '../components/Error';
import { AboutIDParam, GetLang, calcNavHeight } from '../_helpers/HelperFunctions';
import { FormattedMessage } from 'react-intl';

export class AboutPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            aboutInfo: '',
            loadingFetch: true,
            error: '',
        };
    }

    async fetch(method, endpoint, body) {
        try {
            const response = await fetch(`api/${endpoint}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    LangCode: GetLang(),
                },
            });
            if (!response.ok) {
                const error = (response && response.message) || response.statusText;
                throw error;
            } else
                return await response.json();
        } catch (error) {
            console.error(error);
            this.setState({ error });
        }
    }
    openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    async componentDidMount() {
        calcNavHeight();
        this.setState({ loadingFetch: true, aboutInfo: await this.fetch('GET', "Wiki/GetAbout/" + AboutIDParam), loadingFetch: false });
        calcNavHeight();
    }

    openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }


    render() {
        document.title = "About";
        let { error, aboutInfo, loadingFetch } = this.state;

        const teamImages = aboutInfo && aboutInfo.images.filter(image => image.isActive != true);

        for (var i = 0; i < teamImages.length; i++) {
            if (teamImages[i].image)
                teamImages[i].image = teamImages[i].image.replace("M_", "TI_");           
        }
        
        if (error)
            return <Error />
        return (
            <div className="wrapper-container">
                {/* About Header */}               
                {loadingFetch === true ? <Loading /> :
                    <>
                        <div className="about-header">
                            {(() => {
                                if (aboutInfo && aboutInfo.image == null) {
                                    return <img src="assets/img/about.png" className="img-fluid animated fadeInLeft" alt="" />
                                }
                                else {
                                    return <img src={aboutInfo && aboutInfo.image} className="img-fluid animated fadeInLeft" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/about.png" }} />
                                }
                                })()}
                        </div>
                        <div className="about-container">
                            <div className="container-fluid">
                                <div className="about-content">
                                    <div className="about-flexable d-flex justify-content-between">
                                        <div className="about-data">
                                            <div className="about-text-container">
                                                <div className="sub-title">{aboutInfo && aboutInfo.name}</div>
                                                <div className="bg-title text-uppercase">
                                                    <FormattedMessage
                                                        id="Who_we_are"
                                                        defaultMessage="Who we are"
                                                    />  </div>
                                                <div className="text">
                                                    <div dangerouslySetInnerHTML={{ __html: aboutInfo && aboutInfo.string5 }}></div>
                                                </div>
                                            </div>
                                            <div className="about-text-container">
                                                <div className="bg-title text-uppercase">  <FormattedMessage
                                                    id="What_Makes_Us_Different"
                                                    defaultMessage="What Makes Us Different?"
                                                /> </div>
                                                <div className="text">   {aboutInfo && aboutInfo.description}   </div>
                                            </div>
                                        </div>
                                        <div className="about-sidebar">
                                            <div className="download-btn mb-5">
                                                <div style={{ cursor: 'pointer' }} onClick={(e) => { this.openInNewTab(aboutInfo && aboutInfo.files && aboutInfo.files[0] && aboutInfo.files[0].azureUri) }} className="dl-btn text-center"> <FormattedMessage
                                                id="DownloadOurProfile"
                                                defaultMessage="Download Our Profile"
                                                /></div>
                                            </div>
                                            <div className="about-text-container">
                                                <div className="title text-uppercase"><FormattedMessage
                                                    id="VisionMission"
                                                    defaultMessage="Vision &amp; Mission"
                                                />  </div>
                                                <div className="text"> {aboutInfo && aboutInfo.richContent}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="about-team">
                                        <div className="bg-title text-uppercase"><FormattedMessage
                                            id="MeetOurTeam"
                                            defaultMessage="Meet Our Team"
                                        /></div>
                                        <div className="team-grid">
                                            {teamImages && teamImages.map(image =>
                                                <div className="person-card animated fadeInLeft" key={image && image.id}>
                                                    {image && image.image == null ? <img src="assets/img/notfound.png" className="img-fluid animated fadeInLeft" alt="" />
                                                        : <img src={image && image.image} className="img-fluid animated fadeInLeft" alt="" onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/notfound.png" }} />}
                                                    <div className="person-data">
                                                        <div className="person-name">{image && image.name}</div>
                                                        <div className="person-position">{image && image.description}</div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        )
    }
}
