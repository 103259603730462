import React, { Component } from 'react'
import '../css/main.css';
import '../css/careers.css';
import '../css/rtl.css';
import { Loading } from '../components/Loading';
import { Error } from '../components/Error';
import { FormattedMessage } from 'react-intl';
import $ from 'jquery';
import { GetLang, AboutIDParam,IntegersRegex, IntegersGreaterThanZeroRegex} from '../_helpers/HelperFunctions';
import { Link } from 'react-router-dom';
import Context from '../components/Context';


export class JobDetailsPage extends Component {
    static contextType = Context;  

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            socialBtns: '',
            infoPage: '',
            aboutInfo:'',
            loadingFetch: true,
            error: ''
        };
    }

    async fetch(method, endpoint, body) {
        try {
            const response = await fetch(`api/${endpoint}`, {
                method,
                body: body && JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    LangCode: GetLang(),
                },
            });
            if (!response.ok) {
                const error = (response && response.message) || response.statusText;
                throw error;
            } else
                return await response.json();
        } catch (error) {
            console.error(error);
            this.setState({ error });
        }
    }

    // Callback IF Condition Header Bottom
    headerBottomScrollerChecker() {
        var mainHeaderBottom =
            $("#headerScroller").position().top +
            $("#headerScroller").outerHeight(true);
        if ($(window).scrollTop() >= mainHeaderBottom) {
            $("#nav").addClass("active");
            $(".transition-title").removeClass("active");
        } else {
            $("#nav").removeClass("active");
        }
    }

    handleAnimateDown(id) {
        var target = $("#" + id).attr("data-target");
        $("html, body").animate(
            {
                // scrollTop: $("#" + target).top,
                scrollTop: $(target).offset().top - 199,
            },
            "slow"
        );        
    }

    async componentDidMount() {
        let { id } = this.state;
        const { socialBtns } = this.context;


        document.body.style.paddingTop = '80.625px';
        var element = document.getElementById("nav");
        element.classList.add("active-page");

        let that = this;

        $(window).scroll(function () {
            if ($(window).scrollTop() > 0) {
                $("#navigationBar").addClass("active");
            } else {
                $("#navigationBar").removeClass("active");
            }
        });

        // Header Scroller
        $(window).on("scroll", function () {
            if ($(window).scrollTop() > 0 && $("#headerScroller")[0]) {
                $(".transition-title").addClass("active");
                that.headerBottomScrollerChecker();
            } else {
                $(".transition-title").removeClass("active");
                $("#nav").removeClass("active");
            }
        });

        let IsInt = IntegersRegex.test(id) ? true : false;
        let IsGreateThanZero = IntegersGreaterThanZeroRegex.test(id) ? true : false;
        if (IsInt !== true && IsGreateThanZero !== true) {
            window.location.assign('/404')
        } else {
            this.setState({ aboutInfo: await this.fetch('GET', "Wiki/GetByWikiId/" + AboutIDParam), infoPage: await this.fetch('GET', "Service/" + id), socialBtns, loadingFetch: false }); //: await this.fetch('GET', "Generic/GetSocialBtns")
        }
        document.body.style.removeProperty("padding-top")
        var element = document.getElementById("nav");
        element.classList.remove("active-page");
    }

    render() {
        document.title = "Job";

        let { error, infoPage, id, loadingFetch, socialBtns, aboutInfo} = this.state;
        if (error)
            return <Error />

        let img = infoPage && infoPage.image;
        var url;
        var renderSocialBtns = socialBtns && socialBtns.map(function (btn, idx) {
            switch (btn.name) {
                case "showfacebook":
                    url = socialBtns.filter(obj => {
                        return obj.name === "facebookurl"
                    })
                    if (url.length > 0 && btn.value.toLowerCase() != 'false' && url[0].value.trim() != '') {
                        return <a href={url[0].value} target="_blank" rel="noopener noreferrer" className="social-icon facebook">
                            <i className="fab fa-facebook-f" />
                            <span><FormattedMessage
                                id="showfacebook"
                                defaultMessage="Facebook"
                            /></span>
                        </a>

                    }
                    break;
                case "showlinkedin":
                    url = socialBtns.filter(obj => {
                        return obj.name === "linkedinurl"
                    })
                    if (url.length > 0 && btn.value.toLowerCase() != 'false' && url[0].value.trim() != '')
                        return <a href={url[0].value} target="_blank" rel="noopener noreferrer" className="social-icon linkedin">
                            <i className="fab fa-linkedin-in" />
                            <span><FormattedMessage
                                id="showlinkedin"
                                defaultMessage="Linkedin"
                            /></span>
                        </a>;
                    break;
                case "showinsta":
                    url = socialBtns.filter(obj => {
                        return obj.name === "instaUrl"
                    })
                    if (url.length > 0 && btn.value.toLowerCase() != 'false' && url[0].value.trim() != '')
                        return <a href={url[0].value} target="_blank" rel="noopener noreferrer" className="social-icon instagram">
                            <i className="fab fa-instagram" />
                            <span><FormattedMessage
                                id="showinsta"
                                defaultMessage="Instagram"
                            /></span>
                        </a>;
                    break;
                case "showyoutube":
                    url = socialBtns.filter(obj => {
                        return obj.name === "youtubeurl"
                    })
                    if (url.length > 0 && btn.value.toLowerCase() != 'false' && url[0].value.trim() != '')
                        return <a href={url[0].value} target="_blank" rel="noopener noreferrer" className="social-icon youtube">
                            <i className="fab fa-youtube" />
                            <span><FormattedMessage
                                id="showyoutube"
                                defaultMessage="YouTube"
                            /></span>
                        </a>;
                    break;
                default:
                    return null;

            }
        })

        return (
            <div className="wrapper-container">
                {/* Slider main container */}
                {loadingFetch === true ? <Loading /> :
                    <>
                        <div id="headerScroller" className="home-header">
                            <div className="careers-header" style={{ backgroundImage: img === null ? 'assets/img/application.png' : `url(${img}),url("assets/img/services/service-1.png")` }}>
                                <div className="overlay" />
                                <div className="careers-data text-center">
                                    <div className="bg-title">
                                        {infoPage && infoPage.name}
                                    </div>
                                    <div className="careers-action mb-3">
                                        <Link to={"/Apply/"+id} className="career-btn"><FormattedMessage
                                            id='Applyforthisjob'
                                            defaultMessage='Apply for this job'
                                        /></Link>
                                    </div>
                                    <div className="d-inline-block">
                                        <button data-target="#introduction" id="introduction" onClick={() => this.handleAnimateDown("introduction")} className="career-down arrow-target flex-center-center"><span className="icon-chevron-down icon" /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="careers">
                            <div className="container-fluid">
                                <div className="careers-content">
                                    <div className="careers-st-container">
                                        <div className="careers-socialmedia text-center">
                                            {renderSocialBtns}
                                        </div>
                                        <div id="introduction" className="careers-text">
                                            <div className="career-text-card mb-5">
                                                <div className="bg-title text-left">{aboutInfo && aboutInfo.name}</div>
                                                <div className="text">
                                                    <div dangerouslySetInnerHTML={{ __html: aboutInfo && aboutInfo.string5 }}></div>
                                                </div>
                                            </div>
                                            <div className="text">
                                                <div dangerouslySetInnerHTML={{ __html: infoPage && infoPage.richContent }}></div>
                                            </div>
                                            <div className="careers-action text-center mb-3 mt-5">
                                                <Link to={"/Apply/" + id} className="career-btn"><FormattedMessage
                                                    id='Applyforthisjob'
                                                    defaultMessage='Apply for this job'
                                                /></Link>
                                            </div>
                                            <div className="text text-center pt-4">
                                                <FormattedMessage
                                                    id='Orknow'
                                                    defaultMessage='Or, know someone who would be a perfect fit?'
                                                />
                                                <br /> <FormattedMessage
                                                    id='Letthemknow'
                                                    defaultMessage='Let them know!'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        )
    }
}
