import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Layout } from './components/Layout';
import { AboutPage } from './pages/AboutPage';
import { HomePage } from './pages/HomePage';
import { ContactUsPage } from './pages/ContactUsPage';
import { ArticlesPage } from './pages/ArticlesPage';
import { ServicesPage } from './pages/ServicesPage';
import { ProjectsPage } from './pages/ProjectsPage';
import { ProjectPage } from './pages/ProjectPage';
import { PropertyPage } from './pages/PropertyPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { ApplicationPage } from './pages/ApplicationPage';
import { SearchResultPage } from './pages/SearchResultPage';
import { ArticlePage } from './pages/ArticlePage';
import { JobDetailsPage } from './pages/JobDetailsPage';
import { CareersPage } from './pages/CareersPage';
import { SearchResultProjectsPage } from './pages/SearchResultProjectsPage';
import { AllJobsPage } from './pages/AllJobsPage';
import { ConnectPage } from './pages/ConnectPage';
import ReactGA from "react-ga4"


export default class App extends Component {
    static displayName = App.name;
    render() {
        const TRACKING_ID="GTM-KVWZ76S9"
        ReactGA.initialize(TRACKING_ID);
        ReactGA.send({ hitType: "pageview", page: "https://capitallords.com/", title: "capitallords" });

        return (
            <Switch>
                <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} />
                <Route exact path='/Connect' component={ConnectPage} />                            
                <Route path={["/", "/About", "/Connect", "/Job/:id", "/AllJobs", "/Careers", "/ContactUs", "/Article/:id", "/SearchResult", "/SearchResultProjects", "/Apply/:id", "/Press", "/Services", "/Press", "/Project/:id", "/Projects/:id", "/Property/:id"]} exact> 
                    <Layout>
                        <Switch>
                            <Route path='/Apply/:id' component={ApplicationPage} />
                            <Route exact path='/' component={HomePage} />
                            <Route path='/About' component={AboutPage} />
                            <Route path='/ContactUs' component={ContactUsPage} />
                            <Route path='/Press' component={ArticlesPage} />
                            <Route path='/Services' component={ServicesPage} />
                            <Route path='/Projects/:id' component={ProjectsPage} />
                            <Route path='/Project/:id' component={ProjectPage} />
                            <Route path='/Property/:id' component={PropertyPage} />
                            <Route path='/Article/:id' component={ArticlePage} />
                            <Route path='/Job/:id' component={JobDetailsPage} />
                            <Route path='/Careers' component={CareersPage} />
                            <Route path='/AllJobs' component={AllJobsPage} />                            
                            <Route path='/SearchResult' component={SearchResultPage} />
                            <Route path='/SearchResultProjects' component={SearchResultProjectsPage} />
                        </Switch>
                    </Layout>
                </Route>
                <Route path='/404' component={NotFoundPage} />
                <Redirect from='*' to='/404' />
            </Switch>
        );
    }
}
