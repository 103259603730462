import React, { useState } from "react";
import { FormattedMessage } from 'react-intl';

export const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <p className="text">
            {isReadMore ? text && text.slice(0, 150) : text}
            <span onClick={toggleReadMore} className="read-or-hide">
                {isReadMore ? <FormattedMessage
                    id='read_more'
                    defaultMessage='...read more'
                /> : <FormattedMessage
                        id='show_less'
                        defaultMessage='show less'
                    />}
            </span>
        </p>
    );
}